<div mat-dialog-content>
        <div class="modal-content">
            <!-- <img class="row" src="assets/images/check-circle.svg"> -->
            <img class="row" src="assets/images/check-circle.svg">
            <span class="modal-text"> {{data.modalText}} </span>
        </div>
</div>
<!-- <div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
</div> -->