import { Component } from '@angular/core';

@Component({
  selector: 'app-scientist-details',
  templateUrl: './scientist-details.component.html',
  styleUrls: ['./scientist-details.component.css']
})
export class ScientistDetailsComponent {

}
