
      <div class="row homepage">
        <mat-icon class="mat-icon-home">home</mat-icon><span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Homepage</span>
      </div>
      <div class="row message">
      </div>
      <br>
      <div class="card-container">
        <mat-card class="card" (click)="toggleCardDetails(1)" [ngClass]="{active: selected == 1}">
          <mat-card-header>
            <mat-card-title class="card-title">Latest 10 Screens</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="card-content-text">Screens</p>
          </mat-card-content>
        </mat-card>
        <mat-card class="card" (click)="toggleCardDetails(2)" [ngClass]="{active: selected == 2}">
          <mat-card-header>
            <mat-card-title class="card-title">Latest 3 Projects</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="card-content-text">Projects</p>
          </mat-card-content>
        </mat-card>
        <mat-card class="card" (click)="toggleCardDetails(3)" [ngClass]="{active: selected == 3}">
          <mat-card-header>
            <mat-card-title class="card-title">Latest 3 Libraries</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="card-content-text">Libraries</p>
          </mat-card-content>
        </mat-card> 
        <mat-card class="card" (click)="toggleCardDetails(4)" [ngClass]="{active: selected == 4}">
          <mat-card-header>
            <mat-card-title class="card-title">Latest 10 Hit Lists</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p class="card-content-text">Hitlists</p>
          </mat-card-content> 
        </mat-card>
      </div>
      <mat-card class="details-card">
        <!-- <mat-table [dataSource]="dataSource">
          <ng-container *ngFor="let column of cardColumns" [matColumnDef]="column.columnDef">
            <mat-header-cell *matHeaderCellDef>{{ column.header }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row[column.columnDef] }}</mat-cell>
          </ng-container>
      
          <mat-header-row *matHeaderRowDef="getColumnDefNames()"></mat-header-row>
          <mat-row *matRowDef="let row; columns: getColumnDefNames()"></mat-row>
        </mat-table> -->
        <div class="table-title">
          <span class="title">{{tableTitle}}</span>
          <span class="description"><a (click)="listDetail()">View All</a></span>
        </div>
        <div *ngIf="!loading">
          <app-table-component [columns]="tableColumns" [tableData]="tableList" listName="NotificationList"  
          [clickable]="true"  (showRowDetails)="rowDetails($event)"  [showSearch]=false [showEditRow]=true  />
        
        </div>
        <div *ngIf="loading" class="loading-container">
          <app-loading-spinner></app-loading-spinner>
        </div>
       </mat-card>
  