
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponentComponent } from './pages/modal-component/modal-component.component';
import { ModalScreenComponent } from './pages/modal-screen/modal-screen.component';
import { LibraryModalComponent } from './components/library-modal/library-modal.component';


@Injectable({
  providedIn: 'root'
})

export class ModalServiceService {
  private showSideNavSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public sideNav$ = this.showSideNavSubject.asObservable();

  sharedDataModalScreen?: boolean;

  private dataSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public data$ = this.dataSubject.asObservable();
  constructor(private dialog: MatDialog) { };

  setDataModalScreen(data: boolean) {
    this.sharedDataModalScreen = data;
  }

  getDataModalScreen() {
    return this.sharedDataModalScreen;
  }

  getData(): Observable<string> {
    return this.dataSubject.asObservable();
  }

  updateData(data: string) {
    this.dataSubject.next(data);
  }

  //TODO: Sherif Make it enum
  openModal(modalName?: string, data?:any) {
   return  this.dialog.open( LibraryModalComponent , {
      // Add any additional configuration options for the modal
      width: '400px',
      data: data
      // ... other options
    });

    // Subscribe to the afterClosed observable to get the result when the modal is closed
    
  }

  openModalScientist() {
    const dialogRef = this.dialog.open(ModalComponentComponent, {
      // Add any additional configuration options for the modal
      width: '100px',
      // ... other options
    });

    // Subscribe to the afterClosed observable to get the result when the modal is closed
    dialogRef.afterClosed().subscribe(result => {
      // Handle the result if needed
    });
  }

  openModalScreen1() {
    const dialogRef = this.dialog.open(ModalScreenComponent, {
      // Add any additional configuration options for the modal
      width: '400px',
      // ... other options
    });

    // Subscribe to the afterClosed observable to get the result when the modal is closed
    dialogRef.afterClosed().subscribe(formValues => {
      if (formValues) {
        // Form values are available here
        alert(JSON.stringify(formValues));
        this.updateData(JSON.stringify(formValues));
      } else {
        // Modal closed without submitting the form
        console.log('Modal closed without form submission');
      }
    });
  }

  public updateMyVariable(newValue: boolean): void {
    this.showSideNavSubject.next(newValue);
  }


}