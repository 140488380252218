import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';
import { MsalService } from '@azure/msal-angular';


export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  
  
  const authService =  inject(MsalService);
  const router = inject(Router)
 
    const roles: any = authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
    const adminRole: boolean = roles.some((role: string)=> {
        return role == 'ApplicationAdmin'
      })
    if(adminRole) {
      return true
    }  else {
      router.navigate(['/no-admin']); 
      return false
    }
    
};
