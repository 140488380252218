<div class="screentab" >
    <div class="row homepage project-back">
        <mat-icon mat-icon-button (click)="navigateToscreensPage()"
            class="mat-icon-home" >west</mat-icon>
        <span  class="project-name" > {{data?.projectName || data?.project}}</span>
        <!-- <span  class="project-name" > TEST</span> -->
    </div>
    <div class="tab-container1">
        <mat-button-toggle-group class="tab-container" aria-label="Favorite Color">
            <mat-button-toggle value="tab1" class="tab"
            #toggleButton1
             (click)="changeSelectedTab(1,toggleButton1)" 
             >Screen
                Profile</mat-button-toggle>
            <mat-button-toggle value="tab2" 
                [disabled]="tab1Selected" 
                class="tab" 
                #toggleButton2
            (click)="changeSelectedTab(2,toggleButton2)" 
             >NGS
                Profile
            </mat-button-toggle>
            <!-- <mat-button-toggle value="tab3"
             [disabled]="tab1Selected || tab2Selected "
              class="tab"
            #toggleButton3
            (click)="changeSelectedTab(3,toggleButton3)"
                >Experimental Conditions</mat-button-toggle> -->
            <mat-button-toggle value="tab4" 
                [disabled]="tab1Selected || tab2Selected || tab3Selected" 
            class="tab"
                #toggleButton4
            (click)="changeSelectedTab(4,toggleButton4)"
                >Processing Parameters</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="mat-btn-next"
        [ngClass]="{'tab-two': selectedTab == 'tab2'}">

     



            <button mat-raised-button color="primary" *ngIf="selectedTab == 'tab1'"
                [disabled]="!personalFormGroup.valid || duplicatedName || showErrorCSV || showErrorExcel" (click)="nextClick(selectedTab)"><span
                    class="btn-name">Next</span>
                <span class="btn-icon">
                    <mat-icon>arrow_circle_right</mat-icon>
                </span>
            </button>

            <div class="tab-3-action-buttons">
        
                 <div class="row div1" *ngIf="selectedTab == 'tab2'" style="margin-right: 20px" [formGroup]="readFileGroup">
                    <mat-label class="mat-label">Merged/Paired*</mat-label>

                    <mat-form-field class="matformfield">
                        <mat-select placeholder="value" formControlName="readFile" (selectionChange)="selectFileType($event)">
                            <mat-option *ngFor="let option of readFiles" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                 </div>
                
                 <button mat-raised-button color="primary" *ngIf="selectedTab == 'tab2'" 
                     [disabled]="showErrorOddNGSFiles"             
                    (click)="nextClick(selectedTab)"><span class="btn-name">Next</span>
                    <span class="btn-icon">
                        <mat-icon>arrow_circle_right</mat-icon>
                    </span>
                </button>               
            </div>

            <div class="tab-3-action-buttons">
        

                <button mat-raised-button class="change-file-button"  *ngIf="selectedTab == 'tab3'"
                [disabled]="!experimentalFormGroup.valid || showInputError " (click)="fileInput.click()"><span
                    class="btn-name"> Reupload file</span>
                <span class="btn-icon">

                    <input type="file" #fileInput accept=".xlsx" (change)="onFilesSelected(fileInput)"
                     style="display: none" >
                </span>
            </button>

<!-- 
                <button mat-raised-button class="upload-button"  *ngIf="selectedTab == 'tab3'"
                    [disabled]="!experimentalFormGroup.valid || showInputError" (click)="uploadExperementalConditions()"><span
                        class="btn-name">Upload</span>
                    <span class="btn-icon">
                        <mat-icon style="color: blue">upload </mat-icon>
                    </span>
                </button> -->

                <button mat-raised-button color="primary" *ngIf="selectedTab == 'tab3'"
                    [disabled]="!experimentalFormGroup.valid || showEXPvalidationError" (click)="nextClick(selectedTab)"><span
                        class="btn-name">Next</span>
                    <span class="btn-icon">
                        <mat-icon>arrow_circle_right</mat-icon>
                    </span>
                </button>
            </div>
            <!-- TAB  3  EXPERMENTAL CONDITION FILES ABOVE  -->
    

            <div *ngIf="!loading" class="row homepage">
                <button mat-raised-button color="primary" *ngIf="selectedTab == 'tab4'"
                    [disabled]="!processingFormGroup.valid && ngsFiles.length > 0" (click)="screenFilesUpload();" 
                    data-toggle="modal" data-target="#myModal"
                    ><span class="btn-name">Submit</span>
                    <span class="btn-icon">
                        <mat-icon>arrow_circle_right</mat-icon>
                    </span>
                </button>
            </div>
            <div *ngIf="loading" class="row homepage">
                <!-- <app-loading-spinner></app-loading-spinner> -->
                <!-- <mat-progress-spinner class="custom-spinner" mode="indeterminate"></mat-progress-spinner> -->
                <div class="container-file-uploading">
                    <p>Uploading...</p>
                    <mat-progress-spinner class="custom-spinner" mode="indeterminate" diameter="24" strokeWidth="3"></mat-progress-spinner>
                </div>       
            </div>
            
            <!-- <button mat-raised-button color="primary" *ngIf="selectedTab == 'tab4'"
                [disabled]="!processingFormGroup.valid" (click)="screenFilesUpload();" 
                data-toggle="modal" data-target="#myModal"
                ><span class="btn-name">Submit</span>
                <span class="btn-icon">
                    <mat-icon>arrow_circle_right</mat-icon>
                </span>
            </button> -->
        </div><br>

        <div *ngIf="selectedTab === 'tab1' && personalFormGroup" [formGroup]="personalFormGroup">
            <div class="row add-scientist" >
                <p class="form-grid-heading">Library Details</p>
                <div class="mat-elevation-z8">
                    <br>
                    <p class="mat-elevation-z8-heading">Add below the library details</p>
                    <div class="form-grid-asym">
                        <div class="row div1">
                            <mat-label class="mat-label">Name*</mat-label><br>
                            <mat-form-field class="matformfield">
                                <input matInput placeholder="Insert Name" formControlName="name" (focusout)="checkDuplicateName()" required>
                            </mat-form-field>
                            <p *ngIf="duplicatedName" style="color:red; margin-left :1em">Name already exist. Please use different name!</p>
                        </div>
                        <div class="row div2">
                            <mat-label class="mat-label">Uploader *</mat-label><br>.

                            <mat-form-field class="matformfield">
                                <mat-select placeholder="value" formControlName="uploader" >
                                    <mat-option *ngFor="let option of uploaders" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="row div3">
                            <mat-label class="mat-label">Description</mat-label><br>
                            <mat-form-field class="matformfieldtextarea">
                                <textarea matInput placeholder="Describe in detail..." rows="5" formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                        <div class="row div4">
                            <mat-label class="mat-label"> Upload library definition* </mat-label>
                            <div class="library-container">
                             <div class="drop-zone upload-container">
                              <input type="file" #fileInput formControlName="libraryFileInput"  (change)="onLibFilesSelected(fileInput,libraryDefinitionFile)" accept=".xlsx, .gz" style="display: none">
                              <mat-icon class="upload-icon" (click)="fileInput.click()">upload</mat-icon>
                              <span (click)="fileInput.click()">
                                <span class="upload-text">Click to select File</span>
                              </span>
                           
                            </div>
                          
                          </div>
                          <h4 class="accepted-format" style="margin-bottom: 5px;">eg: LDF_PH_WUXI_DEL_20230712.xlsx</h4>
                          <h4 class="accepted-format error" *ngIf="showErrorExcel" style="margin-bottom: 5px; color: red;"> Wrong file Name format .. Please follow the format name of the example!</h4>
                          <h4 class="accepted-format bold">Format : LDF_(PH or CS or VI)_company_library_YYYYMMDD.xlsx </h4>
                          <h4 class="accepted-format">It should have 5 parts separated by “_”:</h4>
                          <h4 class="accepted-format">File name parts:</h4>
                          <h4 class="accepted-format">1. LDF</h4>
                          <h4 class="accepted-format">2. PH, CS or VI.</h4>
                          <h4 class="accepted-format">3. Company : Combination of upper case and lower case letters and numbers.</h4>
                          <h4 class="accepted-format">4. Library : Combination of upper case and lower case letters and numbers.</h4>

                          <h4 class="accepted-format">5. Date YYYYMMDD : alphanumeric</h4>
                          <h4 class="accepted-format">Extension: .xlsx</h4>


                          
                        </div>


                        <div class="row div5">
                            <mat-label class="mat-label"> Upload chemical structure table* </mat-label>
                            <div class="library-container"> 
                            <!-- <div class="drop-zone upload-container" (dragover)="onDragOver($event)" (drop)="onDrop($event)"> -->
                            <div class="drop-zone upload-container">
                              <input type="file" accept=".csv, .gz" #checmicalInput formControlName="chemicalFileInput" (change)="onLibFilesSelected(checmicalInput,libraryStructureFile)" style="display: none">
                              <mat-icon class="upload-icon" (click)="checmicalInput.click()">upload</mat-icon>
                              <span (click)="checmicalInput.click()">
                                <span class="upload-text">Click to select File  </span>
                                <!-- <span> or drag and drop</span> -->
                              </span>
                            </div>
                            </div>
                            <h4 class="accepted-format" > eg: STRUCT_LDF_PH_WUXI_DEL_20230712.csv</h4>
                            <h4 class="accepted-format error" *ngIf="showErrorCSV" style="color: red;">  Wrong file Name format .. Please follow the format name of the example.</h4>
                            <h4 class="accepted-format bold">Format : STRUCT_LDF_(PH or CS or VI)_company_library_YYYYMMDD.csv </h4>
                          <h4 class="accepted-format">It should have 6 parts separated by “_”. Additionally it should match the LDF filename.</h4>
                          <h4 class="accepted-format">File name parts:</h4>
                          <h4 class="accepted-format">1. STRUCT</h4>
                          <h4 class="accepted-format">2. LDF</h4>
                          <h4 class="accepted-format">3. PH, CS or VI.</h4>
                          <h4 class="accepted-format">4. Company : Combination of upper case and lower case letters and numbers.</h4>
                          <h4 class="accepted-format">5. Library : Combination of upper case and lower case letters and numbers.</h4>

                          <h4 class="accepted-format">5. Date YYYYMMDD : alphanumeric</h4>
                          <h4 class="accepted-format">Extension: .csv</h4>  
                            
                        </div>
                        <div class="row div6">
                            <mat-label class="mat-label">Theoretical number of Compounds*</mat-label><br>
             
                                <mat-form-field class="matformfield">
                                    <input matInput  formControlName="compounds" required>
                              </mat-form-field>                       
                        </div>
                      
                        <div class="file-name-size" >
                            <ul  *ngIf="selectedFiles && selectedFiles.length > 0">
                                    <li *ngFor="let file of selectedFiles; let i = index">
                                              <span class="library-file-name"> {{ file['file'].name }}  </span> 
                    
                                                <!-- <button mat-icon-button color="warn" (click)="removeFileByIndex(i)" class="delete-ouline-button" >
                                                  <mat-icon  class="delete-button">delete_outline</mat-icon>
                                                </button> -->
                                    </li>
                    
                                  
                                    <!-- <li *ngFor="let file of selectedFiles">
                                            {{ getFileSize(file.size) }}
                                    </li> -->
                            </ul>
                      </div>
                    </div>
                    <!-- <button mat-raised-button color="primary" (click)="saveForm()">Save</button> -->
                    <div *ngIf="showSuccessMessage" class="success-message">Form saved successfully!</div>
                </div>
            </div>
        </div>

        <div *ngIf="selectedTab == 'tab2'" [formGroup]="ngsFormGroup">
            <!-- Content for Tab 2 -->
            <div class="row add-scientist">
                <p class="form-grid-heading">NGS Files</p>
                <div class="mat-elevation-z8">
                    <br>
                    <span *ngIf="ngsFiles && ngsFiles.length === 0" class="mat-elevation-z8-heading upload-description">Select your NGS Files in order to connect with experimental condition (optional, required for relative abundance enrichment factor calculation).</span>
                    <p *ngIf="ngsFiles && ngsFiles.length > 0 " class="mat-elevation-z8-heading"><span>
                            <mat-icon>done</mat-icon>
                        </span>{{ ngsFiles.length }} NGS file(s) successfully selected</p>
                    <div class="drop-zone " [ngClass]="ngsFiles && ngsFiles.length > 0 ? 'upload-container' : 'large-upload-container'" 
                         (dragover)="onDragOver($event)" (drop)="onDrop($event)" multiple>
                        <input type="file" #fileInput accept=".fastq ,.gz" (change)="onFilesSelected(fileInput)"
                            style="display: none" multiple>
                        <div class="upload-icon mat-icon-upload ">
                            <mat-icon (click)="fileInput.click()">upload</mat-icon>
                        </div>
                        <div *ngIf="errorMessageDuplicateFileUpload">{{ errorMessageDuplicateFileUpload }}</div>
                        <div class="row upload-text ">
                                <span (click)="fileInput.click()" style="color: #2196F3;">Click to select </span><span>NGS
                                    files </span>
                                <span style="display: block;  color:  #6C757D;">
                                    eg.: NGS_PH_PRJ1212_MDM2_1212_DEL20R_R1.fastq</span>
                                <span style="color:red; display: block; margin-top: 10px;" *ngIf="showInputError">
                                   Please follow file's format Name as the example shown </span>

                                   <span style="color:red; display: block; margin-top: 10px;" *ngIf="showNaiveNGSFilesErr">
                                    files uploaded should be either NGS or NAIVE</span>

                                    <span style="color:red; display: block" *ngIf="showErrorOddNGSFiles">
                                        Read one files should equals read two files!
                                    </span>  
                                  
                        </div>
                    </div>
                    <div class="file-info" *ngIf="selectedTab == 'tab2' && ngsFiles && ngsFiles.length > 0">
                        <ul >
                            <li *ngFor="let file of ngsFiles; let i = index">
                                <div class="file-info-grid">
                                    <div class="div11">
                                        <mat-icon> description</mat-icon>{{ file }}
                                    </div>
                                    <div class="div21">

                                        <span>
                                            <button mat-icon-button class="div31" color="warn"
                                                *ngIf="ngsFiles && ngsFiles.length > 0"
                                                (click)="clearFiles(i)">
                                                <mat-icon>delete_outline</mat-icon>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <br>
                </div>
            </div>
        </div>

        <div *ngIf="selectedTab === 'tab3'" [formGroup]="experimentalFormGroup">
            <!-- Content for Tab 3 -->
            <div class="row add-scientist">
                <p class="form-grid-heading">Experimental Conditions</p>
                <div class="mat-elevation-z8" *ngIf="!isXLSXTableManual && !isXLSXTableExport">
                    <br>
                    <span class="mat-elevation-z8-heading">Add your experimental conditions and match to your selected NGS files.</span>
                    <!-- <span class="mat-elevation-z8-heading">Please add NGS file names</span> -->
                    <!-- <button (click)="showXLSXTable()" mat-button color="primary" class="btn-add-library">
                        <mat-icon>add</mat-icon>Add Manually
                    </button> -->
                    <br><br>
                    <div class="drop-zone" [ngClass]="!isXLSXTableExport ? 'large-upload-container' : 'upload-container'"  (dragover)="onDragOver($event)" (drop)="onDrop($event)"
                        multiple>
                        <input type="file" #fileInput accept=".xlsx" (change)="onFilesSelected(fileInput)"
                            formControlName="experimentalFileInput" style="display: none" multiple>
                        <div class="upload-icon">
                            <mat-icon (click)="fileInput.click()">upload</mat-icon>
                        </div>
                        <div class="row upload-text">
                            <span (click)="fileInput.click()" style="color: #2196F3;">Click to Upload conditions
                            </span><span>with excel file</span>
                                <span style="display: block;  color:  #6C757D;" >
                                    eg.: EXP_NGS_PH_PRJ1212_MDM2_1212.xlsx</span>
                                <span style="color:red; display: block" *ngIf="showInputError">
                                   Please follow file's format Name as the example shown </span>

                                
                        </div>
                        <!-- <div class="row upload-text">
                            <span>The file is not working? Click to </span><span (click)="fileInput.click()"
                                style="color: blue;">Download template</span>
                        </div> -->
                    </div>
      
                    <br>
                </div>

                <!-- // TODO: Sherif make it dynamic  -->
                <!-- // TODO: THIS IS MANUAL PART  -->
                <!-- <div class="mat-elevation-z8" *ngIf="isXLSXTableManual">
                    <mat-form-field class="homepage">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
                    </mat-form-field>
                    <br>
                    <div class="table-container-xlsx">
                        <table mat-table [dataSource]="xlData" matSort>
                            <ng-container *ngFor="let column of xlDisplayedColumns" matColumnDef="{{ column }}">
                                <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
                                <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="xlDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: xlDisplayedColumns"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "{{input?.value}}"</td>
                            </tr>
                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"
                        aria-label="Select page of screenList"></mat-paginator>
                    <br>
                </div> -->



                  <!-- // TODO: Sherif make it dynamic  -->
                <!-- // TODO: THIS IS EXPORT PART  -->
                <div class="mat-elevation-z8" *ngIf="isXLSXTableExport && rowData">
                    <!-- <app-table-component 
                    [columns]="xlsxHeaders" 
                    [tableData]="xlsxData"
                      /> -->
                    <!-- -->
                    <div class="flex-end">
                        <button (click)="exportToExcel()" class="export-button"> Export </button>
                    </div>

                    <ag-grid-angular
                        style="width: 100%; height: 350px;"
                        class="ag-theme-alpine"
                        [rowData]="rowData"
                        [columnDefs]="xlsxHeaders"
                        (cellValueChanged)="onCellValueChanged($event)"
                        (gridReady)="onGridReady($event)"
                        [gridOptions]="gridOptions"
                  ></ag-grid-angular> 
                </div>
         
                <span class="ngs-input-error" *ngIf="showEXPvalidationError && isXLSXTableExport  && rowData"> Please make sure to fill all NGS_read_1 / NGS_read_2 inputs and <br>  make sure their values have the same name of one of the upoaded NGS files. </span>
            </div>
        </div>



        <div *ngIf="selectedTab === 'tab4'" [formGroup]="processingFormGroup">
            <!-- Content for Tab 4 -->
            <div class="row add-scientist">
                <p class="form-grid-heading">Processing Parameter</p>
                <div class="mat-elevation-z8">
                    <br>
                    <p class="mat-elevation-z8-heading">Select below one of the process parameter to finalize your
                        screen submission
                    </p>

                    <!-- <div class="row div3">
                        <mat-label class="mat-label">Libraries*</mat-label><br>
                        <mat-form-field class="matformfield">
                            <mat-select class="custom-dropdown-arrow" placeholder="Select role/area"
                                formControlName="libraries" multiple>
                                <mat-select-trigger>
                                    <ng-container
                                        *ngIf="personalFormGroup.get('libraries')?.value as selectedLibraries">
                                        <ng-container *ngFor="let option of selectedLibraries">
                                            <mat-chip class="selected-option" [removable]="true"
                                                (removed)="removeOption3(option)">
                                                {{ option }}
                                                <mat-icon matChipRemove>cancel</mat-icon>
                                            </mat-chip>
                                        </ng-container>
                                    </ng-container>
                                </mat-select-trigger>
                                <mat-option *ngFor="let option of libraryArr" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div> -->

                    <div class="form-grid-asym">
                        <div class="row div1">
                            <mat-label class="mat-label">Sequence Matching method</mat-label><br>
                            <mat-form-field class="matformfield">
                                <mat-select placeholder="value" formControlName="method" (selectionChange)="selectSequenceMatchMethod($event)" >
                                    <mat-option *ngFor="let option of matchMethod" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                       
                        <div class="row div3">
                            <mat-label class="mat-label">Alignment Algorithm</mat-label><br>
                            <mat-form-field class="matformfield">
                                <mat-select placeholder="value" formControlName="algorithm" >
                                    <mat-option *ngFor="let option of alignAlgo1" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                       
                        <div class="row div4">
                            <mat-label class="mat-label">Error Margins</mat-label><br>
                            <mat-form-field class="matformfield">
                                <mat-select placeholder="value" formControlName="errorMargins">
                                    <mat-option *ngFor="let option of errorMarginsValues" [value]="option">
                                        {{ option }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>