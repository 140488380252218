import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FILE_EXTENSION } from 'src/app/enums/File-extension.enum';
import { CommonService } from 'src/app/common.service';
import { FILE_TYPE } from 'src/app/enums/File-type.enum';
import { DataService } from '../../data.service';
import { concatMap, toArray, finalize, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { NotificationModalComponent } from '../notification-modal/notification-modal.component';



@Component({
  selector: 'app-library-modal',
  templateUrl: './library-modal.component.html',
  styleUrls: ['./library-modal.component.css']
})
export class LibraryModalComponent implements OnInit {

  // libraryName!: string;

  libraryName = new FormControl('');
  libraryDefinition = new FormControl('');
  libraryInfo = new FormControl('');
  libraryUpload!: string;
  selectedFile!: any;
  isFileUploaded = false;
  uploadedFile: File | null = null;
  fileControl = new FormControl();
  selectedFiles: File[] = [];
  presignedUrls!: any;
  presignedUrlArr: Object[] = [];

  csvFile: FILE_EXTENSION = FILE_EXTENSION.CSV;
  excelFile: FILE_EXTENSION = FILE_EXTENSION.EXCEL;

  showErrorExcel = false;
  showErrorCSV = false;

  libraryDefinitionFile = FILE_TYPE.LIBRARY_DEFINITION
  libraryStructureFile = FILE_TYPE.LIBRARY_STRUCTURE

  libraryFormGroup!: FormGroup;
  fileUploadFormGroup!: FormGroup;

  fileSelected: boolean = false;
  fileUploaded: boolean = false;

  libraryData: any = []
  showLibraryNameDuplicate: boolean = false
  editMode: boolean = false
  modalOpened: boolean = false
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<LibraryModalComponent>,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private dataService: DataService) {
    this.editMode = this.data?.editMode || false;
    this.libraryFormGroup = this.formBuilder.group({
      libraryName: ['', Validators.required],
      // libraryDefinition: ['', ],
      libraryInfo: ['', Validators.required],

      libraryFileInput: [null],
      chemicalFileInput: [null],
    });
    if (this.data) {
      // TODO:Sherif do it with destructing
      // this.libraryName = this.data.name;
      this.libraryFormGroup.get('libraryName')?.setValue(this.data.library_name)
      // this.libraryFormGroup.get('libraryDefinition')?.setValue(this.data.definition)
      this.libraryFormGroup.get('libraryInfo')?.setValue(this.data.info)
    }
  }

  ngOnInit() {

  }


  onFilesSelected(fileInput: HTMLInputElement, fileType: FILE_TYPE) {
    const files: any | null = fileInput.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        file.fileType = fileType
        if (this.commonService.validateFileName(file, fileType)) {
          // find file type and replace it 
          const indexFound = this.selectedFiles?.findIndex((file: any) => file.fileType == fileType)
          //replace File
          if (indexFound != -1) {
            this.selectedFiles.splice(indexFound, 1);
          }
          this.selectedFiles.push(file);
          if (this.selectedFiles.length == 2) {
            this.fileSelected = true
          }
          this.showErrorExcel = false;
          this.showErrorCSV = false;
        } else {
          if (fileType == this.libraryDefinitionFile) {
            this.showErrorExcel = true;
            this.showErrorCSV = false;
          }
          if (fileType == this.libraryStructureFile) {
            this.showErrorExcel = false;
            this.showErrorCSV = true;
          }
        }
      }
    }
  }

  onFilesSelected1(inputElement: HTMLInputElement): void {
    const files = inputElement.files;
    if (files) {
      this.selectedFiles = Array.from(files);
      // // Reset file input value
      // inputElement.value = '';
    }
  }

  removeFileByIndex(index: any): void {
    this.selectedFiles.splice(index, 1);
    this.fileSelected = false;
    this.fileUploaded = false;
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.selectedFiles = Array.from(files);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    // event.stopPropagation();
  }

  closeModal() {
    this.dialogRef.close();
  }

  updateForm() {
    const data = {
      table_name: "bay-delt-libraries",
      operation_type: "update",
      library_name: this.libraryFormGroup.get('libraryName')?.value,
      info: this.libraryFormGroup.get('libraryInfo')?.value,
    }

    this.dataService.editLibrary(data).subscribe(res => {
      if(res) {
        if (res) {
          this.closeModal();
          this.openSnackBar('Library Updaed successfully!');
        }
      }
    })
  }

  submitForm() {
    // Perform form submission logic here
    // Access input values using this.input1Value, this.input2Value, this.input3Value
    // Access uploaded file using this.selectedFile
    this.fileSelected = true
    const libraryName = this.libraryFormGroup.get('libraryName')?.value;
    
    // const indexFound =  this.libraryData?.findIndex((item: any) => item.library_name == libraryName)
    // if (indexFound != -1) {
    //   this.showLibraryNameDuplicate = true
    //   return;
    // }
    this.showLibraryNameDuplicate = false
    const libraryFilePath = this.libraryFormGroup.get('libraryFileInput')?.value
    const libraryFileSplitted = libraryFilePath.split('\\');
    const libraryFile = libraryFileSplitted && libraryFileSplitted.length >= 0 && libraryFileSplitted[libraryFileSplitted.length - 1]
    const data = {
      table_name: "bay-delt-libraries",
      operation_type: "add",
      library_name: this.libraryFormGroup.get('libraryName')?.value,
      info: this.libraryFormGroup.get('libraryInfo')?.value,
      library_file: libraryFile
    }
    this.dataService.addLibrary(data).subscribe(res => {
      if (res) {
        this.closeModal();
        if(!this.modalOpened) {
          this.modalOpened = true;
          this.commonService.openNotificationDialog(this.dialog, 'New Library successfully created!')
        }

        // this.openSnackBar();
      }
    })

  }

  onFileSelected_old(event: any) {
    const file = event.dataTransfer?.files[0] || event.target.files[0];
    // Rest of the logic to handle the file
    if (file) {
      // Perform your file handling logic here
      this.selectedFile = file;
      this.uploadedFile = file;
      this.isFileUploaded = true;
      this.openSnackBar('File uploaded successfully');
    }
    this.selectedFile = event.target.files[0];
    // const file = event.target.files[0];
    // Handle the file upload logic here
    this.isFileUploaded = true;
    this.openSnackBar('File uploaded successfully');
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileUploaded = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileUploaded = false;
  }

  removeFile() {
    this.uploadedFile = null;
    this.selectedFile = null;
    this.isFileUploaded = false;
    this.fileControl.reset();
  }

  openSnackBar(message?: string) {
    const message1 = message || 'File uploaded !!'
    this.snackBar.open(message1, 'Close', {
      duration: 1000,
    });
  }

  getFileSize(fileSize: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (fileSize === 0) {
      return '0 B';
    }
    const k = 1024;
    const i = Math.floor(Math.log(fileSize) / Math.log(k));
    return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + units[i];
  }

  getPresignedUrl(fileList: any) {
    this.dataService.getPresignedUrl(fileList).subscribe({
      next: (response) => {
       
        const statusCode = response.statusCode;
        if (statusCode === 200) {
          response.body.map((obj: any) => this.presignedUrlArr.push(obj));
          this.uploadFileToS3();
        } else {
          console.log(`There is ${statusCode} error from the server side!!`);
        }
      },
      error: (error) => {
        console.error('Error:', error);
      }
    })
  }

  uploadFileToS3() {
    if (this.selectedFiles.length === this.presignedUrlArr.length) {

      from(this.selectedFiles).pipe(
        concatMap((file: any, i) => {
          let presignedUrlArrEle: any = this.presignedUrlArr[i];
  
          return this.dataService.uploadFileToS3(presignedUrlArrEle['presigned_url'], this.selectedFiles[i]);
        })
      ).subscribe(res=> {
        this.submitForm()
      })
    }
    else {
      console.log('please select at least one file OR there is mis-match in  no. of presigned urls and no. of files we are trying to upload');
    }
  }

  uploadFiles(edit:boolean) {
    if(edit){
      this.updateForm()
    }
    else {
      this.fileUploaded = true;
      this.getPresignedUrl(this.selectedFiles);
    }
  }

  done(edit: boolean) {
    this.uploadFiles(edit);
  }
}