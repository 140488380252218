<div *ngIf="showScientistList">
  <div class="title-home">
    <mat-icon class="mat-icon-home">person_outline</mat-icon>
    <span class="title-breadcrump">Scientists</span>
  </div>
  <div class="select-description-container">
    <span class="description-title">
      Scientists
      <span *ngIf="this.users?.length > 0"> ({{this.users?.length}}) </span>
      <br />
      <span class="description-text">View below the users data in DEL platform</span>
    </span>
    <button (click)="navigateToAddScientistComponent()" mat-flat-button class="btn-add">
      <mat-icon>person_add</mat-icon>
      Add New Scientist
    </button>
  </div>
  <br />
  <mat-divider></mat-divider>
  <br />

  <!--       TABLE PART      -->
  <div class="row homepage">
    <app-table-component [columns]="tableColumns" [tableData]="users" [showCheckbox]="showCheckbox"
      [clickable]="clickable" [showActionMenu]=true listName="Scientist" (showRowDetails)="rowDetails($event)"
      [showEditRow]=true (editRow)="rowDetails($event)" (applyFilter)="applyFilter($event)"
      (deleteRow)="deleteScientist($event)" />
  </div>
</div>

<div *ngIf="showAddRow">
  <div class="row homepage title-breadcrump-container">
    <mat-icon mat-icon-button (click)="goBack()" class="mat-icon-home details">west</mat-icon>
    <span class="title-breadcrump">Scientists</span>
  </div>
  <div class="row message">
    <p>
      <!-- <strong>{{ name ? name : "Creating New" }} Profile</strong> -->
      <strong> Creating New Profile</strong>
    </p>
    <!-- <span class="text">{{ name ? "active sicnce 19.08.2020" : "Insert below the details" }}</span> -->
    <span class="hint-text"> Insert below the details </span>
    <!-- TODO: SHERIF ADD IT  -->
    <!-- <button (click)="addScientistComponent()" mat-flat-button color="primary"
        class="btn-add-scientist"><mat-icon>save</mat-icon>Save</button> -->
  </div>
  <br />
  <div class="row add-scientist">
    <div class="mat-elevation-z8 profile">
      <br />
      <div class="scientist-profile-text-container">
        <span class="form-grid-heading">Scientist Profile</span>
        <span class="edit-profile-button" *ngIf="!showEditRow" (click)="showEditRow = true; addScientistComponent()">
          Edit Profile
          <mat-icon style="padding-left: 5px">edit</mat-icon>
        </span>
        <span class="edit-profile-button" *ngIf="showEditRow" (click)="showEditRow = false; addScientistComponent()">
          <!-- <span class="edit-profile-button" *ngIf="showEditRow" (click)="openNotificationDialog()"> -->
          Save
          <mat-icon style="padding-left: 5px">done</mat-icon>
        </span>
      </div>
      <div class="form-grid">
        <div class="row">
          <mat-label class="mat-label">Name*</mat-label><br />
          <mat-form-field class="matformfield">
            <input matInput placeholder="Insert name" [(ngModel)]="name" [disabled]="!showEditRow" />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label class="mat-label">Role/Area*</mat-label><br />
          <mat-form-field class="matformfield">
            <mat-select placeholder="Select role/area" [(ngModel)]="role" [disabled]="!showEditRow">
              <mat-option *ngFor="let option of optionRole" [value]="option">
                {{ option }}
              </mat-option>
              <!-- <mat-option value="Biologist">Biologist</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label class="mat-label">Project Assign*</mat-label><br />

          <mat-form-field class="matformfield">
            <input matInput placeholder="Project" [(ngModel)]="project" [disabled]="!showEditRow" />
          </mat-form-field>

          <!-- ---- DROP DOWN OPTION -----  -->
          <!-- <mat-form-field class="matformfield">
              <mat-select placeholder="Select projects assign" [formControl]="selectedOptions2" multiple
                [(ngModel)]="projectAssign">
                <mat-select-trigger>
                  <ng-container *ngFor="let option of selectedOptions2.value">
                    <mat-chip class="selected-option" [removable]="true" (removed)="removeOption(option)">
                      {{ option }}
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </ng-container>
                </mat-select-trigger>
                <mat-option *ngFor="let option of optionProjectAssign" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
        </div>
        <div class="row">
          <mat-label class="mat-label">Cwid*</mat-label><br />
          <mat-form-field class="matformfield">
            <input matInput placeholder="Insert cwid" [(ngModel)]="cwid" [disabled]="!showEditRow" />
          </mat-form-field>
        </div>
      </div>
      <!-- <button mat-raised-button color="primary" (click)="saveForm()">Save</button> -->
      <!-- <div *ngIf="showSuccessMessage" class="success-message">
        Form saved successfully!
      </div> -->
    </div>
  </div>
  <div class="screen-history-container" *ngIf="screens && screens.length > 0">
    <span class="screen-history-title"> Screen History </span>
    <div class="row homepage">
      <app-table-component [columns]="screenHistoryColumns" [tableData]="screens" />
    </div>
  </div>
</div>

<div>
  <mat-progress-spinner> </mat-progress-spinner>
</div>