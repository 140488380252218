import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.html',
})
export class ConfirmationModal {
  message: string = "Are you sure you want to delete it?"
  confirmButtonText = "Yes"
  cancelButtonText = "Cancel"
  deleteRow: any;
  rowData: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationModal>) {
      if(data){
    this.message = data.message || this.message;
    this.deleteRow = data.deleteRow;
    this.rowData = data.rowData;
    if (data.buttonText) {
      this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
      }
  }

  onConfirmClick(): void {
    this.deleteRow.emit(this.rowData)
    this.dialogRef.close(true);
  }

}