<div class="mainscreen" *ngIf="screenTab">
    <div class="header-container">
        <div class="title-container">
            <!-- <mat-icon class="mat-icon-home">add_chart</mat-icon> -->
            <img class="title-icon" src="assets/images/chart-bar-blue.svg">
            <span class="title">Screens</span>
        </div>

        <div class="select-description-container">
            <span class="description-title">
                Processed screens overview
              <span *ngIf="this.screenList?.length > 0">  ({{this.screenList?.length}}) </span>
              <br />
              <span class="description-text">Find overview of performed screens, including their experimental status here </span>
            </span>
            <button (click)="openModal()" mat-flat-button class="btn-add">
                <img src="assets/images/chart-bar.svg">
              Process new screen
            </button>
          </div>

        <!-- ============= -->
        <!-- <div class="row message">
            <span class="description-title">Processed Screens
                <span *ngIf="this.screenList?.length > 0">
                    ({{this.screenList?.length}})
                </span> 
            </span>
            <div class="select-description-container">
                <span class="description-text">Select on the project name to open and visualize the screen
                    analysis</span>
                <button (click)="openModal()" mat-flat-button class="btn-add-library">
                    <img src="assets/images/chart-bar.svg">
                    Process new screen</button>
            </div>
        </div> -->
    </div>
    <mat-divider style="margin-bottom: 10px;"></mat-divider>

    <div class="row homepage" *ngIf="showScreenList">
        <app-table-component [columns]="screenListColunms" 
            [tableData]="screenList | screenFilter : filterProjectName : filterScientist : filterLibraries : filterExperimental : filterSubmissionDate" 
            [clickable]="clickable" listName="Screen" [showActionMenu]=true [showEditRow]=false 
            (applyFilter)="applyFilter($event)" (showRowDetails)="screenResults($event)" [showDuplicateOption]=true
            (duplicateRow)="duplicateRow($event)" (reanalyzeRow)="reanalyzeScreen($event)"
            (deleteRow)="deleteScreen($event)" (showDescription)="onShowDescription($event)"
            [filters]="criteria" (filterItem)="onApplyFilter($event)"
             />
    </div>
    <mat-divider style="margin-bottom: 10px;"></mat-divider>
    <button (click)="refreshScreen()" mat-flat-button class="btn-add1">
        <!-- <img src="assets/images/chart-bar.svg"> -->
      Refresh
    </button>
</div>