import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-component',
  templateUrl: './modal-component.component.html',
  styleUrls: ['./modal-component.component.css']
})
export class ModalComponentComponent {

  libraryName!: string;
  libraryDefinition!: string;
  libraryInfo!: string;
  libraryUpload!:string;
  selectedFile!: any;
  isFileUploaded = false;
  uploadedFile: File | null = null;
  fileControl = new FormControl();
  formGroup!: FormGroup;
  selectedFiles: File[] = [];
  

  constructor(private dialogRef: MatDialogRef<ModalComponentComponent>, private snackBar: MatSnackBar) {
    this.formGroup = new FormGroup({
      libraryName: new FormControl(),
      libraryDefinition: new FormControl(),
      libraryInfo: new FormControl()
    });
  }


  onFilesSelected(fileInput: HTMLInputElement) {
    const files: FileList | null = fileInput.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.selectedFiles.push(file);
      }
    }
  }

  onFilesSelected1(inputElement: HTMLInputElement): void {
    const files = inputElement.files;
    if (files) {
      this.selectedFiles = Array.from(files);
      // // Reset file input value
      // inputElement.value = '';
    }
  }

  clearFiles(inputElement: HTMLInputElement): void {
    this.selectedFiles = [];
    // Reset file input value
    inputElement.value = '';
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files) {
      this.selectedFiles = Array.from(files);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    // event.stopPropagation();
  }

  closeModal() {
    this.dialogRef.close();
  }

  submitForm() {
    // Perform form submission logic here
    // Access input values using this.input1Value, this.input2Value, this.input3Value
    // Access uploaded file using this.selectedFile
    console.log('Form data:', this.formGroup.value);
    console.log('Selected file:', this.selectedFiles);
  }

  onFileSelected_old(event: any) {
    const file = event.dataTransfer?.files[0] || event.target.files[0];
    // Rest of the logic to handle the file
    if (file) {
      // Perform your file handling logic here
      this.selectedFile = file;
      this.uploadedFile = file;
      this.isFileUploaded = true;
      this.openSnackBar('File uploaded successfully');
    }
    this.selectedFile = event.target.files[0];
    // const file = event.target.files[0];
    // Handle the file upload logic here
    this.isFileUploaded = true;
    this.openSnackBar('File uploaded successfully');
  }

  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileUploaded = true;
  }
  
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileUploaded = false;
  }

  removeFile() {
    this.uploadedFile = null;
    this.selectedFile = null;
    this.isFileUploaded = false;
    this.fileControl.reset();
  }
  
  openSnackBar(message?: string) {
    const message1 = 'File uploaded !!' 
    this.snackBar.open(message1, 'Close', {
      duration: 1000,
    });
  }

  getFileSize(fileSize: number): string {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (fileSize === 0) {
      return '0 B';
    }
    const k = 1024;
    const i = Math.floor(Math.log(fileSize) / Math.log(k));
    return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + units[i];
  }
}
