import { Component } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})

export class CallbackComponent {
  constructor(private oauthService: OAuthService) {
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

}
