<div *ngIf="showComponentScientist">
    <div class="row homepage">
      <mat-icon class="mat-icon-home">add_chart</mat-icon><span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Frequent Hitter File</span>
    </div>
    <div class="row message">
      <p><strong>Upload Frequent Hitter File </strong></p>
      </div>
    <br><br>    
  </div>
  <div >
      <div class="row homepage">
        <mat-icon mat-icon-button (click) ="goBack()"class="mat-icon-home">west</mat-icon>
        <span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Frequent Hitter | Upload new frequent hitter table</span>
      </div>
      <div class="row message">
       
        <button (click)="uploadFrequencyHit()" [disabled]="!frequencyhitlistFormGroup.valid" mat-flat-button color="primary" class="btn-add-library"><mat-icon>save</mat-icon>Upload</button>
      </div> 
      <br><br>
  
      
      <div class="row add-scientist" *ngIf="!loading">
          <div class="mat-elevation-z8">
              <br>
              <p class="form-grid-heading">Frequent Hitter Table Name</p><br>
          <div class="form-grid-asym" [formGroup]="frequencyhitlistFormGroup">  
              <div class="row div1">
                <div class="drop-zone large-upload-container"  (dragover)="onDragOver($event)" (drop)="onDrop($event)"
                multiple>
            <input type="file" #fileInput accept=".xlsx" (change)="onFilesSelected(fileInput);"
                style="display: none"  formControlName="frequencyHitlistFile" >
            <div class="upload-icon">
                <mat-icon (click)="fileInput.click()">upload</mat-icon>
            </div>
            <div class="row upload-text">
                <span (click)="fileInput.click()" style="color: #2196F3;">Click to Upload 
                </span><span>or drag and drop</span>
                   
      
                    
            </div>
            <div><span (click)="downloadExcel()" style="color: #2196F3; text-align: center;cursor: pointer;">Download</span> the sample Template</div>
            <div class="file-list" *ngIf="selectedFiles.length"><strong>{{selectedFiles[0]['file'].name}}</strong></div>
            <div>
              <h4 class="accepted-format error" style="color: red;" *ngIf="invalidRows.length"> {{ invalidRows }}  </h4>
              &nbsp; 
              <h4 class="accepted-format" style="margin: 5px;">eg: Freq_AnyText.xlsx</h4>
              <h4 class="accepted-format" style="margin: 5px; "> It should start with prefix Freq</h4>
            </div>  
                     
            </div>
            
         
      
              </div>
              <div class="row div2">
                  <mat-label class="mat-label">Name*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <input matInput placeholder="Insert name" formControlName="name" required>               
                  </mat-form-field>
              </div> 
              &nbsp;
                     
          
          </div> 
                 
                <!-- <div *ngIf="showSuccessMessage" class="success-message">Form saved successfully!</div>  -->
          </div>   
      </div> 

      <div *ngIf="loading" class="row add-scientist">
        <app-loading-spinner></app-loading-spinner>
        <!-- <mat-progress-spinner class="custom-spinner" mode="indeterminate"></mat-progress-spinner> -->
        <!-- <div class="container-file-uploading">
            <p>Uploading...</p>
            <mat-progress-spinner class="custom-spinner" mode="indeterminate" diameter="24" strokeWidth="3"></mat-progress-spinner>
        </div>        -->
    </div>   

      <br><br>
      <div class="row add-scientist" *ngif="!loading">
        <div class="mat-elevation-z8">
          <div class="row homepage">
            <app-table-component [columns]="cols" [tableData]="list" [showSearch]="false" listName="freqHitFile"  />  
          </div> 
        </div>
      </div>
    </div>
  
    
      
    
  
  
  
  