import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ModalServiceService } from '../../modal-service.service'; 
import { DataService } from 'src/app/data.service';

export interface ProjectNameType {
  
  project_id: string,
  project_name: string
}

@Component({
  selector: 'app-modal-screen',
  templateUrl: './modal-screen.component.html',
  styleUrls: ['./modal-screen.component.css']
})



export class ModalScreenComponent implements OnChanges {
  
  @Input() dataFromModalChange!: any;
  form!: FormGroup;
  //projectNameArr = ['Project Name 01', 'Project Name 02', 'Project Name 03', 'Project Name 04', 'Project Name 05'];
  projectNameArr: ProjectNameType[] = []
  isScreenModalClosed?: boolean;

  constructor(private modalService: ModalServiceService,
             private dialogRef: MatDialogRef<ModalScreenComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private dataService: DataService) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      projectName: ['', [Validators.required, notEmptyOrWhitespaceValidator()]]
    });

    this.dataService.getProjectName().subscribe(res=> {
      this.projectNameArr = res
     });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes[this.dataFromModalChange]) {
      this.updateData();
    }
  }

  updateData() {
    this.modalService.updateData(this.dataFromModalChange);
  }

  closeModal() {
    this.dialogRef.close();
    this.isScreenModalClosed = true;
  }

  findProjectIdByName(name: string): string | undefined {
  
    var pr = "undefined"
    this.projectNameArr.forEach(item => {
      if(item.project_id == name)
      pr = item.project_name
      return item.project_name
    })
    return pr
}

  onSubmit() {
    if (this.form.valid) {
      const projectName = this.findProjectIdByName(this.form.value.projectName);
     
      const formValues = this.form.value;
      // Close the dialog and pass the form values back to the parent component
      //this.dialogRef.close(formValues);
      this.dialogRef.close({projectId: this.form.value.projectName, projectName: projectName})
      this.isScreenModalClosed = true;
      this.dataFromModalChange = true;
      this.modalService.setDataModalScreen(true);
    }
  }

  onClose() {
    this.form.reset(); 
    // Close the dialog without passing any data
    this.dialogRef.close();
    this.isScreenModalClosed = true;
  }

 
}

// Custom validator function to check for empty or whitespace-only strings
function notEmptyOrWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && control.value.trim() === '') {
      return { 'whitespace': true }; // Return an error if the input is blank or contains only spaces
    }
    return null; // No error, input is valid
  };
}

