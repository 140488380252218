
<div class="card-container">
    <mat-card class="div1">
        <img src="assets/Login_Image.png" class="image">
    </mat-card>
    <mat-card class="div2">
        <mat-card class="div3 center">
            <mat-card-header class="title">
                <mat-card-title>Welcome to DEL Analysis</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <button class="login-button" mat-raised-button color="primary" (click)="login()">Login</button> 
                <mat-card-footer class="footer">Unable to sign in?</mat-card-footer>
                <mat-card-footer class="footer1">Please contact support center</mat-card-footer>              
            </mat-card-content>             
        </mat-card>
    </mat-card>
</div>

  

