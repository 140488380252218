<mat-dialog-actions>
  <div class="row-flex">
  <div class="title">
    <h2>New Screen</h2>
    <p class="text">Add below the project to process new screen</p>
  </div>
  <button class="icon" mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
</mat-dialog-actions>
<!-- <mat-dialog-content> -->
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-label class="mat-label">Project Name*</mat-label><br>
      <mat-form-field class="matformfield">
         <!-- <input matInput placeholder="select project name" formControlName="projectName" required>  -->

          <mat-select placeholder="select project name"  formControlName="projectName" required>
            <mat-option *ngFor="let option of projectNameArr" [value]="option.project_id">
                  {{ option.project_name }}
            </mat-option>
          </mat-select> 
          
        <mat-error *ngIf="form.get('projectName')?.hasError('required')">Please select one project name</mat-error>
        <mat-error *ngIf="form.get('projectName')?.hasError('whitespace')">Project name cannot be blank or contain only spaces</mat-error>
      </mat-form-field>
      <div class="row-flex btn">
        <button mat-button mat-dialog-close color="primary" (click)="onClose();closeModal()"><mat-icon>close</mat-icon>Cancel</button>
        <button mat-button  color="primary" [disabled]="!form.valid" (click)="closeModal()" type="submit"><mat-icon>done</mat-icon>Process New Screen</button>
      </div>
  </form>
<!-- </mat-dialog-content> -->

