<div class="mainscreen">
  <div class="header-container">
      <div class="title-container">
          <!-- <mat-icon class="mat-icon-home">add_chart</mat-icon> -->
          <img class="title-icon" src="assets/images/chart-bar-blue.svg">
          <span class="title">Frequent Hitter Analysis</span>
      </div>

      <div class="select-description-container">
          <span class="description-title">
            Overview of processed screens
            <!-- <span *ngIf="this.screenList?.length > 0">  ({{this.screenList?.length}}) </span> -->
            <br />
            <span class="description-text">Overview of processed screens and uploaded frequent hitter tables, upload of Frequent Hitter Tables, Frequent Hitter Analysis            </span>
          </span>
          <button (click)="openModal()" mat-flat-button class="btn-add">
              <img src="assets/images/chart-bar.svg">
              Start frequent hitter analysis
          </button>
          <button (click)="processNewScreen()" mat-flat-button class="btn-add" style="margin-left: 0;">
            <mat-icon>upload</mat-icon>
            Upload new frequent hitter table
          </button>
        </div>

     
  </div>
  <mat-divider style="margin-bottom: 10px;"></mat-divider>

  <div class="row homepage" *ngIf="showFreqHitter">
      <app-table-component [columns]="screenListColunms" 
          [tableData]="screenList" 
          [clickable]="clickable" listName="freqhit"  
          (showRowDetails)="screenResults($event)" 
          
           />
  </div>
  <mat-divider style="margin-bottom: 10px;"></mat-divider>
  <!-- <button (click)="refreshScreen()" mat-flat-button class="btn-add1">
    Refresh
  </button> -->
</div>