import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-freq-hitter',
  templateUrl: './upload-freq-hitter.component.html',
  styleUrls: ['./upload-freq-hitter.component.css']
})
export class UploadFreqHitterComponent {
  constructor(private formBuilder: FormBuilder,
    private dataService: DataService,
    private snackBar: MatSnackBar,
    private router: Router
) {

}

@ViewChild('fileInput')
fileInput!: ElementRef;
showComponentScientist = true;
showComponentAddScientist = false;
frequencyhitlistFormGroup!: FormGroup;
selectedFiles: any = [];
presignedUrlArr: Object[] = [];
showSuccessMessage: boolean = false;
loading = false;
list: any;
cols: Array<Object> = [
  { key: 'file_name', displayName: 'Name' },
  { key: 'file', displayName: 'File' },
  // { key: 'url', displayName: 'Scientist' } 
];

invalidRows: string[] = [];

addFrequencyHit() {
this.showComponentScientist = false;
this.showComponentAddScientist = true;

}

ngOnInit(): void {

this.frequencyhitlistFormGroup = this.formBuilder.group({
name: ['', Validators.required],
frequencyHitlistFile: ['', Validators.required]


});
this.loadList()
}

onDragOver(event: DragEvent): void {
event.preventDefault();
event.stopPropagation();

}

// Function to handle drop event
onDrop(event: DragEvent) {
event.preventDefault();
event.stopPropagation();
if(event.dataTransfer !== null){
const files:any = event.dataTransfer.files;
}
}

onFilesSelected(fileInput: HTMLInputElement) {
this.selectedFiles = []
const files: FileList | null = fileInput.files;
const file = files![0];
if (file) {
  this.readFile(file);
}
this.selectedFiles.push({
file: file
})

this.fileInput.nativeElement.value = '';
}

readFile(file: File): void {
  const reader = new FileReader();
  reader.onload = (event: any) => {
    const binaryStr: ArrayBuffer = event.target.result;
    this.validateCols(binaryStr);
   
  };
  reader.readAsArrayBuffer(file);
}

validateCols(contents: ArrayBuffer): void {
  
    //const workbook = XLSX.read(contents, { type: 'array' });
    const workbook: XLSX.WorkBook = XLSX.read(contents, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    
    const rows: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    


  if (rows.length > 0) {
    const headers = rows[0];
    const requiredColumns =  ['rule_id','library_id','sublibrary_id','bb1','bb2','bb3','bb4','bb5','code','comment']
   
    const missingHeaders = requiredColumns.filter(header => !headers.includes(header));

    if (missingHeaders.length) {
      this.invalidRows.push(`Missing headers: ${missingHeaders}`);
    } 
    console.log(this.invalidRows)
    // if (columnIndexes.includes(-1)) {
    //   this.invalidRows.push(`One or more required columns are missing in the CSV file ` );
    // }
  }
}

downloadExcel() {
// Define the headers
const headers = [['rule_id', 'library_id', 'sublibrary_id', 'bb1', 'bb2', 'bb3', 'bb4', 'bb5', 'code', 'comment']];

// Create a worksheet
const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(headers);

// Create a workbook
const workbook: XLSX.WorkBook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

// Save the file
XLSX.writeFile(workbook, 'Freq_example.xlsx');
}

uploadFrequencyHit() {
  this.loading = true
this.dataService.getPresignedUrlCsv(this.selectedFiles).subscribe({
next: (response) => {
const statusCode = response.statusCode;
if (statusCode === 200) {
response.body.map((obj: any) => this.presignedUrlArr.push(obj));
this.uploadFileToS3();
} else {
  this.loading = false
console.log(`There is ${statusCode} error from the server side!!`);
}
},
error: (error) => {
  this.loading = false
console.error('Error:', error);
}
})
}

uploadFileToS3() {

const presignedUrlArrEle: any = this.presignedUrlArr[0];
this.dataService.uploadFileToS3FASTQ(presignedUrlArrEle['presigned_url'], 
this.selectedFiles[0]).subscribe(res => {
this.saveFreqHitFile()
});
}

saveFreqHitFile() {
const presignedUrlArrEle: any = this.presignedUrlArr[0];
const payload =       {
"operation_type": "add",
"table_name": "bay-delt-frequency-hit-files",
"file": this.selectedFiles[0]['file'].name,
"file_name": this.frequencyhitlistFormGroup.controls['name'].value,
"url": presignedUrlArrEle['presigned_url']
}

this.dataService.addFreqHitFiles(payload).subscribe(res => {
  this.loading = false
this.openSnackBar('New HitList successfully created !!');

// Save form logic here
// Show success message
this.showSuccessMessage = true;


// Hide success message after 3 seconds
setTimeout(() => {
this.showSuccessMessage = false;
}, 3000);
//this.loadHitlist()
})
}

openSnackBar(message: string) {
this.snackBar.open(message, 'Close', {
duration: 1000,
panelClass: 'custom-snackbar'
});
setTimeout(() => {
//this.goBack();
}, 1000);

}
goBack() {
  this.router.navigate(['frequencyhit']);
}

loadList() {
  this.dataService.getFreqHitFileLists().subscribe(res=> {
    this.list = res
  })
}
}
