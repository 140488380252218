import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import { ModalServiceService } from '../../modal-service.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { DataService } from 'src/app/data.service';




export interface library {
  info: string;
  library_name: string;
  active_since: string;
  library_file: string
}

export interface projectInfo {
  projects: string;
  last_screening: string;
  scientist_name: string;
  active_since: string;
  cwid: string;
  role_area: string
}



@Component({
  selector: 'app-profile',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  
})


export class ProjectComponent implements OnInit {

  projectFormGroup!: FormGroup;

  showComponentScientist = true;
  showComponentAddScientist = false;
  projectDetail = false;
  showSuccessMessage: boolean = false;
  selectedOptions2:any = new FormControl([]);
  projectStatusArr = [];
  projectLeadArr: projectInfo[] = [];
  projectTeamArr: projectInfo[] = [];
  targetProteinArr = [];
  libraryArr: library[] = [];
  projectList: any;
  projectListCount = 0;
  admin = false;
  filterProtein: string[] = []
  filterProjectLead: string[] = []
  filterStatus: string[] = []
  filterProjectName: string[] = []
  filterProjectID: string[] = []
  criteria: any[] = []

  projectListColunms: Array<Object> = [
    { key: 'project_name', displayName: 'Project Name' },
    { key: 'unique_project_id', displayName: 'Project ID' },
    { key: 'project_lead', displayName: 'Project Lead' },
    { key: 'main_target_protein', displayName: 'Target' },
    { key: 'project_status', displayName: 'Status' },
    { key: 'updated_date', displayName: 'Last Screening' },
  ];

 
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(private modalService: ModalServiceService,  
              private snackBar: MatSnackBar, 
              private router: Router, 
              private formBuilder: FormBuilder,
              private commService: CommonService,
              private dataService: DataService) {
    
                this.commService.adminRole$.subscribe(res=> {
                  this.admin =res
                })
  }

  ngOnInit(): void {

    this.projectFormGroup = this.formBuilder.group({
      projectStatus: ['', Validators.required],
      projectName: ['', Validators.required],
      projectID: ['', Validators.required],
      projectLead: ['', Validators.required],
      projectTeam: ['', Validators.required],
      targetProtein: ['', Validators.required],
      library: ['', Validators.required],
      comment: ['']
      
    })

    this.loadProjectList()
    this.getProjectEssentials()


    this.commService.showComponentScientist$.subscribe(res => {
      this.showComponentScientist = res
    })

    this.commService.showComponentAddScientist$.subscribe(res => {
      this.showComponentAddScientist = res
    })
    
  }

  rowDetails(row: any, edit: boolean = true) {
    this.commService.editProject$.next(false)
    this.router.navigate(['project/project-details'], { state: { data: row } });    
  }

  editRow(row: any) {
    this.commService.editProject$.next(true)
    this.router.navigate(['project/project-details'], { state: { data: row } })
  }

  duplicate(row: any) {
    const duplicateName = row.project_name + '_' + 'copy'
    const payload = {   
      "table_name": "bay-delt-projects",
      "operation_type": "add",
      "project_name": duplicateName,
      "project_status": row.project_status,
      "project_lead": row.project_lead,
      "project_team": row.project_team,
      "libraries": row.libraries,
      "main_target_protein": row.main_target_protein,
      "comments": row.comments,
      "user_id": row.user_id,
      "unique_project_id": row.unique_project_id
    }

    this.dataService.addProjectItem(payload).subscribe(res=> {
      this.openSnackBar('Duplicate project successfully created !!');
      this.showSuccessMessage = true;
      this.loadProjectList()
    })
  
  }

  loadProjectList() {
    this.dataService.getProjectList().subscribe(res=> {
        this.projectList = res.body.response;
        this.projectListCount = res.body.count;

        if(this.projectList && this.projectList.length) {
          this.criteria.push({ key: 'project_name', display_name: 'Project Name', searchText: '', value: this.projectList.map((project: any) => project.project_name )})
          this.criteria.push({ key: 'unique_project_id', display_name: 'Project ID', searchText: '', value: this.projectList.map((project: any) => project.unique_project_id )})
          
        }
    }) 
  }

  getProjectEssentials() {
    this.dataService.getProjectStatus().subscribe(res=> {
      let temp = (res.version)
      this.projectStatusArr = (temp)
      this.criteria.push({ key: 'project_status', display_name: 'Status', searchText: '', value: temp})
    })

    this.dataService.getProjectLead().subscribe(res=> {
      this.projectLeadArr = res
      this.projectTeamArr =res
      this.criteria.push({ key: 'project_lead', display_name: 'Project Lead', searchText: '', value: this.projectLeadArr.map(item => item.scientist_name)})
    })
  
    this.dataService.getLibrary().subscribe(res=>{
      this.libraryArr = res
    })

    this.dataService.getProtein().subscribe(res=>{
      this.targetProteinArr = res
      this.criteria.push({ key: 'main_target_protein', display_name: 'Target', searchText: '', value: this.targetProteinArr})
    })
  }

delete(row: any) {
  this.dataService.deleteProjectItem(row).subscribe(res=> {
    this.openSnackBar('Project Item Deleted  !!');
    this.loadProjectList()
  })
   
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
  }

  addProjectComponent(){
    const payload = {
      
        "table_name": "bay-delt-projects",
        "operation_type": "add",
        "project_name": this.projectFormGroup.controls['projectName'].value,
        "project_status": this.projectFormGroup.controls['projectStatus'].value,
        "project_lead": this.projectFormGroup.controls['projectLead'].value,
        "project_team": this.projectFormGroup.controls['projectTeam'].value,
        "libraries": this.projectFormGroup.controls['library'].value,
        "main_target_protein": this.projectFormGroup.controls['targetProtein'].value,
        "comments": this.projectFormGroup.controls['comment'].value,
        "user_id": '123',
        "unique_project_id": this.projectFormGroup.controls['projectID'].value
      }

    this.dataService.addProjectItem(payload).subscribe(res=> {
      this.openSnackBar('New project successfully created !!');
      this.showSuccessMessage = true;
      this.router.navigate(['projects']);
      this.loadProjectList()
    })  
  

    // Hide success message after 3 seconds
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 3000);
  }

  navigateToAddScientistComponent() {
    this.projectFormGroup.reset()
    this.showComponentScientist = false;
    this.showComponentAddScientist = true;
    //this.loadProjectList()
    
  }

  goBack() {
    this.showComponentScientist = true;
    this.showComponentAddScientist = false;
  }

 

  removeOption1(option: string) {
    const selectedProjectTeam = this.projectFormGroup?.get('projectTeam')?.value;
    const index = selectedProjectTeam.indexOf(option);
    if (index >= 0) {
      selectedProjectTeam.splice(index, 1);
      this.projectFormGroup?.get('projectTeam')?.setValue(selectedProjectTeam);
    }
  }

  removeOption3(option: string) {
    const selectedLibrary = this.projectFormGroup?.get('library')?.value as string[];
    const index = selectedLibrary.indexOf(option);
    if (index >= 0) {
      selectedLibrary.splice(index, 1);
      this.projectFormGroup?.get('library')?.setValue(selectedLibrary);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 1000,
      panelClass: 'custom-snackbar'
    });
    setTimeout(() => {
      this.goBack();
    }, 1000);

  }

  onApplyFilter(filterItem: any) {
  this.filterProtein = filterItem[1].value  
  this.filterProjectLead = filterItem[0].value
  this.filterStatus = filterItem[2].value
  this.filterProjectName = filterItem[3].value
  this.filterProjectID = filterItem[4].value
    
  }

}


