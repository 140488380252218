import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'screenFilter',
  pure: false
})
export class ScreenFilter implements PipeTransform {

  transform(arr: any[], filterCol1: any[], filterCol2: any[], filterCol3: any[], filterCol4: any[], filterCol5: any[]): any[]{
    
  if (filterCol1.length == 0 && filterCol2.length == 0 && filterCol3.length == 0 && filterCol4.length == 0 && filterCol5.length == 0) 
      return arr
    let retArr1 = []
    if(filterCol1.length > 0) {
      retArr1 = arr.filter(item => {
        if (filterCol1.includes(item.project_name))
            return true
    });
    } else {
      retArr1 = arr
    }
    
    let retArr2 = []
    if (filterCol2.length > 0) {
      retArr2 = retArr1.filter(item => {
        if (filterCol2.includes(item.scientist_name))
            return true
    });
    } else {
      retArr2 = retArr1
    }

    let retArr3 = []
    if (filterCol3.length > 0) {
      retArr3 =  retArr2.filter(item => {
        if (filterCol3.includes(item.ldf_files))
            return true
    });
    } else {
       retArr3 = retArr2
    }

    let retArr4 = []
    if (filterCol4.length > 0) {
      retArr4 = retArr3.filter(item => {
        if (filterCol4.includes(item.experiment_number))
            return true
    });
    } else {
      retArr4 = retArr3
    }

    let retArr5 = []
    if (filterCol5.length > 1) {

      const from = new Date(filterCol5[0]);
      const to = new Date(filterCol5[1]);
      return retArr4.filter(item => {
        const itemDate = new Date(item.submission);
        return itemDate >= from && itemDate <= to;
    });
    } else {
       return retArr4
    }

     
  }
}