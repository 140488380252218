import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ModalServiceService } from 'src/app/modal-service.service';
import { firstValueFrom } from 'rxjs';


export interface ScreenResultData {
  name: string;
  protein: string;
  molecules: string;
  libraries: string;
  experimentalId: string;
  description: string;
  statusIcon: string;
}


@Component({
  selector: 'app-screen-details',
  templateUrl: './screen-details.component.html',
  styleUrls: ['./screen-details.component.css']
})
export class ScreenDetailsComponent {

  screenResultColunms: Array<Object> = [
    { key: 'condition', displayName: 'Condition name' },
    { key: 'protein', displayName: 'Target Protein' },
    { key: 'totalMolecules', displayName: 'Identified Molecules' },
    { key: 'ldfFiles', displayName: 'Associated libraries' },
    { key: 'experimentNumber', displayName: 'Experimental ID' },
    { key: 'description', displayName: 'Description' },
    { key: 'statusIcon', displayName: ' ' },
  ];


  screenResultDataSource: MatTableDataSource<ScreenResultData>;
  showScreenList: boolean = true;
  showBarchart: boolean = false;
  screenResult: any;
  clickable: boolean = true;
  screenName!: string;
  screenDetails: any;
  statisticsList: any;
  screenResultList: any;
  chartData: any;
  loading: boolean = false;

  constructor(private dialog: MatDialog,
    private modalService: ModalServiceService,
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

    this.screenResultDataSource = new MatTableDataSource(this.screenResult);
    let screenDetailsStorage: any= localStorage.getItem('screenDetails');
    this.screenDetails = JSON.parse(screenDetailsStorage);
    this.screenName = this.screenDetails.screen_name;
    this.getScreenStatistics();

  }

  ngOnInit() {
  }

  getScreenStatistics() {
    this.dataService.getScreenStatistics(this.screenName).subscribe(res => {
      this.statisticsList = [...res];
      this.screenResultList = [...this.statisticsList].map(item => {
        return {
          condition: item.Condition,
          protein: this.screenDetails.protein,
          totalMolecules: item.total_molecules, 
          ldfFiles: this.screenDetails.ldf_files,
          experimentNumber: this.screenDetails.experiment_number,
          description: this.screenDetails.description,
          statusIcon: this.screenDetails.screen_status
        }
      })
      this.chartData = [...this.statisticsList];
    })
    
  }

  async getHitmilesAndCSVImportURL(screenName: string, condition: string, ngs_files: string, row: any) {
    this.loading = true;
    const api1Observable = this.dataService.getHitmiles(screenName, condition, ngs_files, "screen");
    const api2Observable = this.dataService.getHitmilesCSVPresignedURL(screenName, condition, ngs_files, "screen");
    try {
      const [api1Response, api2Response] = await Promise.all([
        firstValueFrom(api1Observable),
        firstValueFrom(api2Observable)
      ]);
      // Handle the responses of both API calls
      if (api1Response.statusCode === 200 &&  api2Response.statusCode === 200 && 
          api1Response.body.length !== 0 && api2Response.body !== null ) {
        localStorage.setItem('hitmiles_data', JSON.stringify(api1Response.body));
        localStorage.setItem('hitmiles_data_total_count', JSON.stringify(api1Response.count));
        localStorage.setItem('getHitmilesParams', JSON.stringify({screenName:screenName, condition:condition,ngs_files:ngs_files}));
        localStorage.setItem('hitmiles_CSV_PresignedURL', JSON.stringify(api2Response.body));
        this.loading = false;
        this.router.navigate(['screens/chart-analysis', row.condition]);
      } else if (api1Response.statusCode !== 200 || api1Response.body.length === 0 ||
         api2Response.statusCode !== 200 || api2Response.body === null) {
          this.loading = false;
          alert("Could not fetched complete data there might be some server error!!");
      }
    } catch (error) {
      // Handle errors in the Promise.all or individual promises
      this.loading = false;
      alert("There is a server error!!");
      
    }
  }



  // getHitmiles(screenName:string, condition:string, ngs_files:string, row:any){
  //   this.loading = true;
  //   this.dataService.getHitmiles(screenName, condition, ngs_files).subscribe(res => {
  //     if(res.statusCode === 200 && res.body.length !== 0){
  //       localStorage.setItem('hitmiles_data', JSON.stringify(res.body));
  //       localStorage.setItem('hitmiles_data_total_count', JSON.stringify(res.count));
  //       localStorage.setItem('getHitmilesParams', JSON.stringify({screenName:screenName, condition:condition,ngs_files:ngs_files}))
  //       this.loading = false;
  //       this.router.navigate(['screens/chart-analysis', row.condition]);
  //     }else if(res.statusCode !== 200 || res.body.length === 0) {
  //       alert("There is server error !!");
  //     }
  //   })
  //   this.dataService.getHitmilesCSVPresignedURL(screenName, condition, ngs_files).subscribe(res => {
  //     if(res.statusCode === 200 && res.body.length !== 0){
  //       localStorage.setItem('hitmiles_CSV_PresignedURL', JSON.stringify(res.body));
  //       this.loading = false;
  //       this.router.navigate(['screens/chart-analysis', row.condition]);
  //     }else if(res.statusCode !== 200 || res.body === null) {
  //       alert("There is server error !!");
  //     }
  //   })
  // }

  // getHitmilesCSVPresignedURL(screenName:string, condition:string, ngs_files:string, row:any){
  //   this.dataService.getHitmilesCSVPresignedURL(screenName, condition, ngs_files).subscribe(res => {
  //     localStorage.setItem('hitmiles_CSV_PresignedURL', JSON.stringify(res.body));
  //   })
  // }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.screenResultDataSource.filter = filterValue.trim().toLowerCase();

    if (this.screenResultDataSource.paginator) {
      this.screenResultDataSource.paginator.firstPage();
    }
  }
  showDescription(value: any): void {
    this.modalService.updateMyVariable( this.screenDetails );
  }


  toggleBarchartView(row: any) {
    const inputString = this.screenDetails.ngs_files;
    // Split the string by commas
    const valuesArray = inputString.split(',');
    // Get the first value (at index 0)
    const firstValue = valuesArray[0];
    
    this.getHitmilesAndCSVImportURL(this.screenName, row.condition, firstValue, row);

    // this.getHitmiles(this.screenName, row.condition, firstValue, row);
    // this.getHitmilesCSVPresignedURL(this.screenName, row.condition, firstValue, row);
    const index = this.statisticsList.findIndex((data: any)=> data.Condition == row.condition)

    localStorage.setItem('chartData', JSON.stringify(this.chartData[index]));
    // this.router.navigate(['screens/chart-analysis', row.condition]);
  }

  navigateToScreenDetails() {
    this.router.navigate(['screens/screen-details', 1]);
  }

  navigateToScreenList() {
    this.router.navigate(['screens']);
  }
}
