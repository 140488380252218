<div class="title">
    <h2>Adding New Library</h2>
    <p class="text">Enter below the information</p>
</div>
<form [formGroup]="formGroup" (ngSubmit)="submitForm()">
  <mat-form-field class="matformfield">
    <input matInput placeholder="Insert Library Name" formControlName="libraryName">
  </mat-form-field>
  <mat-form-field class="matformfield">
    <input matInput placeholder="Insert Library Definition" formControlName="libraryDefinition">
  </mat-form-field>
  <mat-form-field class="matformfield">
    <input matInput placeholder="Insert Library Info" formControlName="libraryInfo">
  </mat-form-field>
  <div class="drop-zone upload-container" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
    <input type="file" #fileInput (change)="onFilesSelected(fileInput)" style="display: none">
    <mat-icon class="upload-icon" (click)="fileInput.click()">upload</mat-icon>
    <span class="upload-text"(click)="fileInput.click()">Click to upload </span><span>or drag and drop</span>
  </div>
  <div class="file-info">
    <p *ngIf="selectedFiles && selectedFiles.length > 0">{{ selectedFiles.length }} file(s) selected</p>
    <button mat-icon-button color="warn" *ngIf="selectedFiles && selectedFiles.length > 0" (click)="clearFiles(fileInput)">
      <mat-icon class="delete-button">delete_outline</mat-icon>
    </button>
  </div>
  <div class="file-name-size">
        <ul  *ngIf="selectedFiles && selectedFiles.length > 0">
                <li *ngFor="let file of selectedFiles">
                            {{ file.name }} 
                </li>
                <li *ngFor="let file of selectedFiles">
                        {{ getFileSize(file.size) }}
                </li>
        </ul>
  </div>
</form>
<div class="submit-close-button">
  <button mat-button color="primary" (click)="closeModal()"><mat-icon>close</mat-icon>Cancel</button>
  <button mat-button color="primary" (click)="submitForm();closeModal();openSnackBar();"><mat-icon>done</mat-icon>Add Library</button>
</div>

