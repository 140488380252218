<div class="title">
    <h2 style="margin-bottom: 0px;" *ngIf="!editMode">Adding New Library</h2>
    <h2 style="margin-bottom: 0px;" *ngIf="editMode">Editing Library</h2>
    <p class="text">Enter below the information</p>
</div>
<form [formGroup]="libraryFormGroup" (ngSubmit)="submitForm()">
  <mat-form-field class="matformfield"     >
    <input matInput placeholder="Insert Library Name"   [readonly]="editMode" formControlName="libraryName">
  </mat-form-field>
  <span class="duplicate-error" *ngIf="showLibraryNameDuplicate"> Duplicate Library name, please insert another name!</span>

  <!-- <mat-form-field class="matformfield">
    <input matInput placeholder="Insert Library Definition"  formControlName="libraryDefinition">
  </mat-form-field> -->
  <mat-form-field class="matformfield">
    <input matInput placeholder="Insert Library Description" formControlName="libraryInfo" >
  </mat-form-field>

  <div *ngIf="!editMode">
  <h3 class="upload-library-title"> Upload library definition* </h3>
  <div class="library-container">
  <!-- <div class="drop-zone upload-container" (dragover)="onDragOver($event)" (drop)="onDrop($event)"> --> 
  <div class="drop-zone upload-container">
    <input type="file" #fileInput formControlName="libraryFileInput" (change)="onFilesSelected(fileInput,libraryDefinitionFile)"  accept=".xlsx" style="display: none">
    <mat-icon class="upload-icon" (click)="fileInput.click()">upload</mat-icon>
    <span (click)="fileInput.click()">
      <span class="upload-text">Click to select File</span>
      <!-- <span> or drag and drop</span> -->
    </span>
 
  </div>

</div>
<h4 class="accepted-format" style="margin-bottom: 5px;">eg: LDF_PH_WUXI_DEL_20230712.xlsx</h4>
<h4 class="accepted-format error" *ngIf="showErrorExcel" style="margin-bottom: 5px;"> Wrong file Name format .. Please follow the format name of the example!</h4>



<h3 class="upload-chemical-title"> Upload chemical structure table* </h3>
<div class="library-container"> 
<!-- <div class="drop-zone upload-container" (dragover)="onDragOver($event)" (drop)="onDrop($event)"> -->
<div class="drop-zone upload-container">
  <input type="file" accept=".csv" #checmicalInput formControlName="chemicalFileInput" (change)="onFilesSelected(checmicalInput,libraryStructureFile)" style="display: none">
  <mat-icon class="upload-icon" (click)="checmicalInput.click()">upload</mat-icon>
  <span (click)="checmicalInput.click()">
    <span class="upload-text">Click to select File  </span>
    <!-- <span> or drag and drop</span> -->
  </span>
</div>
</div>
<h4 class="accepted-format" > eg: STRUCT_LDF_PH_WUXI_DEL_20230712.csv</h4>
<h4 class="accepted-format error" *ngIf="showErrorCSV">  Wrong file Name format .. Please follow the format name of the example.</h4>


<!-- <div class="file-info">
  <p *ngIf="selectedFiles && selectedFiles.length > 0">{{ selectedFiles.length }} file(s) selected</p>
  <button mat-icon-button color="warn" *ngIf="selectedFiles && selectedFiles.length > 0" (click)="clearFiles(fileInput)">
    <mat-icon class="delete-button">delete_outline</mat-icon>
  </button>
</div> -->
  <div class="file-name-size" >
        <ul  *ngIf="selectedFiles && selectedFiles.length > 0">
                <li *ngFor="let file of selectedFiles; let i = index">
                          <span class="library-file-name"> {{ file.name }}  </span> 

                            <button mat-icon-button color="warn" (click)="removeFileByIndex(i)" class="delete-ouline-button" >
                              <mat-icon  class="delete-button">delete_outline</mat-icon>
                            </button>
                </li>

              
                <!-- <li *ngFor="let file of selectedFiles">
                        {{ getFileSize(file.size) }}
                </li> -->
        </ul>
  </div>

</div>
</form>
<div class="submit-close-button">
  <button mat-button color="primary" [disabled]=" !libraryFormGroup.valid  || !fileSelected" (click)="done(false)"  *ngIf="!editMode"><mat-icon>done</mat-icon>Submit</button>
  <button mat-button color="primary" [disabled]=" !libraryFormGroup.valid" (click)="done(true)"  *ngIf="editMode"><mat-icon>done</mat-icon>Update</button>
  <button class="cancel-button" mat-button  (click)="closeModal()"><mat-icon>close</mat-icon>Cancel</button>
</div>

