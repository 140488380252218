import { Component } from '@angular/core';

@Component({
  selector: 'no-admin',
  templateUrl: './no-admin.component.html',
  styleUrls: ['./no-admin.component.css']
})
export class NoAdminComponent {

}
