<mat-form-field>
    <mat-label>Input</mat-label>
    <input matInput>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Select</mat-label>
    <mat-select>
      <mat-option value="one">First option</mat-option>
      <mat-option value="two">Second option</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Textarea</mat-label>
    <textarea matInput></textarea>
</mat-form-field>