import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError, timeout } from 'rxjs';
import { Subject } from 'rxjs';
import axios from 'axios';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  //DEV
  private apiUrl = environment.apiURL;
  private apiKey = environment.apiKey;
  private dynamoDBURL = environment.dynamoDBURL;
  private screenResultUrl = environment.screenResultUrl;
  private hitlistResultUrl = environment.hitlistResultUrl
  private readonly TIMEOUT = 15000; // Timeout in milliseconds (e.g., 10 seconds)

  //QA
  // private apiUrl = environment.apiURL_QA;
  // private apiKey = environment.apiKey_QA;
  // private dynamoDBURL = environment.dynamoDBURL_QA;
  // private screenResultUrl = environment.screenResultUrl_QA;

  // PROD
  // private apiUrl = environment.apiURL_PROD;
  // private apiKey = environment.apiKey_PROD;
  // private dynamoDBURL = environment.dynamoDBURL_PROD;
  // private screenResultUrl = environment.screenResultUrl_PROD;


  isUserLoggedIn: Subject<boolean> = new Subject<boolean>();
  

  basicHeaders: HttpHeaders;
  constructor(private http: HttpClient) {
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
  }

  getValidationToken(token: any): Observable<any> {
    const postData = `Bearer ${token}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(`${this.apiUrl}/validate-jwt-token`, {}, { headers });
  }

  getPresignedUrl(fileList: any): Observable<any> {
    // Replace with the specific endpoint of the third-party API
    const listArr: object[] = [];
    fileList.map((obj: any) => listArr.push({ 'name': obj.name, 'type': obj.type }));
    
    const postData = JSON.stringify(listArr);
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(`${this.apiUrl}/presigned-url`, postData, { headers });
  }

  getPresignedUrlCsv(fileList: any): Observable<any> {
    // Replace with the specific endpoint of the third-party API
    const listArr: object[] = [];
    fileList.map((obj: any) => listArr.push({ 'name': obj['file'].name, 'type': obj['file'].type}));
   
    const postData = JSON.stringify(listArr);
    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(`${this.apiUrl}/presigned-url`, postData, { headers });
  }


  getPresignedUrlFASTQ(fileList: any): Observable<any> {
      // Replace with the specific endpoint of the third-party API
      const listArr: object[] = [];
      fileList.map((obj: any) => {
        if(obj.type === ""){
          listArr.push({ 'name': obj['file'].name, 'type': 'application/fastq'});
        }else {
          listArr.push({ 'name': obj['file'].name, 'type': obj['file'].type});
        }
      });
  
      const postData = JSON.stringify(listArr);
      const token = localStorage.getItem('access_token');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'x-api-key': this.apiKey
      });
      return this.http.post<any>(`${this.apiUrl}/presigned-url`, postData, { headers });

  }

  getPresignedUrlNGS(ngsFile: any, context: string): Observable<any> {
    // Replace with the specific endpoint of the third-party API
    let chunk: number[] =[]
    let name: string[] = []
    ngsFile.map((item: any) => {
      let chunkSize = (context === 'ldf' || context === 'structure')? item.size/1000000000 : item['file'].size/1000000000
      chunkSize = Math.ceil(chunkSize)
      if (context === 'ldf' || context === 'structure') {
        name.push(item.name)
      } else {
        name.push(item['file'].name)
      }
     
      if (chunkSize < 1) {
        chunk.push(1)
        
      }
      else {
        chunk.push(chunkSize)
      }
        
      
    }) 

    const newPayload = {
      "num_parts": chunk,
      "method_type": "Initiated",
      "filename": name,
      "context": context
    }


    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(`${this.apiUrl}/multipart-upload`, newPayload, { headers });
    
}

  uploadFileToS3(presignedUrl: string, file: File): Observable<any> {
    const postData = JSON.stringify({ 'presignedUrl': presignedUrl, 'fileData': file });
    const token = localStorage.getItem('id_token');
    const headers = new HttpHeaders({
      'Content-Type': file.type
    });
    return this.http.put<any>(presignedUrl, file);
  }

  uploadFileToS3FASTQ(presignedUrl: string, file: any): Observable<any> {
    const fileToBeUpload = file['file'];
    const postData = JSON.stringify({ 'presignedUrl': presignedUrl, 'fileData': file });
    const token = localStorage.getItem('id_token');
    const headers = new HttpHeaders({
      'Content-Type': file['file'].type
    });
    return this.http.put<any>(presignedUrl, fileToBeUpload, {observe: 'response'});
  }

  uploadMultiPartFile(p: any): Observable<any> {
  
    const newPayload = {
      "method_type": "completed",
      "body": p,
      "context": 'none'
    }

    const token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(`${this.apiUrl}/multipart-upload`, newPayload, { headers })

  }

  downloadFileFromS3(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadFileFromS3AsZip(presignedUrl: string[], screenName: string, condition: string, ngs_files: string): Observable<Blob>{
    const data = {
      "type": 'download',
      "preSignedUrls": presignedUrl,
      "context": 'download',
      "screen_name": screenName,
      "condition": condition,
      "ngs_files": ngs_files
    } 
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
      return this.http.post<any>(this.screenResultUrl, data, { headers: this.basicHeaders});
  }
  


  // PART: LIBRARY END POINTS //

  getLibrariesData(): Observable<any> {
    const data = {
      "table_name": "bay-delt-libraries",
      "operation_type": "data"
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getLatestLibrariesData(): Observable<any> {
    const data = {
      "table_name": "bay-delt-libraries",
      "operation_type": "data",
      "latest": true
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }



  addLibrary(data: any): Observable<any> {
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  editLibrary(data: any): Observable<any> {

    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  deleteLibrary(libraryName: string): Observable<any> {
    const data = {
      "table_name": "bay-delt-libraries",
      "operation_type": "delete",
      "library_name": libraryName
    }
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  // PART: LIBRARY END POINTS //


  // PART SCREENS //

  getProcessedScreens(): Observable<any> {
    const data = {
      table_name: "bay-delt-screens",
      operation_type: "data"
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getFreqHitterLists(): Observable<any> {
    const data = {
      "table_name": "bay-delt-frequency-hit-files",
      "operation_type": "data"
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }
  
  getLatestProcessedScreens(): Observable<any> {
    const data = {
      table_name: "bay-delt-screens",
      operation_type: "data",
      latest: true
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  addScreen(data: any): Observable<any> {
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }


  deleteScreen(screenName: string): Observable<any> {
    const data = {
      table_name: "bay-delt-screens",
      operation_type: "delete",
      screen_name: screenName
    }
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getScreenStatistics(screenName: string): Observable<any> {
    const data = {
      "table_name": "bay-delt-statistics",
      "operation_type": "conditions_data",
      screen_name: screenName
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getLibraryStatistics(libraryName: string): Observable<any> {
    const data = {
      "table_name": "bay-delt-library-statistics",
      "operation_type": "conditions_data_libraries",
      "screen_name": libraryName
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getHitmiles(screenName: string, condition: string, ngs_files:string, context: string): Observable<any> {
  
  const payload = {
    "screen_name": screenName,
    "condition": condition,
    "ngs_files": ngs_files,
    "type": 'data',
    "context": context

  }
  const token = localStorage.getItem('access_token');
  this.basicHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'x-api-key': this.apiKey
  });
    
    return this.http.post<any>(this.screenResultUrl, payload, { headers: this.basicHeaders }).pipe(timeout(60000));

  }

  getHitmilesCSVPresignedURL(screenName: string, condition: string, ngs_files:string, context: string): Observable<any> {
  
  const payload = {
    "screen_name": screenName,
    "condition": condition,
    "ngs_files": ngs_files,
    "type": 'export',
    "context": context

  }
  const token = localStorage.getItem('access_token');
  this.basicHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'x-api-key': this.apiKey
  });
    return this.http.post<any>(this.screenResultUrl, payload, { headers: this.basicHeaders});
  }

  // PART SCREENS END


  // PART Scientists START //

  addScientist(data: any): Observable<any> {
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getScientists(): Observable<any> {
    const data = {
      table_name: "bay-delt-scientists",
      operation_type: "data"
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  deleteScientist(cwid: string): Observable<any> {
    const data = {
      table_name: "bay-delt-scientists",
      operation_type: "delete",
      cwid: cwid
    }
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }
  
  // PART Scientists END //




  getScreenHistory(scientistName: string): Observable<any> {
    const data = {
      "table_name": "bay-delt-scientists",
      "operation_type": "history",
      "cwid": scientistName
    }

    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }


  getAPIVersion(): Observable<any> {
    const data = {
      "operation_type": "version"
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }



  // Project API's listed below. Hitlist and Screenlist based on project  ID also mentioned below

  getProjectList(): Observable<any> {
    const data = {
      table_name: "bay-delt-projects",
      operation_type: "data"
    }
    
    return this.http.post<any>(this.dynamoDBURL, data, {headers: this.basicHeaders});
  }

  getLatestProjectList(): Observable<any> {
    const data = {
      table_name: "bay-delt-projects",
      operation_type: "data",
      latest: true 
    }
    
    return this.http.post<any>(this.dynamoDBURL, data, {headers: this.basicHeaders});
  }

  getProjectName(): Observable<any> {
    const data = {
      operation_type: "project_list"
    }
    return this.http.post<any>(this.dynamoDBURL, data, {headers: this.basicHeaders})
  }

  deleteProjectItem(item: any): Observable<any> {
    const payload= {
      "table_name": "bay-delt-projects",
      "operation_type": "delete",
      "project_id": item.project_id
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  addProjectItem(payload: any) {
   
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  //addFreqHitter(payload: )

  updateProjectItem(payload: any) {
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  getProjectStatus(): Observable<any> {
    const payload = {
      "operation_type": "project_status"
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  getProjectLead(): Observable<any> {
    const payload = {
      "table_name": "bay-delt-scientists",
      "operation_type": "data"
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  getLibrary(): Observable<any> {
    const payload = {
      "table_name": "bay-delt-libraries",
      "operation_type": "data"
    }

    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  getProtein(): Observable<any> {
    const payload = {
      "table_name": "bay-delt-target-protein",
      "operation_type": "data"
    }

    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  getScreenList(projectID: string): Observable<any> {
    const payload = {
      "table_name": "bay-delt-screens",
      "operation_type": "data",
      "project_id": projectID
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  getHitList(projectID: string): Observable<any> {
    const payload = {
      "table_name": "bay-delt-hitlists",
      "operation_type": "data",
      "project_id": projectID
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  // Api for Hitlist page

  getHitLists(): Observable<any> {
    const payload = {
      "table_name": "bay-delt-hitlists",
      "operation_type": "data"
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});

  }

  getLatestHitlists(): Observable<any> {
    const data = {
      "table_name": "bay-delt-hitlists",
      "operation_type": "data",
      "latest": true
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getAnalysedBy(): Observable<any> {
    const payload = {
      "operation_type": "hit_list_data",
      "data_type": "analyzedby_list"
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  getScientistList(): Observable<any> {
    const payload = {
      "operation_type": "hit_list_data",
      "data_type": "scientist_list"
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});
  }

  addHitlist(payload: any): Observable<any> {
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders})
  }

  getHitlistDetails(payload: any): Observable<any> {
    
    const queryParams = new HttpParams()
    .set('operation_type', payload.operation_type)
    .set('hitlist_id', payload.hitlist_id)
    .set('table_name', payload.table_name)
    .set('context', payload.context )
    return this.http.post<any>(this.hitlistResultUrl, payload, {headers: this.basicHeaders}).pipe(
      timeout(this.TIMEOUT), // Set timeout
      catchError(this.handleError) // Handle errors
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'File size is too big';
    // if (error.name === 'TimeoutError') {
    //   errorMessage = 'Request timed out. Please try again.';
    // } else if (error.error instanceof ErrorEvent) {
    //   // Client-side or network error
    //   errorMessage = `Client-side error: ${error.error.message}`;
    // } else {
    //   // Backend error
    //   errorMessage = `Server error: ${error.status}, ${error.message}`;
    // }
    // console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }



  deleteHitlist(hitlistID: string): Observable<any> {
    const data = {
      "table_name": "bay-delt-hitlists",
      "operation_type": "delete",
      "hitlist_id": hitlistID
    }
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getScreen(screenName: string): Observable<any> {
    const data = {
      "table_name": "bay-delt-screens",
      "operation_type": "data",
      "screen_name": screenName
    }
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  scanDynamoDB(name: string, table: string): Observable<any> {
    const data = {
      "table_name": table,
      "operation_type": "scan",
      "name": name
    }
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  addFreqHitFiles(payload: any): Observable<any> {
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders})
  }

  getProcessedFreqHit(): Observable<any> {
    const data = {
      table_name: "bay-delt-frequent-hit",
      operation_type: "data"
    }
    const token = localStorage.getItem('access_token');
    this.basicHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
      'x-api-key': this.apiKey
    });
    return this.http.post<any>(this.dynamoDBURL, data, { headers: this.basicHeaders });
  }

  getFreqHitFileLists(): Observable<any> {
    const payload = {
      "table_name": "bay-delt-frequency-hit-files",
      "operation_type": "data"
    }
    return this.http.post<any>(this.dynamoDBURL, payload, {headers: this.basicHeaders});

  }

 }
