<div *ngIf="showComponentScientist">
    <div class="row homepage">
      <mat-icon class="mat-icon-home">add_chart</mat-icon><span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Hit Lists</span>
    </div>
    <div class="row message">
      <p><strong>Hit Lists Overview ({{count}})</strong></p>
      <span class="text">Hit list is a storage of Re-imported, prioritized compound lists analyzed with external software(e.g. Spotfire)</span>
      <button (click)="addNewAnalysis()" mat-flat-button color="primary" class="btn-add-library"><mat-icon>file_download</mat-icon>Import New Analysis</button>
    </div>
    <br><br>
    <!-- <div class="row homepage">
      <mat-form-field class="homepage">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Bayer" #input>
        </mat-form-field>
     </div> -->

     <div class="row homepage">
      <app-table-component [columns]="hitListColunms" [tableData]="hitList | hitlistFilter : filterAnalyzed : filterScientist : filterProjectName : filterImportDate" listName="Hitlist"  
      (showRowDetails)="rowDetails($event)"  (showScreen)="onShowScreen($event)" [clickable]="true"  
      [showActionMenu]=true (deleteRow)="delete($event)" (checkedRow)="export($event)" [showCheckbox]="true" [export]="true"
      [filters]="criteria" (filterItem)="onApplyFilter($event)" />
  
      
     </div>     
  </div>
  <div *ngIf="showComponentAddScientist">
      <div class="row homepage">
        <mat-icon mat-icon-button (click) ="goBack()"class="mat-icon-home">west</mat-icon>
        <span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Hit List | create a new hit list</span>
      </div>
      <div class="row message">
        <p><strong>Import New Analysis</strong></p>
        <span class="text">Insert related information about the new Hit list.</span>
        <button (click)="addHitlist()" [disabled]="!hitlistFormGroup.valid || invalidRows.length > 0" mat-flat-button color="primary" class="btn-add-library"><mat-icon>save</mat-icon>Save</button>
      </div>
      <br><br>

      
      <div class="row add-scientist">
          <div class="mat-elevation-z8">
              <br>
              <p class="form-grid-heading">Project Details</p><br>
          <div class="form-grid-asym" [formGroup]="hitlistFormGroup">  
              <div class="row div1a">
                <div class="drop-zone large-upload-container"  (dragover)="onDragOver($event)" (drop)="onDrop($event)"
                multiple>
            <input type="file" #fileInput accept=".csv" (change)="onFilesSelected(fileInput);"
                style="display: none"  formControlName="hitlistFile" multiple>
            <div class="upload-icon">
                <mat-icon (click)="fileInput.click()">upload</mat-icon>
            </div>
            <div class="row upload-text">
                <span (click)="fileInput.click()" style="color: #2196F3;">Click to Upload 
                </span><span>or drag and drop</span>
                   
      
                    
            </div>
                     
            </div>
            <div class="file-list" *ngIf="selectedFiles.length">{{selectedFiles[0]['file'].name}}</div>
            <div class="error">
              <ul *ngIf="invalidRows.length > 0">
                <li *ngFor="let row of invalidRows">{{ row }}</li>
              </ul>
            </div>
      
              </div>
              <div class="row div1">
                  <mat-label class="mat-label">Name*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <input matInput placeholder="Insert name" formControlName="hitlistName" required>               
                  </mat-form-field>
              </div>
              <div class="row div2">
                <mat-label class="mat-label">Project Name*</mat-label><br>
                <mat-form-field class="matformfield">
                <mat-select placeholder="select project name" #projectName formControlName="uniqueProjectID" 
                (selectionChange)="getScreens(projectName.value)"  required>
                  <mat-option *ngFor="let option of projectNameArr" [value]="option.project_id">
                        {{ option.project_name }}
                  </mat-option>
                </mat-select>
                </mat-form-field> 
             </div>
             <!-- <div class="row div3">
              <mat-label class="mat-label">Project ID*</mat-label><br>
              <mat-form-field class="matformfield">
                  <input matInput  formControlName="projectID" [value]="projectName.value">
              </mat-form-field>
             </div> -->
             <div class="row div4">
                <mat-label class="mat-label">Project ID*</mat-label><br>
                <mat-form-field class="matformfield">
                    <input matInput  formControlName="projectID"  readonly required >
                </mat-form-field>
             </div>
             <div class="row div5">
                <mat-label class="mat-label">Screens*</mat-label><br>
                <mat-form-field class="matformfield">
                  <mat-select placeholder="Select Screens" [(value)]="selectedObjects" 
                    (selectionChange)="selectionChanged($event)" multiple formControlName="screens" required>
                    <!-- <mat-select-trigger>
                        <ng-container *ngFor="let option of selectedOptions4.value">
                          <mat-chip class="selected-option" [removable]="true" (removed)="removeOption1(option.screen_name)">
                            {{ option.screen_name }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                        </ng-container>
                      </mat-select-trigger> -->
                      <mat-option *ngFor="let option of screensArr" [value]="option">
                          {{ option.screen_name }}
                      </mat-option>
                  </mat-select>
              </mat-form-field>
             </div> 
             <div class="row div6">
                  <mat-label class="mat-label">Hit list length*</mat-label><br>
                  <mat-form-field class="matformfield">
                    <input matInput  formControlName="hitlistLength" readonly required>
                </mat-form-field>
                 
              </div>
              <div class="row div7">
                  <mat-label class="mat-label">Analysed by*</mat-label><br>
                <mat-form-field class="matformfield">
                <mat-select placeholder="please select"  formControlName="analysedBy" required>
                  <mat-option *ngFor="let option of analysedArr" [value]="option">
                        {{ option }}
                  </mat-option>
                </mat-select>
                </mat-form-field> 
              </div>
              <div class="row div8">
                <mat-label class="mat-label">Scientist*</mat-label><br>
                <mat-form-field class="matformfield">
                <mat-select placeholder="select scientist"  formControlName="scientist" required>
                  <mat-option *ngFor="let option of scientistList" [value]="option">
                        {{ option }}
                  </mat-option>
                </mat-select>
                </mat-form-field> 
            </div>
            <div class="row div9">
                <mat-label class="mat-label">hit list Descriptions</mat-label><br>
                <mat-form-field class="matformfieldtextarea">
                    <textarea matInput placeholder="Please enter comments" rows="10" formControlName="comment"></textarea>
                </mat-form-field>
             </div> 
             <input matInput formControlName="projectName"  hidden> 
             <!-- <input matInput formControlName="projectName2" [value]="" hidden>  -->
          </div> 
                <!-- <button mat-raised-button color="primary" (click)="saveForm()">Save</button> -->  
                <div *ngIf="showSuccessMessage" class="success-message">Form saved successfully!</div> 
          </div>   
      </div>     
    </div>

    
      
    
  
  

