<div *ngIf="personalFormGroup" [formGroup]="personalFormGroup">
    <div class="row homepage">
        <mat-icon mat-icon-button (click) ="goBack()"class="mat-icon-home">west</mat-icon>
        <span >Frequent Hitter Analysis</span>
    </div>
    
    <div class="row message">
        <span class="text">Insert details below</span>
        <button (click)="saveDetails()" mat-flat-button color="primary" class="btn-add-library" ><mat-icon>save</mat-icon>Save</button>
      
    </div> 
    <div *ngIf="loading" class="row homepage">
        <!-- <app-loading-spinner></app-loading-spinner> -->
        <!-- <mat-progress-spinner class="custom-spinner" mode="indeterminate"></mat-progress-spinner> -->
        <div class="container-file-uploading">
            <p>Uploading...</p>
            <mat-progress-spinner class="custom-spinner" mode="indeterminate" diameter="24" strokeWidth="3"></mat-progress-spinner>
        </div>       
    </div>   
    <div class="row add-scientist" >
        <div class="mat-elevation-z8">
            <br>
            <p class="mat-elevation-z8-heading">Add below the  details</p>
            <div class="form-grid-asym">
                <div class="row div1">
                    <mat-label class="mat-label">Screen Name*</mat-label><br>
                    <mat-form-field class="matformfield">
                        <mat-select placeholder="value" formControlName="screen" #screenName
                        (selectionChange)="getScreenDetails(screenName.value)">
                        <input matInput [(ngModel)]="screenItem" [value]="screenItem" class="search-input"
                        [ngModelOptions]="{standalone: true}" placeholder="type to search" (keyup)="valuechange($event)"  >
                            <mat-option *ngFor="let option of filterList" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                   </mat-form-field>

                    
                </div>
                <div class="row div2">
                    <mat-label class="mat-label">Frequent Hitter Analysis Name*</mat-label><br>
                    <mat-form-field class="matformfield">
                    <input matInput  formControlName="name"   required >
                    </mat-form-field>
                </div>
                <div class="row div3">
                    <mat-label class="mat-label">Select Frequent Hitter Table*</mat-label><br>
                    <mat-form-field class="matformfield">
                        <mat-select placeholder="value" formControlName="freq_hit_files" #screenName>
                            <mat-option *ngFor="let option of freqHitterList" [value]="option.file">
                                {{ option.file_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row div4">
                    <mat-label class="mat-label">Project Name*</mat-label><br>
                    <mat-form-field class="matformfield">
                    <input matInput  formControlName="projectName"  readonly required >
                    </mat-form-field>
                </div>
                <div class="row div5">
                    <mat-label class="mat-label">Screener*</mat-label><br>
                    <mat-form-field class="matformfield">
                    <input matInput  formControlName="scientistName"  readonly required >
                    </mat-form-field>
                </div>
                <div class="row div6">
                    <mat-label class="mat-label">Protein*</mat-label><br>
                    <mat-form-field class="matformfield">
                    <input matInput  formControlName="protein"  readonly required >
                    </mat-form-field>
                </div>
                <div class="row div7">
                    <mat-label class="mat-label">Experimental No.*</mat-label><br>
                    <mat-form-field class="matformfield">
                    <input matInput  formControlName="experiment_number"  readonly required >
                    </mat-form-field>
                </div>
                <div class="row div8">
                    <mat-label class="mat-label">Submission Date</mat-label><br>
                    <mat-form-field class="matformfield">
                    <input matInput  formControlName="submission"  readonly required >
                    </mat-form-field>
                </div>
                
                    <input matInput  formControlName="cwid"  hidden required >
                    <input matInput  formControlName="single_read"  hidden required >
                 
                    <input matInput  formControlName="project_id"  hidden required >
                    <input matInput  formControlName="info"  hidden required >
                    <input matInput  formControlName="ngs_files"  hidden required >
                    <input matInput  formControlName="ldf_files"  hidden required >
                    <input matInput  formControlName="exp_file"  hidden required >
                    <input matInput  formControlName="alignment_algorithms"  hidden required >
                    <input matInput  formControlName="error_margin"  hidden required >
                    <input matInput  formControlName="is_whole_sequence_exact_match"  hidden required >
                    <!-- <input matInput  formControlName="freq_hit_files"  hidden required > -->
                 
                <!-- <div class="row div2">
                    <mat-label class="mat-label">Screener *</mat-label><br>.

                    <mat-form-field class="matformfield">
                        <mat-select placeholder="value" formControlName="screener" >
                            <mat-option *ngFor="let option of screeners" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="row div3">
                    <mat-label class="mat-label">Library File*</mat-label><br>
                    <mat-form-field class="matformfield">
                        <mat-select class="custom-dropdown-arrow" placeholder="Select role/area"
                            formControlName="libraries" multiple >
                            <mat-select-trigger>
                                <ng-container
                                    *ngIf="personalFormGroup.get('libraries')?.value as selectedLibraries">
                                    <ng-container *ngFor="let option of selectedLibraries" >
                                        <mat-chip class="selected-option" [removable]="true"
                                            (removed)="removeOption3(option)">
                                            {{ option }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                    </ng-container>
                                </ng-container>
                            </mat-select-trigger>
                            <mat-option *ngFor="let option of libraryArr" [value]="option.ldf_files">
                                {{ option.library_name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="row div4">
                    <mat-label class="mat-label">Experiment Number*</mat-label><br>
                    <mat-form-field class="matformfield">
                        <input matInput placeholder="Insert Experiment Number" type="text" formControlName="experiment">
                    </mat-form-field>
                </div>
                <div class="row div5">
                    <mat-label class="mat-label">Target protein*</mat-label><br>
     
                        <mat-form-field class="matformfield">
                            <input matInput placeholder="Insert protein" formControlName="protein" required>
                      </mat-form-field>                       
                </div>
                <div class="row div6">
                    <mat-label class="mat-label">Description</mat-label><br>
                    <mat-form-field class="matformfieldtextarea">
                        <textarea matInput placeholder="Describe in detail..." rows="10" formControlName="description"></textarea>
                    </mat-form-field>
                </div> -->
            </div>
            <!-- <button mat-raised-button color="primary" (click)="saveForm()">Save</button> -->
            <!-- <div *ngIf="showSuccessMessage" class="success-message">Form saved successfully!</div> -->
        </div>
    </div>
</div>
