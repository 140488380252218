import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ModalServiceService } from '../../modal-service.service';
import { FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalScreenComponent } from '../modal-screen/modal-screen.component';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import * as XLSX from 'xlsx';
import { CommonService } from 'src/app/common.service';
import { MatButtonToggle } from '@angular/material/button-toggle';
// import * as ExcelJS from 'exceljs';
import {
  GridApi, GridOptions, GridReadyEvent, RowClassParams, RowStyle,
} from 'ag-grid-community'
import 'ag-grid-enterprise';
import { FILE_TYPE } from 'src/app/enums/File-type.enum';
import { DataService } from 'src/app/data.service';
import { concatMap, from, forkJoin } from 'rxjs';

@Component({
  selector: 'app-process-new-freqhit',
  templateUrl: './process-new-freqhit.component.html',
  styleUrls: ['./process-new-freqhit.component.css']
})
export class ProcessNewFreqhitComponent implements OnInit{

  personalFormGroup!: FormGroup;
  screenNameList = [];
  filterList = [];
  screenList: any
  freqHitterList: any
  freqHitterNameList = []
  loading = false
  screenItem = ''

  constructor(private dialog: MatDialog,
    private modalService: ModalServiceService,
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService) {

      this.dataService.getProcessedScreens().subscribe(res => {
        if (res) {
          this.screenList = [...res]
          this.screenNameList = res.filter((screen: any) => screen.screen_status === 'Successful')  // Filter for successful status
          .map((screen: any) => `${screen.screen_name}`);    
          this.filterList = this.screenNameList       // Map to screen names
        }
      })
      this.dataService.getFreqHitterLists().subscribe(res => {
        if (res) {
          this.freqHitterList = [...res]
          //this.freqHitterNameList = res.map((freq: any) => `${freq.file_name}`)
        }
      })
  }

  valuechange(e: any) {
    console.log(e)
    console.log(this.screenItem);
    this.filterList = this.screenNameList.filter((item: string)=> item.toLowerCase().includes(this.screenItem.toLowerCase()) )
  }


  ngOnInit(): void {
    this.personalFormGroup = this.formBuilder.group({
      screen: ['', Validators.required],
      name: ['', Validators.required],
      projectName: ['', Validators.required],
      scientistName: ['', Validators.required],
      protein: ['', Validators.required],
      experiment_number: ['', Validators.required],
      single_read: ['', Validators.required],
      cwid: ['', Validators.required],
      project_id: ['', Validators.required],
      info: ['', Validators.required],
      ngs_files: ['', Validators.required],
      ldf_files: ['', Validators.required],
      exp_file: ['', Validators.required],
      alignment_algorithms: ['', Validators.required],
      error_margin: ['', Validators.required],
      is_whole_sequence_exact_match: ['', Validators.required],
      freq_hit_files: ['', Validators.required],
    });
  }

  getScreenDetails(screenName: string) {
    let selectedList = this.screenList.filter( (screen: any) => screen.screen_name === screenName)
    console.log(selectedList)
    this.personalFormGroup?.get('projectName')?.setValue(selectedList[0]?.project);
    this.personalFormGroup?.get('scientistName')?.setValue(selectedList[0]?.scientist_name);
    this.personalFormGroup?.get('protein')?.setValue(selectedList[0]?.protein);
    this.personalFormGroup?.get('experiment_number')?.setValue(selectedList[0]?.experiment_number);
    this.personalFormGroup?.get('submission')?.setValue(selectedList[0]?.submission);
    this.personalFormGroup?.get('single_read')?.setValue(selectedList[0]?.single_read);
    this.personalFormGroup?.get('cwid')?.setValue(selectedList[0]?.cwid);
    this.personalFormGroup?.get('project_id')?.setValue(selectedList[0]?.project_id);
    this.personalFormGroup?.get('info')?.setValue(selectedList[0]?.info);

    this.personalFormGroup?.get('ngs_files')?.setValue(selectedList[0]?.ngs_files);
    this.personalFormGroup?.get('ldf_files')?.setValue(selectedList[0]?.ldf_files);
    this.personalFormGroup?.get('exp_file')?.setValue(selectedList[0]?.exp_file);
    this.personalFormGroup?.get('alignment_algorithms')?.setValue(selectedList[0]?.alignment_algorithms);
    this.personalFormGroup?.get('error_margin')?.setValue(selectedList[0]?.error_margin);

    this.personalFormGroup?.get('is_whole_sequence_exact_match')?.setValue(selectedList[0]?.is_whole_sequence_exact_match);
    this.personalFormGroup?.get('freq_hit_files')?.setValue(selectedList[0]?.freq_hit_files);
    
    
  }

  saveDetails() {
    this.loading = true
    const payload = {
      
      "table_name": "bay-delt-frequent-hit",
      "operation_type": "add",
      "single_read":this.personalFormGroup.controls['single_read'].value,
      "screen_name":this.personalFormGroup.controls['screen'].value,

      "freq_hit_name": this.personalFormGroup.controls['name'].value, 

      "protein":this.personalFormGroup.controls['protein'].value,
      "scientist_name":this.personalFormGroup.controls['scientistName'].value,
      "cwid":this.personalFormGroup.controls['cwid'].value,
      "project":this.personalFormGroup.controls['projectName'].value,
      "project_id":this.personalFormGroup.controls['project_id'].value,
      "experiment_number":this.personalFormGroup.controls['experiment_number'].value,
      "screen_status": 'Initiated',
      "info":this.personalFormGroup.controls['info'].value,
      "ngs_files":this.personalFormGroup.controls['ngs_files'].value,
      "ldf_files":this.personalFormGroup.controls['ldf_files'].value,
      "exp_file":this.personalFormGroup.controls['exp_file'].value,
      "alignment_algorithms":this.personalFormGroup.controls['alignment_algorithms'].value,
      "error_margin":this.personalFormGroup.controls['error_margin'].value,
      "is_whole_sequence_exact_match":this.personalFormGroup.controls['is_whole_sequence_exact_match'].value,
      "freq_hit_files":this.personalFormGroup.controls['freq_hit_files'].value  // source of the files
    }

    console.log(payload)

  this.dataService.addProjectItem(payload).subscribe(res=> {
    // this.openSnackBar('New project successfully created !!');
    // this.showSuccessMessage = true;
    // this.router.navigate(['projects']);
    console.log(res)
    this.loading = false
    //this.loadProjectList()
  })  
  }

  goBack() {
    this.router.navigate(['frequencyhit']);
  }

}
