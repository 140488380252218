<div>
    <div class="header-container">
        <div class="title-container">

            <mat-icon class="mat-icon-home" (click)="navigateToScreenList()">arrow_back</mat-icon>
            <span class="breadcrumb" (click)="navigateToScreenList()">Frequent Hitter </span>
            <span class="breadcrumb"> | </span>
            <span class="breadcrumb"> {{this.screenName}}</span>
        </div>
        
        <div class="row message">
            <span class="process-screen"> Conditions </span>
            <div class="select-description-container">
                <span class="select-description">View below the over view of the results</span>
                <!-- <button (click)="openModal()" mat-flat-button  class="btn-add-library"><mat-icon>add_chart</mat-icon>Process new screen</button> -->
                <!-- <button (click)="showDescription(true)" mat-flat-button class="btn-add-library export-button chart-button zero-margin">
                    Show Description <img src="assets/images/arrow-up-right.svg" />
                </button> -->
            </div>
        </div>
    </div>
    <mat-divider style="margin-bottom: 10px;"></mat-divider>

    <div *ngIf="!loading" class="row homepage">
        <app-table-component [columns]="screenResultColunms" [tableData]="screenResultList" [clickable]="clickable"
            listName="Screen Result" (applyFilter)="applyFilter($event)" [showActionMenu]=true
            (showDescription)="showDescription($event)" (showRowDetails)="toggleBarchartView($event)" />
    </div>
    <div *ngIf="loading" class="loading-container">
        <app-loading-spinner></app-loading-spinner>
    </div>

</div>
