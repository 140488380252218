
<mat-sidenav-container class="slide-container" autosize >
  <mat-sidenav #sidenav mode="over"  position="end">
    <div *ngIf="descriptionObject != undefined">
    <!-- Content of the slide bar goes here -->
    <!-- TODO:Sherif MAKE THIS PART BELLOW IN A SEPARE COMPONENT -->
    <div>
      <span class="side-nav-header">Screen Description</span>
    </div>

    <div>
      <span  class="side-nav-title"> {{descriptionObject.screen_name}}</span>
    </div>

    <div>
      <span class="side-nav-key">Description</span>
      <span class="side-nav-value">{{descriptionObject.description}}</span>
    </div>
    <mat-divider style="margin: 10px 0;"/>
    <div>
      <span class="side-nav-key">Screener</span>
      <span class="side-nav-value">{{descriptionObject.scientist_name}}</span>
    </div>
    <mat-divider style="margin: 10px 0;"/>
    <div>
      <span class="side-nav-key">Libraries</span>
      <span class="side-nav-value"> {{descriptionObject.ldf_files}}</span>
    </div>
    <mat-divider style="margin: 10px 0;"/>
    <div *ngIf="descriptionObject.protein">
      <span class="side-nav-key">Main target protein</span>
      <span class="side-nav-value">{{descriptionObject.protein}}</span>
    </div>
    <mat-divider style="margin: 10px 0;"/>
    <div>
      <span class="side-nav-key">Experiment Number</span>
      <span class="side-nav-value">{{descriptionObject.experiment_number}}</span>
    </div>
    <mat-divider style="margin: 10px 0;"/>
    <div>
      <span class="side-nav-key">NGS Files</span>
      <span class="side-nav-value" style="word-wrap: break-word;">{{descriptionObject.ngs_files}}</span>
    </div>
    <mat-divider style="margin: 10px 0;"/>
    <div *ngIf="descriptionObject?.single_read">
      <span class="side-nav-key">Merged/Paired</span>
      <span class="side-nav-value" style="word-wrap: break-word;" *ngIf="descriptionObject.single_read=='True'">Merged</span>
      <span class="side-nav-value" style="word-wrap: break-word;" *ngIf="descriptionObject.single_read=='False'">Paired</span>
    </div>
  </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <!--  SSO-sherif -->
<mat-drawer-container class="delt-container" *ngIf="isUserLoggedIn">
  
  <!-- <mat-drawer-container class="delt-container" *ngIf="true"> -->
  <!-- <mat-drawer-container class="delt-container" > -->
  <mat-drawer class="delt-drawer" mode="side" opened>
    <div class="content">
      <img src="assets/Bayer_Logo.png" alt="BAYER" width="40" height="40">
      <span class="text">DEL Analysis Tool</span>
    </div>
    <mat-nav-list class="menu-list">
      <a mat-list-item routerLink="/home"><mat-icon class="mat-icon">home</mat-icon>Homepage</a>
      <a mat-list-item routerLink="/projects"><mat-icon class="mat-icon">folder_open</mat-icon>Projects</a>
      <a mat-list-item routerLink="/screens"><mat-icon class="mat-icon">add_chart</mat-icon>Screens</a>
      <a mat-list-item routerLink="/hitlists"><mat-icon class="mat-icon">add_chart</mat-icon>Hit lists</a>
      <a mat-list-item routerLink="/frequencyhit"><mat-icon class="mat-icon">add_chart</mat-icon>Frequent Hitters</a>
      <a mat-list-item routerLink="/libraries"><mat-icon class="mat-icon">library_books</mat-icon>Libraries</a>
      <a mat-list-item routerLink="/scientists"><mat-icon class="mat-icon">person_outline</mat-icon>Scientists</a>
      <a mat-list-item routerLink="/contactsupport"><mat-icon class="mat-icon">contacts</mat-icon>Contact Support</a>
      <a mat-list-item routerLink="/helpcentre"><mat-icon class="mat-icon">info_outline</mat-icon>Help Centre</a>
    </mat-nav-list>
    <div class="drawer-footer">
      <p>{{apiVersion}}</p>
      <p>{{uiVersion}}</p>
    </div>
  </mat-drawer>
  <mat-drawer-content class="delt-drawer-content">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="circular-avatar">{{userNameAvtar}}</div>
        <mat-card-title>{{userName}}</mat-card-title>
        <mat-card-subtitle *ngIf="role && role.length >=1">
          <span *ngIf="roleAdmin">ApplicationAdmin</span>
          <span *ngIf="!roleAdmin">ApplicationUser</span>
        </mat-card-subtitle>
        <button mat-button (click)="logout()">
          <mat-icon class="mat-icon-rtl-mirror">logout</mat-icon>
        </button> 
      </mat-card-header>
    </mat-card>
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<div *ngIf="!isUserLoggedIn">

  <div class="card-container">
    <mat-card class="div1">
        <img src="assets/Login_Image.png" class="image">
    </mat-card>
    <mat-card class="div2">
        <mat-card class="div3 center">
            <mat-card-header class="title">
                <mat-card-title>Welcome to DEL Analysis</mat-card-title>
              </mat-card-header>
            <mat-card-content>
                <button class="login-button" mat-raised-button color="primary" (click)="login()">Login</button> 
                <mat-card-footer class="footer">Unable to sign in?</mat-card-footer>
                <mat-card-footer class="footer1">Please contact support center</mat-card-footer>              
            </mat-card-content>           
        </mat-card>
    </mat-card>
  </div>
</div>

