<div *ngIf="showComponentScientist">
    <div class="row homepage">
      <mat-icon class="mat-icon-home">folder_open</mat-icon><span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Projects</span>
    </div>
    <div class="row message">
      <p><strong>Projects overview ({{12}})</strong></p>
      <span class="text">Select on the project name to open and visuaize the project details</span>
      <button (click)="navigateToAddScientistComponent()" mat-flat-button color="primary" class="btn-add-library"><mat-icon>add</mat-icon>New Project</button>
    </div>
    <br><br>
    <div class="row homepage">
      <mat-form-field class="homepage">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Bayer" #input>
        </mat-form-field>
     </div>
     <div class="row homepage">
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>
        
            <!-- Name Column -->
            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row"> {{row.Name}} </td>
            </ng-container>
        
            <!-- Project Lead Column -->
            <ng-container matColumnDef="ProjectLead">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Lead </th>
              <td mat-cell *matCellDef="let row"> {{row.ProjectLead}} </td>
            </ng-container>
        
            <!-- Target Column -->
            <ng-container matColumnDef="Target">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Target </th>
              <td mat-cell *matCellDef="let row"> {{row.Target}} </td>
            </ng-container>
        
            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
            </ng-container>
  
            <!-- Last Screening Column -->
            <ng-container matColumnDef="LastScreening">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Screening </th>
              <td mat-cell *matCellDef="let row"> {{row.LastScreening}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
        </div>
      </div>     
  </div>
  <div *ngIf="showComponentAddScientist">
      <div class="row homepage">
        <mat-icon mat-icon-button (click) ="goBack()"class="mat-icon-home">west</mat-icon>
        <span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Projects</span>
      </div>
      <div class="row message">
        <p><strong>Creating New Project</strong></p>
        <span class="text">Insert details below</span>
        <button (click)="addProjectComponent()" mat-flat-button color="primary" class="btn-add-library"><mat-icon>save</mat-icon>Save</button>
      </div>
      <br><br>
      <div class="row add-scientist">
          <div class="mat-elevation-z8">
              <br>
              <p class="form-grid-heading">Project Details</p><br>
          <div class="form-grid-asym">
              <div class="row div1">
                  <mat-label class="mat-label">Project Status*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <!-- <input matInput placeholder="Insert name" [(ngModel)]="projectStatus"> -->
                      <mat-select placeholder="On Going" [formControl]="selectedOptions1"  [(ngModel)]="projectStatus">
                        <mat-option *ngFor="let option of projectStatusArr" [value]="option">
                            {{ option }}
                        </mat-option>
                    <!-- <mat-option value="On Going">On Going</mat-option> -->
                    </mat-select>
                  </mat-form-field>
              </div>
              <div class="row div2">
                <mat-label class="mat-label">Project Name*</mat-label><br>
                <mat-form-field class="matformfield">
                    <input matInput placeholder="Insert project name" [(ngModel)]="projectName">
                </mat-form-field>
             </div>
             <div class="row div3">
                <mat-label class="mat-label">Project ID*</mat-label><br>
                <mat-form-field class="matformfield">
                    <input matInput placeholder="Insert project id" [(ngModel)]="projectID">
                </mat-form-field>
             </div>
             <div class="row div4">
                <mat-label class="mat-label">Project Lead*</mat-label><br>
                <mat-form-field class="matformfield">
                    <mat-select placeholder="select project lead" [formControl]="selectedOptions3"  [(ngModel)]="projectLead">
                      <mat-option *ngFor="let option of projectLeadArr" [value]="option">
                          {{ option }}
                      </mat-option>
                  </mat-select>
                </mat-form-field>
             </div>
              <div class="row div5">
                  <mat-label class="mat-label">Project Team*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <mat-select placeholder="Select team" [formControl]="selectedOptions4" multiple [(ngModel)]="projectTeam">
                        <mat-select-trigger>
                            <ng-container *ngFor="let option of selectedOptions4.value">
                              <mat-chip class="selected-option" [removable]="true" (removed)="removeOption1(option)">
                                {{ option }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </ng-container>
                          </mat-select-trigger>
                          <mat-option *ngFor="let option of projectTeamArr" [value]="option">
                              {{ option }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="row div6">
                  <mat-label class="mat-label">Target protein*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <mat-select placeholder="Select protein" [formControl]="selectedOptions5" multiple [(ngModel)]="targetProtein">
                          <mat-select-trigger>
                              <ng-container *ngFor="let option of selectedOptions5.value">
                                <mat-chip class="selected-option" [removable]="true" (removed)="removeOption2(option)">
                                  {{ option }}
                                  <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                              </ng-container>
                            </mat-select-trigger>
                            <mat-option *ngFor="let option of targetProteinArr" [value]="option">
                              {{ option }}
                            </mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="row div7">
                <mat-label class="mat-label">Libraries*</mat-label><br>
                <mat-form-field class="matformfield">
                    <mat-select class="custom-dropdown-arrow" placeholder="Select role/area" [formControl]="selectedOptions6" multiple  [(ngModel)]="library">
                        <mat-select-trigger>
                            <ng-container *ngFor="let option of selectedOptions6.value">
                              <mat-chip class="selected-option" [removable]="true" (removed)="removeOption3(option)">
                                {{ option }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </ng-container>
                          </mat-select-trigger>
                        <mat-option *ngFor="let option of libraryArr" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row div8">
                <mat-label class="mat-label">Comments</mat-label><br>
                <mat-form-field class="matformfieldtextarea">
                    <textarea matInput placeholder="Please enter comments" rows="10" [(ngModel)]="comment"></textarea>
                </mat-form-field>
             </div>
          </div> 
                <!-- <button mat-raised-button color="primary" (click)="saveForm()">Save</button> -->  
                <div *ngIf="showSuccessMessage" class="success-message">Form saved successfully!</div> 
          </div>   
      </div>     
    </div>

    
      
    
  
  
