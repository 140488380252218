import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ModalServiceService } from 'src/app/modal-service.service';
import { firstValueFrom } from 'rxjs';


export interface ScreenResultData {
  name: string;
  protein: string;
  molecules: string;
  libraries: string;
  experimentalId: string;
  description: string;
  statusIcon: string;
}


@Component({
  selector: 'app-freq-hit-details',
  templateUrl: './freq-hit-details.component.html',
  styleUrls: ['./freq-hit-details.component.css']
})
export class FreqHitDetailsComponent {

  screenResultColunms: Array<Object> = [
    { key: 'condition', displayName: 'Condition name' },
    { key: 'protein', displayName: 'Target Protein' },
    { key: 'totalMolecules', displayName: 'Identified Molecules' },
    { key: 'ldfFiles', displayName: 'Associated libraries' },
    { key: 'experimentNumber', displayName: 'Experimental ID' },
    { key: 'description', displayName: 'Description' },
    { key: 'statusIcon', displayName: ' ' },
  ];


  screenResultDataSource: MatTableDataSource<ScreenResultData>;
  showScreenList: boolean = true;
  showBarchart: boolean = false;
  screenResult: any;
  clickable: boolean = true;
  screenName!: string;
  freqName!: string;
  screenDetails: any;
  statisticsList: any;
  screenResultList: any;
  chartData: any;
  loading: boolean = false;

  constructor(private dialog: MatDialog,
    private modalService: ModalServiceService,
    private dataService: DataService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

    this.screenResultDataSource = new MatTableDataSource(this.screenResult);
    let screenDetailsStorage: any= localStorage.getItem('screenDetails');
    this.screenDetails = JSON.parse(screenDetailsStorage);
    this.screenName = this.screenDetails.screen_name;
    this.freqName = this.screenDetails.freq_hit_name
    this.getScreenStatistics();

  }

  ngOnInit() {
  }

  getScreenStatistics() {
    this.dataService.getScreenStatistics(this.screenName).subscribe(res => {
      this.statisticsList = [...res];
      this.screenResultList = [...this.statisticsList].map(item => {
        return {
          condition: item.Condition,
          protein: this.screenDetails.protein,
          totalMolecules: item.total_molecules, 
          ldfFiles: this.screenDetails.ldf_files,
          experimentNumber: this.screenDetails.experiment_number,
          description: this.screenDetails.description,
          statusIcon: this.screenDetails.screen_status
        }
      })
      this.chartData = [...this.statisticsList];
    })
    
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.screenResultDataSource.filter = filterValue.trim().toLowerCase();

    if (this.screenResultDataSource.paginator) {
      this.screenResultDataSource.paginator.firstPage();
    }
  }
  showDescription(value: any): void {
    this.modalService.updateMyVariable( this.screenDetails );
  }



  toggleBarchartView(row: any) {
    this.loading = true
    const inputString = this.screenDetails.ngs_files;
    // Split the string by commas
    const valuesArray = inputString.split(',');
    // Get the first value (at index 0)
    const firstValue = valuesArray[0];
    this.dataService.getHitmilesCSVPresignedURL(this.freqName, row.condition, firstValue, "freqhit").subscribe(res=> {
      console.log(res)
      this.dataService.downloadFileFromS3(res.body).subscribe((data: Blob) => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
  
        // Create an invisible anchor element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download =  this.freqName + '_' + row.condition +'.csv'; // Set the file name
        a.style.display = 'none';
        document.body.appendChild(a);
        this.loading = false
        a.click();
        window.URL.revokeObjectURL(url);
        
      });
    })
    

  }

  navigateToScreenDetails() {
    this.router.navigate(['screens/screen-details', 1]);
  }

  navigateToScreenList() {
    this.router.navigate(['frequencyhit']);
  }
}
