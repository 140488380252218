import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-frequency-hit',
  templateUrl: './frequency-hit.component.html',
  styleUrls: ['./frequency-hit.component.css']
})
export class FrequencyHitComponent implements OnInit{

  constructor(private formBuilder: FormBuilder) {

  }

  @ViewChild('fileInput')
  fileInput!: ElementRef;
  showComponentScientist = true;
  showComponentAddScientist = false;
  frequencyhitlistFormGroup!: FormGroup;
  selectedFiles: any = [];

  addFrequencyHit() {
    this.showComponentScientist = false;
    this.showComponentAddScientist = true;
    
  }

  ngOnInit(): void {

    this.frequencyhitlistFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      hitlistFile: ['', Validators.required]
      
      
    })
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

  }

  // Function to handle drop event
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if(event.dataTransfer !== null){
      const files:any = event.dataTransfer.files;
    }
  }

  onFilesSelected(fileInput: HTMLInputElement) {
    this.selectedFiles = []
    const files: FileList | null = fileInput.files;
    const file = files![0];
    if (file) {
     // this.parseCSV(file);
    }
    this.selectedFiles.push({
      file: file
    })

    this.fileInput.nativeElement.value = '';
  }
}
