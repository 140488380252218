<div >
    <div class="row homepage">
      <mat-icon mat-icon-button (click) ="goBack()"class="mat-icon-home">west</mat-icon><span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Hit Lists | {{data.hitlist.hit_list_name}}</span>
    </div>
    
    <br><br>

 </div>
  <div>
     
      <div class="row message">
        <p><strong>{{data.hitlist.hit_list_name}} Details</strong></p>
        <span class="text">Check Hit analysis below</span>
       
      </div>
      <br><br>
           
       <div class="row add-scientist">
            <app-table-component [columns]="columns" [tableData]="data.detail" listName="hitlistDetail"  
            [showSearch]="false"      />
         
      </div> 
    </div>

    
      
    
  
  
