import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ModalServiceService } from '../../modal-service.service';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar'; 


export interface UserData {
  Name: string;
  ProjectLead: string;
  Target: string;
  Status: string;
  LastScreening: string
}
/** Constants used to fill up our data base. */
const NAMES: string[] = [
  'Project Name 01',
  'Project Name 02',
  'Project Name 03',
  'Project Name 04',
  'Project Name 05'
];
const PROGECTLEADS: string[] = [
  'Rose Rae',
  'Banu Cinar',
  'Isabel Fr',
  'Pablo Cruz',
  'Honorata Z',
  'Rose Raven',
  'Fredric C'
];
const TARGETS: string[] = [
  'Protein X',
  'Protein Y',
  'Protein Z'
  
];
const STATUS: string[] = [
  'On Going',
  'Archieved'
];

const LASTSCREENINGS: string[] = [
  '21 Dec 2022, 10:30',
  '27 Dec 2022, 12:30',
  '29 Dec 2022, 11:30',
  '21 Mar 2022, 08:30',
  '20 Apr 2022, 02:30',
  '21 Jan 2022, 05:30',
  '20 Aug 2022, 10:30',
  '18 Dec 2022, 12:30'
];

/**
 * @title Data table with sorting, pagination, and filtering.
 */



@Component({
  selector: 'app-profile',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css']
})


export class OldProjectComponent implements OnInit {

  displayedColumns: string[] = ['Name', 'ProjectLead', 'Target', 'Status', 'LastScreening'];
  dataSource: MatTableDataSource<UserData>;
  showComponentScientist = true;
  showComponentAddScientist = false;
  projectStatus!: string;
  role!: string;
  projectAssign!: string;
  projectName!: string;
  projectID!: string;
  comment!: string;
  projectLead!: string;
  projectTeam!: string[];
  targetProtein!: string[];
  library!: string[];
  showSuccessMessage: boolean = false;
  selectedOptions1:any = new FormControl([]);
  selectedOptions2:any = new FormControl([]);
  selectedOptions3:any = new FormControl([]);
  selectedOptions4:any = new FormControl([]);
  selectedOptions5:any = new FormControl([]);
  selectedOptions6:any = new FormControl([]);
  optionRole = ['Biologist', 'Data Scientist', 'Mechanical Engineer', 'ML Scientist'];
  projectStatusArr = ['On Going', 'Archieved'];
  projectLeadArr = ['Rose Rae', 'Banu Cinar', 'Isabel Fr', 'Pablo Cruz'];
  projectTeamArr = ['Rose Rae', 'Banu Cinar', 'Isabel Fr', 'Pablo Cruz'];
  optionProjectAssign = ['Project Name 01', 'Project Name 02', 'Project Name 03', 'Project Name 04'];
  targetProteinArr = ['Protein X', 'Protein Y', 'Protein Z'];
  libraryArr = ['Library 1', 'Library 2', 'Library 3', 'Library 4', 'Library 5'];
  

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private modalService: ModalServiceService,  private snackBar: MatSnackBar) {
    // Create 100 users
    const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(users);

  }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  openModal() {
    this.modalService.openModalScientist();
  }

  addProjectComponent(){
    alert('project created successfully!!');
     this.openSnackBar('New project successfully created !!');
    
     // Save form logic here
    // Show success message
    this.showSuccessMessage = true;
    

    // Hide success message after 3 seconds
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 3000);
  }

  navigateToAddScientistComponent() {
    this.showComponentScientist = false;
    this.showComponentAddScientist = true;
  }

  goBack() {
    this.showComponentScientist = true;
    this.showComponentAddScientist = false;
  }

  removeOption(option: string) {
    const selectedValues = this.selectedOptions2.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions2.setValue(selectedValues);
    }
  }

  removeOption1(option: string) {
    const selectedValues = this.selectedOptions4.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions4.setValue(selectedValues);
    }
  }

  removeOption2(option: string) {
    const selectedValues = this.selectedOptions5.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions5.setValue(selectedValues);
    }
  }

  removeOption3(option: string) {
    const selectedValues = this.selectedOptions6.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions6.setValue(selectedValues);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 1000,
      panelClass: 'custom-snackbar'
    });
    setTimeout(() => {
      this.goBack();
    }, 1000);

  }

}

/** Builds and returns a new User. */
function createNewUser(id: number): UserData {
  const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))];
  const projectlead = PROGECTLEADS[Math.round(Math.random() * (PROGECTLEADS.length - 1))];
  const target = TARGETS[Math.round(Math.random() * (TARGETS.length - 1))];
  const status = STATUS[Math.round(Math.random() * (STATUS.length - 1))];
  const lastscreening = LASTSCREENINGS[Math.round(Math.random() * (LASTSCREENINGS.length - 1))];

  return {
    Name: name,
    ProjectLead: projectlead,
    Target: target,
    Status: status,
    LastScreening: lastscreening
  };
}
