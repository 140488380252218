import { ScreenDetailsComponent } from './pages/screen-details/screen-details.component';
import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { RouterModule, Routes } from '@angular/router';
import { LibraryComponent } from './pages/library/library.component';
import { ScreenComponent } from './pages/screen/screen.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProjectComponent } from './pages/project/project.component';
import { ScientistComponent } from './pages/scientist/scientist.component';
import { HitlistComponent } from './pages/hitlist/hitlist.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { LoginComponent } from './pages/login/login.component';
import { AppComponent } from './app.component';
import { ProcessNewScreenComponent } from './pages/process-new-screen/process-new-screen.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { authGuard } from './auth.guard';
import { NoAdminComponent } from './pages/no-admin/no-admin.component';
import { HitlistDetailsComponent } from './pages/hitlist-details/hitlist-details.component';
import { ProcessNewLibraryComponent } from './pages/process-new-library/process-new-library.component';
import { LibraryDetailsComponent } from './pages/library-details/library-details.component';
import { FrequencyHitComponent } from './pages/frequency-hit/frequency-hit.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
    // SSO-sherif
    // redirectTo: 'home'
  },

  {
    path: 'login',
    component: AppComponent
  },

  {
    path: 'home',
    component: HomeComponent,
        // SSO-sherif
    canActivate: [MsalGuard]
  },
  {
    path: 'projects',
    component: ProjectComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'project/project-details',
    component: ProjectDetailsComponent,
    data: { breadcrumb: 'Project ' },
    canActivate: [MsalGuard]
  },
  {
    path: 'screens',
    component: ScreenComponent,
    data: { breadcrumb: 'Screens' },
    canActivate: [MsalGuard]
  },
  {
    path: 'hitlists',
    component: HitlistComponent,
    canActivate: [MsalGuard]
  }, 
  {
    path: 'hitlists/hitlist-details',
    component: HitlistDetailsComponent,
    data: { breadcrumb: 'Hitlist ' },
    canActivate: [MsalGuard]
  },
  {
    path: 'screens/screen-details/:id',
    component: ScreenDetailsComponent,
    data: { breadcrumb: 'Screen ' },
    canActivate: [MsalGuard]
  },
  {
    path: 'screens/chart-analysis/:id',
    component: BarChartComponent,
    data: { breadcrumb: 'MD2' },
    canActivate: [MsalGuard]
  },

  {
    path: 'screens/process-new-screen',
    component: ProcessNewScreenComponent,
    data: { breadcrumb: 'Screen ' },
    canActivate: [MsalGuard]
  },
  
  {
    path: 'libraries',
    component: LibraryComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'frequencyhit',
    component: FrequencyHitComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'libraries/library-details',
    component: LibraryDetailsComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'libraries/process-new-library',
    component: ProcessNewLibraryComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'libraries/chart-analysis/:id',
    component: BarChartComponent,
    data: { breadcrumb: 'MD2' },
    canActivate: [MsalGuard]
  },
  {
    path: 'scientists',
    component: ScientistComponent,
    canActivate: [MsalGuard, authGuard]
  },
  {
    path: 'contactsupport',
    component: NotFoundComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'help',
    component: NotFoundComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'no-admin',
    component: NoAdminComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [MsalGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
  {
    // initialNavigation:'enabled'
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
    !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
      ? 'enabledNonBlocking'
      : 'disabled', // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }