import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/data.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalServiceService } from 'src/app/modal-service.service';
import { ModalScreenComponent } from '../modal-screen/modal-screen.component';

@Component({
  selector: 'app-frequency-hit',
  templateUrl: './frequency-hit.component.html',
  styleUrls: ['./frequency-hit.component.css']
})
export class FrequencyHitComponent implements OnInit{

  constructor(
              private dialog: MatDialog,
              private modalService: ModalServiceService,
              private formBuilder: FormBuilder,
              private dataService: DataService,
              private snackBar: MatSnackBar,
              private router: Router,
  ) {

  }

  showFreqHitter = true
  screenList: any;
  screenListDataSource!: MatTableDataSource<any>;
  clickable = true;
  data?: any;
  screenListColunms: Array<Object> = [
    { key: 'freq_hit_name', displayName: 'Frequent Hitter Analysis Name'},
    { key: 'experiment_number', displayName: 'Exp ID' },
    { key: 'screen_name', displayName: 'Screen Name' },
    { key: 'screen_status', displayName: 'Status' },
    { key: 'submission', displayName: 'Submission' },
    { key: 'protein', displayName: 'Protein' },
    { key: 'scientist_name', displayName: 'Scientist' },
    { key: 'cwid', displayName: 'CWID' },
    { key: 'project', displayName: 'Project' },
    { key: 'alignment_algorithms', displayName: 'Aligment Algorithms' },
    { key: 'ngs_files', displayName: 'NGS Files'},
    { key: 'ldf_files', displayName: 'Library' },
    { key: 'error_margin', displayName: 'Error Margin' }
  ];

  // @ViewChild('fileInput')
  // fileInput!: ElementRef;
  // showComponentScientist = true;
  // showComponentAddScientist = false;
  // frequencyhitlistFormGroup!: FormGroup;
  // selectedFiles: any = [];
  // presignedUrlArr: Object[] = [];
  // showSuccessMessage: boolean = false;

  // addFrequencyHit() {
  //   this.showComponentScientist = false;
  //   this.showComponentAddScientist = true;
    
  // }

  ngOnInit(): void {

    this.getProcessedScreens();

    // this.frequencyhitlistFormGroup = this.formBuilder.group({
    //   name: ['', Validators.required],
    //   frequencyHitlistFile: ['', Validators.required]
      
      
    // })
  }

  getProcessedScreens() {
    this.dataService.getProcessedFreqHit().subscribe(res => {
      if (res) {
        this.screenList = [...res];
        this.screenListDataSource = new MatTableDataSource(this.screenList);
        
      }
    })
  }

  // screenResults(row: any, edit: boolean = true) {
  //   localStorage.setItem('screenDetails', JSON.stringify(row));
  //   this.router.navigate(['screens/screen-details', row.screen_name]);
  // }

  openModal() {
    this.router.navigate(['frequencyhit/process-new-freqhit'])
  }

  
  // onDragOver(event: DragEvent): void {
  //   event.preventDefault();
  //   event.stopPropagation();

  // }

  // // Function to handle drop event
  // onDrop(event: DragEvent) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   if(event.dataTransfer !== null){
  //     const files:any = event.dataTransfer.files;
  //   }
  // }

  // onFilesSelected(fileInput: HTMLInputElement) {
  //   this.selectedFiles = []
  //   const files: FileList | null = fileInput.files;
  //   const file = files![0];
  //   if (file) {
  //    // this.parseCSV(file);
  //   }
  //   this.selectedFiles.push({
  //     file: file
  //   })

  //   this.fileInput.nativeElement.value = '';
  // }
  
  // uploadFrequencyHit() {
  //   this.dataService.getPresignedUrlCsv(this.selectedFiles).subscribe({
  //     next: (response) => {
  //      const statusCode = response.statusCode;
  //       if (statusCode === 200) {
  //         response.body.map((obj: any) => this.presignedUrlArr.push(obj));
  //         this.uploadFileToS3();
  //       } else {
  //         console.log(`There is ${statusCode} error from the server side!!`);
  //       }
  //     },
  //     error: (error) => {
  //       console.error('Error:', error);
  //     }
  //   })
  // }

  // uploadFileToS3() {
    
  //   const presignedUrlArrEle: any = this.presignedUrlArr[0];
  //   this.dataService.uploadFileToS3FASTQ(presignedUrlArrEle['presigned_url'], 
  //     this.selectedFiles[0]).subscribe(res => {
  //     this.saveFreqHitFile()
  //     });
  // }

  // saveFreqHitFile() {
  //   const payload =       {
  //        "operation_type": "add",
  //        "table_name": "bay-delt-frequency-hit-files",
  //        "file": this.selectedFiles[0]['file'].name,
  //        "file_name": this.frequencyhitlistFormGroup.controls['name'].value
  //    }

  //   this.dataService.addFreqHitFiles(payload).subscribe(res => {
  //     this.openSnackBar('New HitList successfully created !!');
    
  //    // Save form logic here
  //   // Show success message
  //   this.showSuccessMessage = true;
    

  //   // Hide success message after 3 seconds
  //   setTimeout(() => {
  //     this.showSuccessMessage = false;
  //   }, 3000);
  //     //this.loadHitlist()
  //   })
  // }

  // openSnackBar(message: string) {
  //   this.snackBar.open(message, 'Close', {
  //     duration: 1000,
  //     panelClass: 'custom-snackbar'
  //   });
  //   setTimeout(() => {
  //     //this.goBack();
  //   }, 1000);

  // }

  processNewScreen() {
    this.router.navigate(['frequencyhit/upload-new-freqhit'])
  }

  screenResults(row: any, edit: boolean = true) {
    localStorage.setItem('screenDetails', JSON.stringify(row));
    this.router.navigate(['frequencyhit/frequencyhit-details', row.freq_hit_name]);
  }
}
