import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ModalServiceService } from '../../modal-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalScreenComponent } from '../modal-screen/modal-screen.component';
import { FormControl } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import * as XLSX from 'xlsx';
import { CommonService } from 'src/app/common.service';
import { FILE_EXTENSION } from 'src/app/enums/File-extension.enum';
import { MatButtonToggle } from '@angular/material/button-toggle';
// import * as ExcelJS from 'exceljs';
import {
  GridApi, GridReadyEvent,
} from 'ag-grid-community'
import 'ag-grid-enterprise';
import { DataService } from 'src/app/data.service';




export interface ScreenListData {
  name: string;
  id: string;
  status: string;
  submission: string;
  protein: string;
  scientist: string;
  project: string;
}



/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
  selector: 'app-about',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.css']
})
export class ScreenComponent implements OnInit, AfterViewInit {
  private gridApi!: GridApi<any>;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  @ViewChild('myModal') myModal!: ElementRef;

  @ViewChild('toggleButton1') toggleButton1!: MatButtonToggle;
  @ViewChild('toggleButton2') toggleButton2!: MatButtonToggle;
  @ViewChild('toggleButton3') toggleButton3!: MatButtonToggle;
  @ViewChild('toggleButton4') toggleButton4!: MatButtonToggle;



  screenListColunms: Array<Object> = [
    { key: 'experiment_number', displayName: 'Exp ID' },
    { key: 'screen_name', displayName: 'Name' },
    { key: 'screen_status', displayName: 'Status' },
    { key: 'submission', displayName: 'Submission' },
    { key: 'protein', displayName: 'Protein' },
    { key: 'scientist_name', displayName: 'Scientist' },
    { key: 'cwid', displayName: 'CWID' },
    { key: 'project_name', displayName: 'Project' },
    { key: 'alignment_algorithms', displayName: 'Aligment Algorithms' },
    { key: 'ngs_files', displayName: 'NGS Files'},
    { key: 'ldf_files', displayName: 'Library' },
    { key: 'error_margin', displayName: 'Error Margin' },
    { key: 'description', displayName: 'Description' },
  ];

  showCheckbox = true;
  clickable = true;
  screenList: any;


  displayedColumns: string[] = ['Name', 'ID', 'Status', 'Submission', 'Protein', 'Scientist', 'Project'];
  screenListDataSource!: MatTableDataSource<any>;
  screenTab = true;
  isTabComplete = true;

  personalFormGroup!: FormGroup;
  ngsFormGroup!: FormGroup;
  experimentalFormGroup!: FormGroup;
  processingFormGroup!: FormGroup;

  addressFormGroup?: any;
  tab1Selected = true;
  tab2Selected = false;
  tab3Selected = false;
  tab4Selected = false;


  uploadedFileSecondTab: boolean = false;
  uploadedFileThirdTab: boolean = false;
  selectedTab = 'tab1'; // Initial selected tab

  modalFormData?: any;
  data?: any;

  name!: string;
  role!: string;
  projectAssign!: string;
  screener!: string;
  experimentNumber!: string;
  alignmentAlgorithm!: string;
  efc!: string;
  errorMargin!: string;
  description!: string;
  projectLead!: string;
  projectTeam!: string[];
  targetProtein!: string[];
  // library!: string[];
  showSuccessMessage: boolean = false;

  libraries: any = new FormControl([]);


  libraryUpload!: string;
  selectedFile!: any;
  isFileUploaded = false;
  uploadedFile: File | null = null;
  fileControl = new FormControl();
  uploadedFiles: File[] = [];

  xlData: any = [];

  //xlData: any[] = new MatTableDataSource<ExcelData>();
  xlDisplayedColumns: string[] = ['Condition Name', 'Description', 'Field A', 'Field B', 'Field C', 'Field D', 'Field E', 'Field F', 'Field G', 'NGS Read-1', 'NGS Read-2'];

  showScreenList: boolean = true;

  showInputError: boolean = false;

  admin = false;

  filterProtein: string[] = []
  filterScientist: string[] = []
  filterStatus: string[] = []
  filterLibraries: string[] = []
  filterExperimental = []
  filterProjectName: string[] = [ ]
  filterSubmissionDate = []
  criteria: any[] = []


  @ViewChild(MatSort) sort!: MatSort;

  constructor(private dialog: MatDialog,
    private modalService: ModalServiceService,
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService
  ) {
    // Create 100 screenList
    // this.screenList = Array.from({ length: 100 }, (_, k) => createNewUser(k + 1));
    this.commonService.adminRole$.subscribe(res=> {
      this.admin =res
    })

  }


  ngOnInit(): void {
    this.getProcessedScreens();

    this.personalFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      screener: [''],
      libraries: [[], Validators.required],
      experiment: ['', Validators.required],
      protein: [[], Validators.required],
    });

    this.ngsFormGroup = this.formBuilder.group({
      ngsFileInput: [null, Validators.required],
    });

    this.experimentalFormGroup = this.formBuilder.group({
      experimentalFileInput: [null, Validators.required],
    })

    this.processingFormGroup = this.formBuilder.group({
      algorithm: [[], Validators.required],
      enrichmentFactor: [[], Validators.required],
      errorMargins: [[], Validators.required],
    })

    this.dataService.getProjectName().subscribe(res=> {
      this.criteria.push({ key: 'project_name', display_name: 'Project Name', searchText: '', value: res.map((item: any) => item.project_name)})
     });

     this.prepareFilters()
  }

  ngAfterViewInit() {
    // check this code because it gives error in console  ie   main.5051416e4f68a802.js:1 ERROR TypeError: Cannot set properties of undefined (setting 'sort')
    this.screenListDataSource!.sort = this.sort;
  }

  ngOnChanges() {
    this.screenTab = true;
  }

  prepareFilters() {

    this.dataService.getScientists().subscribe(res => {
      if (res) {
        this.criteria.push({ key: 'scientist_name', display_name: 'Scientist', searchText: '', value: res.map((item: any) => item.scientist_name)})
      }
    })

    this.dataService.getLibrariesData().subscribe(res => {
      if (res) {
        this.criteria.push({ key: 'ldf_files', display_name: 'Libraries', searchText: '', value: res.map((item: any) => item.ldf_files)})
      }
    })

    this.criteria.push({ key: 'submission', type: 'date', display_name: 'Submission Date'})

  }

  getProcessedScreens() {
    this.dataService.getProcessedScreens().subscribe(res => {
      if (res) {
        this.screenList = [...res];
        this.screenListDataSource = new MatTableDataSource(this.screenList);
        this.criteria.push({ key: 'experiment_number', display_name: 'Exp ID', searchText: '', value: res.map((item: any) => item.experiment_number)})
    
      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.screenListDataSource.filter = filterValue.trim().toLowerCase();

    if (this.screenListDataSource.paginator) {
      this.screenListDataSource.paginator.firstPage();
    }
  }

  submitScreen() {
    // this.openSubmitModal();
    // alert('Screen Submitted succussfuly');
    this.reloadComponent();

  }

  openSubmitModal() {
    // Access the modal using the ViewChild reference
    this.myModal.nativeElement.style.display = 'block';
  }

  afterCloseSubmitModal() {
    // Access the modal using the ViewChild reference
    this.myModal.nativeElement.style.display = 'none';
    this.reloadComponent();
  }


  openModal() {
    //this.modalService.openModalScreen();
    this.openModalScreen();
  }

  submitForm() {
    // Perform form submission logic here
  }

  goBack() {
    this.screenTab = false;
  }

  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }


  openModalScreen() {
    const dialogRef = this.dialog.open(ModalScreenComponent, {
      // Add any additional configuration options for the modal
      width: '400px',
      // ... other options
    });

    // Subscribe to the afterClosed observable to get the result when the modal is closed
    dialogRef.afterClosed().subscribe(formValues => {
      if (formValues) {
        // Form values are available here
        this.data = formValues;
        this.router.navigate(['screens/process-new-screen'], { state: { data: this.data } });
        // this.screenTab = false;
      } else {
        // Modal closed without submitting the form
        console.log('Modal closed without form submission');
      }
    });
  }


  screenResults(row: any, edit: boolean = true) {
    localStorage.setItem('screenDetails', JSON.stringify(row));
    this.router.navigate(['screens/screen-details', row.screen_name]);
  }

  editRow(row: any) {
    let result = {
      projectName: this.data?.projectName
    }

    this.router.navigate(['screens/process-new-screen'], { state: { data: row } });
  }

  duplicateRow(row: any) {
    row.duplicateRow = true
    this.router.navigate(['screens/process-new-screen'], { state: { data: row } });
  }

  reanalyzeScreen(row: any) {
    const screenName = row.screen_name + '_reanalysis';
    const protein = row.protein;
    const scientistName = row.scientist_name;
    const cwid = row.cwid;
    const exprimentalNumber = row.experiment_number;
    const description = row.description;
    const ldfFilesStr = row.ldf_files;
    const errorMargin = row.error_margin;
    const ngs_files = row.ngs_files;
    const exp_file = row.exp_file;
    const project = row.project;

    const data = {
      table_name: "bay-delt-screens",
      operation_type: "add",
      screen_name: screenName,
      protein: protein,
      scientist_name: scientistName,
      cwid: cwid,
      project: project,
      experiment_number: exprimentalNumber,
      screen_status: "Initiated",
      info: description,
      ldf_files: ldfFilesStr,
      ngs_files: ngs_files,
      exp_file: exp_file,
      alignment_algorithms: row.alignment_algorithms,
      error_margin: errorMargin,
      single_read: row.single_read,
      project_id: row.project_id,
      is_whole_sequence_exact_match: row.is_whole_sequence_exact_match

    }

    this.dataService.addScreen(data).subscribe(res => {
      if (res) {
        this.getProcessedScreens();
      }
    })
    this.screenList = [...this.screenList]
  }


  deleteScreen(rowData?: any) {
    const indexFound = this.screenList.findIndex((screen: any) => screen.screen_name == rowData.screen_name)
    this.dataService.deleteScreen(rowData.screen_name).subscribe(res => {
      if (res && indexFound != -1) {
        this.screenList.splice(indexFound, 1);
        this.screenList = [...this.screenList]
      }
    })
  }

  onShowDescription(value: boolean): void {
    this.modalService.updateMyVariable(value);
  }

  refreshScreen() {
    // alert("this screen table is going to refresh...");
    this.getProcessedScreens();
    this.router.navigate([this.router.url]);
  }

  onApplyFilter(filterItem: any) {
    this.filterProjectName = filterItem[3].value  
    this.filterScientist = filterItem[5].value
    this.filterLibraries = filterItem[6].value
    this.filterExperimental = filterItem[7].value
    this.filterSubmissionDate = filterItem[8].value
    
      
    }
}


