<div class="full-width">
<div class="row homepage search-container" *ngIf="showSearch">
  <!-- <div class="scientist-selected"  [ngStyle]="listName == 'Screens ...'? {display: 'none'} : showCheckbox ? {display: 'flex'}: {visibility: 'hidden'}"> -->
  <!-- // NOTE: make visibility hidden to remain the styles //TODO: fix the styles in the future -->
    <div class="scientist-selected"  style="visibility: hidden">
    {{ this.selection.selected.length + ' ' + listName }} are selected
  </div>

  <!-- <div class="scientist-selected" *ngIf="listName == 'Screens ...'">
    <span class="chart-highlight">
      <mat-icon class="align-middle check-icon">check</mat-icon>
      <span class="align-middle">
          126 of 126 item has succesfully completed
      </span>

      <div class="cicle-check-icon">
          <span class="align-middle "> 126 </span>
          <mat-icon class="align-middle ">check_circle_outline</mat-icon>
      </div>
  </span>  
</div> -->
<div class="filter-container">
  <button (click)="resetFilter()"  *ngIf="filters.length" mat-flat-button color="primary" class="btn-add-library" style="margin-right: 1em;"><mat-icon style="margin: 0;">cached</mat-icon></button>
  <button (click)="showFilterUI()"  *ngIf="filters.length" mat-flat-button color="primary" class="btn-add-library" style="margin-right: 1em;"><mat-icon>filter_alt</mat-icon>Filter</button>
 <div class="filter-list" *ngIf="showFilter" style="z-index: 100;position: absolute; max-height: 360px; max-width: 317px;    
       box-shadow: 0px 4px 4px 0px rgba(108, 117, 125, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
       margin-top: 1em;">
   
    <mat-accordion>
      <mat-expansion-panel  *ngFor="let item of filters" style="margin: 0;background: #FAFAFA;">
        <mat-expansion-panel-header style="padding: 0 16px;">
          <mat-panel-title> {{item.display_name}}</mat-panel-title>
        </mat-expansion-panel-header>
        <input matInput *ngIf="item.value?.length > 5" (keyup)="searchFilter($event)" [(ngModel)]="item.searchText" placeholder={{item.display_name}} #search style="height:2em;width: 100%;
        margin-left: -8px; margin-bottom: 6px; padding-inline-start: 6px;" />
        <ng-container *ngIf="!item.type">
          <li *ngFor="let val of item.value | search: item.searchText" style="list-style: none; margin-left: -16px;">
            <mat-checkbox  (change)="onCheck(item.key, val, $event)" [checked]="reset ? isNotChecked(item.key, val) : isChecked(item.key, val)">
              {{val}}
            </mat-checkbox>
          </li>
        </ng-container>
        <div class="row" style="display: flex" *ngIf="item.type">
          <div class="col">
            <mat-label>From</mat-label>
            <input type="date" id="from" name="from" [(ngModel)]="fromDate" (change)="setFrom($event, item.key)">
          </div>
          <div class="col">
            <mat-label>To</mat-label>
            <input type="date" id="to" name="to" [(ngModel)]="toDate"(change)="setTo($event, item.key)">
          </div>
        </div> 
        
      </mat-expansion-panel>
     
    </mat-accordion>
  </div>
</div>
<button (click)="showSelectedRows()" *ngIf="export" mat-flat-button color="primary" class="btn-add-library" style="margin-right: 1em;"><mat-icon>add</mat-icon>Export</button>

  <mat-form-field class="right" *ngIf="listName && listName !== 'Screens ...'">
    <mat-label>Search </mat-label>
    <input matInput (keyup)="filterUsers($event)" placeholder="Ex. Paul or 4352" #input />
  </mat-form-field>
</div>

<div *ngIf="dataSource && columns !== 'screenResultColunms'" class="full-width">
  <table mat-table [dataSource]="dataSource" matSort>
     <!-- Conditional rendering of a column -->
  <!-- <ng-container matColumnDef="conditionalColumn" *ngIf="showConditionalColumn">
    <th mat-header-cell *matHeaderCellDef> Conditional Column </th>
    <td mat-cell *matCellDef="let element"> {{ element.conditionalColumn }} </td>
  </ng-container> -->
    <!-- Column definitions -->
    <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.key">
      <ng-container  *ngIf="i == 0 ">
      <div *ngIf="listName === 'Screens ...'">
        <th mat-header-cell *matHeaderCellDef>
          <!-- <mat-checkbox  *ngIf="showCheckbox" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox> -->

          {{ column.displayName }}
        </th>
      </div>
      <div *ngIf="listName !== 'Screens ...'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortAllData($event)">
          <mat-checkbox  *ngIf="showCheckbox" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>

          {{ column.displayName }}
        </th>
      </div>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox *ngIf="showCheckbox" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
          <!-- <span class="scientist-name" (click)="onShowDetails(row)" *ngIf="(clickable && !row.statusIcon)  ||  (clickable && row.statusIcon && row.statusIcon === 'Successful')"> {{ row[column.key] }} </span> -->
          <span class="scientist-name" (click)="onShowDetails(row)" *ngIf="clickable"> {{ row[column.key] }} </span>
          <!-- <span style="color: #9BA2AA;"  *ngIf="row.statusIcon"> {{ row[column.key] }} </span> -->
          <span *ngIf="!clickable"> {{ row[column.key] }} </span>


        </td>
      </ng-container>

    <ng-container *ngIf="i > 0 || !showCheckbox">
      <div *ngIf="listName === 'Screens ...'">
        <th mat-header-cell *matHeaderCellDef>
          {{ column.displayName }}
        </th>
      </div>
      <div *ngIf="listName !== 'Screens ...'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortAllData($event)">
          {{ column.displayName }}
        </th>
      </div>
      <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ column.displayName }}
      </th> -->
      <td mat-cell *matCellDef="let row" style="word-wrap: break-word; max-width: 280px;">
       <div style="display: flex; align-items: center;justify-content: flex-end;flex-direction: row-reverse;">
        <span class="error-link" *ngIf="column.key == 'screen_status' && row[column.key]?.status?.S">
          <a [href]="row[column.key]?.errorlink?.S" target="_blank" rel="external"><mat-icon class="arrow_outward" style="font-size: 1.3em;
          color: #2196F3;
          margin-top: .5em;">arrow_outward</mat-icon></a>
         </span>
        <span *ngIf=" column.key != 'statusIcon'">  

        <span *ngIf="column.key == 'description'" style=" color: #2196F3; cursor: pointer;"
         (click)="onShowDescription(row)"> 
          <!-- {{ row[column.key] }}  -->
          View
          <img src="assets/images/arrow-up-right.svg"/>
       </span> 
       <span *ngIf=" row.statusIcon && row.statusIcon !== 'Successful' && column.key !=='description'" style=" color: #9BA2AA;"> 
         {{ row[column.key] }} 

      </span> 
      
      <span class="tooltip" [matTooltip]="row[column.key]?.length > 32 ? row[column.key] : ''" *ngIf="column.key !== 'screen_status' && column.key != 'description' && column.key !='screen_list' && (!row.statusIcon || (row.statusIcon && row.statusIcon === 'Successful' && column.key !=='description'))">
        {{ row[column.key] }}
     </span> 

    
     <span class="tooltip" [matTooltip]="row[column.key]?.message?.S" *ngIf="column.key == 'screen_status' && row[column.key]?.status?.S" >
      {{ row[column.key]?.status?.S }}
     </span> 

   <span class="tooltip"  *ngIf="column.key == 'screen_status' && !row[column.key]?.status?.S" >
    {{ row[column.key] }}
 </span>
      
      </span>


       <span *ngIf="column.key == 'screen_status' || column.key == 'library_status'"> 
      <!-- // TODO:SHERIF MAKE_IT MORE DYNAMIC -->
        <!-- <mat-spinner diameter="20" *ngIf="row[column.key] == 'LOADING'"></mat-spinner> 
        <mat-icon [ngStyle]="{'color':'#62B966'}" [ngClass]="{'success-icon': row[column.key] === 'Successful'}" *ngIf="row[column.key] == 'Successful'">check</mat-icon>
        <mat-icon [ngStyle]="{'color':'#2196F3',  'paddingLeft': '10px'}" [ngClass]="{'failed-icon': row[column.key] === 'Failed'}" *ngIf="row[column.key] == 'Failed'">info</mat-icon>
        <mat-icon [ngStyle]="{'color':'#FF4032'}" [ngClass]="{'failed-icon': row[column.key] === 'FAILED'}" *ngIf="row[column.key] == 'FAILED'">replay</mat-icon> -->

        <!-- <mat-icon  [ngClass]="{'success-icon': row[column.key] === 'Successful'}" *ngIf="row[column.key] == 'Successful'">check</mat-icon>
        <mat-icon  [ngClass]="{'failed-icon': row[column.key] === 'Failed'}" *ngIf="row[column.key] == 'Failed'">info</mat-icon>
        <mat-icon  [ngClass]="{'inprogress-icon': row[column.key] !== 'Successful' && row[column.key] !== 'Failed' }" *ngIf="row[column.key] !== 'Successful' && row[column.key] !== 'Failed'">reply</mat-icon> -->
        
        <div class="icon-container1" *ngIf="row[column.key] === 'Successful'">
          <!-- <span class="icon-text">{{row[column.key]}}</span> -->
          <mat-icon class="mat-icon-screen1">check_circle_outline</mat-icon>
        </div>
        
        <div class="icon-container2" *ngIf="row[column.key] === 'Failed'">
          <!-- <span class="icon-text">{{row[column.key]}}</span> -->
          <mat-icon class="mat-icon-screen2">report_problem</mat-icon>
        </div>
        
        <div class="icon-container3" *ngIf="row[column.key] !== 'Successful' && row[column.key] !== 'Failed' && row[column.key] !== 'No NGS provided'">
          <!-- <span class="icon-text">{{row[column.key]}}</span> -->
          <mat-icon class="mat-icon-screen3">schedule</mat-icon>
        </div>
        

      </span>
      </div>
     
      <span *ngIf="column.key == 'screen_list'" >
        <span class="scientist-name" *ngFor="let screen of row[column.key]"> 
          <span (click)="onShowScreen(screen)">{{ screen }},</span></span>
      </span>
      <span *ngIf="column.key == 'screen'">
        <span class="scientist-name" > {{ row[column.key] }} </span>
      </span>
      <!-- <span *ngIf=" column.key == 'project_status'"> 
       
          <div class="icon-container1" *ngIf="row[column.key] === 'Successful'">
           
            <mat-icon class="mat-icon-screen1">check_circle_outline</mat-icon>
          </div>
          
          <div class="icon-container2" *ngIf="row[column.key] === 'Failed'">
           
            <mat-icon class="mat-icon-screen2">report_problem</mat-icon>
          </div>
          
          <div class="icon-container3" *ngIf="row[column.key] !== 'Successful' && row[column.key] !== 'Failed'">
           
            <mat-icon class="mat-icon-screen3">schedule</mat-icon>
          </div>
          
  
        </span> -->
        <!-- <span *ngIf="column.key == 'screen_list'">
          <span class="scientist-name" > {{ row[column.key] }} </span>
        </span> -->
      </td>
    </ng-container>
  </ng-container>


        <!-- Kebab Menu Column -->
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef>
       
      </th>
      <td mat-cell *matCellDef="let row ; let i = index">
        <!-- Add your menu button here -->
        <!-- For example, let's use a MatMenu with a MatMenuItem for demonstration purposes -->
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onEditRow(row, i)" *ngIf="showEditRow">Edit</button>
          <button *ngIf="showDuplicateOption" mat-menu-item (click)="onDuplicateRow(row,  i)">Duplicate</button>
          <button *ngIf=" row.screen_status == 'Failed'" mat-menu-item (click)="onReanalyze(row,  i)">Reanalyse</button>
          
          <button *ngIf="removeDelete" mat-menu-item (click)="openDialog(row)">Delete</button>
          <!-- Add more menu items as needed -->
        </mat-menu>
      </td>
    </ng-container>

    <!-- Table rows -->
    <tr mat-header-row *matHeaderRowDef="getColumnKeys(false)"></tr>
    <tr  mat-row *matRowDef="let row; columns: showActionMenu ? getColumnKeys(true) : getColumnKeys(false)"></tr>

  </table>
</div>

<div class="spacing">

</div>

<!-- TODO: Sherif fix pagination -->
<mat-paginator  [length]="totalItems" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"
 aria-label="Select page of tableData"></mat-paginator>

</div>


