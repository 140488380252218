<button mat-button (click)="toggleSlideBar()">Open Slide Bar</button>

<mat-sidenav-container class="slide-container" autosize>
  <mat-sidenav #sidenav mode="over" opened="false" position="end">
    <!-- Content of the slide bar goes here -->
    <p>This is the slide bar content.</p>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Main content of the application goes here -->
    <p>Main content goes here.</p>
  </mat-sidenav-content>
</mat-sidenav-container>
