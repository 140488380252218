import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { ModalServiceService } from '../../modal-service.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
//import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';


export interface UserData {
  HitList: string;
  Library: string;
  ImportDate: string;
  Scientist: string;
  HitListDescription: string;
  ExpID: string;
  ProjectName: string;
  ProjectID: string;
  AnalyzedBy: string;
  HitListLength: string;

}

export interface ProjectNameType {
  
  project_id: string,
  project_name: string,
  unique_project_id: string
}

export interface ScreenType {
  screen_name: string,
  is_whole_sequence_exact_match: string,
  ldf_files: string,
  alignment_algorithms: string,
  project: string,
  scientist_name: string,
  cwid: string,
  creation_date: string,
  exp_file: string,
  ngs_files: string,
  protein: string,
  single_read: string,
  error_margin: string,
  experiment_number: string,
  submission: string,
  screen_status: string,
  project_id: string,
  description: string,
  updated_date: string

}

/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
  selector: 'app-hitlist',
  templateUrl: './hitlist.component.html',
  styleUrls: ['./hitlist.component.css']
})
export class HitlistComponent {

  @ViewChild('fileInput')
  fileInput!: ElementRef;
  hitlistFormGroup!: FormGroup;
  displayedColumns: string[] = ['HitList', 'Library', 'ImportDate', 'Scientist', 'HitListDescription', 'ExpID', 'Project', 'AnalyzedBy', 'HitListLength'];
  showComponentScientist = true;
  showComponentAddScientist = false;
  projectStatus!: string;
  role!: string;
  projectAssign!: string;
  projectName!: string;
  projectID!: string;
  comment!: string;
  projectLead!: string;
  targetProtein!: string[];
  library!: string[];
  showSuccessMessage: boolean = false;
  selectedOptions1:any = new FormControl([]);
  selectedOptions2:any = new FormControl([]);
  selectedOptions3:any = new FormControl([]);
  selectedOptions4:any = new FormControl([]);
  selectedOptions5:any = new FormControl([]);
  selectedOptions6:any = new FormControl([]);
  optionRole = ['Biologist', 'Data Scientist', 'Mechanical Engineer', 'ML Scientist'];
  projectStatusArr = ['On Going', 'Archieved'];
  projectLeadArr = ['Rose Rae', 'Banu Cinar', 'Isabel Fr', 'Pablo Cruz'];
  screensArr: ScreenType[] = [];
  optionProjectAssign = ['Project Name 01', 'Project Name 02', 'Project Name 03', 'Project Name 04'];
  targetProteinArr = ['Protein X', 'Protein Y', 'Protein Z'];
  libraryArr = ['Library 1', 'Library 2', 'Library 3', 'Library 4', 'Library 5'];

  hitListColunms: Array<Object> = [
    { key: 'hit_list_name', displayName: 'Hit lists' },
    { key: 'import_date', displayName: 'Import date' },
    { key: 'scientist_name', displayName: 'Scientist' },
    { key: 'hit_list_description', displayName: 'Hit list Descriptions' },
    { key: 'experimental_id', displayName: 'Exp ID' },
    { key: 'project_name', displayName: 'Project' },
    { key: 'unique_project_id', displayName: 'Project ID' },
    { key: 'analyzedby', displayName: 'Analyzed by' },
    { key: 'hit_list_lenght', displayName: 'Hit list Length' },
    { key: 'screen_list', displayName: 'Screens'}

    
  ];

  hitList: any;
  count = 0;
  projectNameArr: ProjectNameType[] = []
  analysedArr = []
  scientistList = []
  selectedFiles: any = [];
  presignedUrlArr: Object[] = []; 
  hitlistPath = ''
  selectedProjectName = '';
  selectedProjectID = '';
  rowCount = 0
  //invalidRows = []
  invalidRows: string[] = [];
  exportdata: any

  filterAnalyzed: string[] = []
  filterScientist: string[] = []
  filterProjectName: string[] = []
  filterImportDate = []
  criteria: any[] = []

  fileName: string[] = []

  

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private modalService: ModalServiceService,  
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private dataService: DataService,
              private router: Router) {  }

  ngOnInit(): void {

    this.hitlistFormGroup = this.formBuilder.group({
      hitlistName: ['', Validators.required],
      hitlistFile: ['', Validators.required],
      projectName: ['', Validators.required],
      projectID: ['', Validators.required],
      hitlistLength: ['', Validators.required],
      screens: ['', Validators.required],
      analysedBy: ['', Validators.required],
      scientist: ['', Validators.required],
      comment: [''],
      uniqueProjectID: ['', Validators.required]
      
    })

    this.loadHitlist()
    this.dataService.getProjectName().subscribe(res=> {
      this.projectNameArr = res
      this.criteria.push({ key: 'project_name', display_name: 'Project Name', searchText: '', value: res.map((item: any) => item.project_name)})
    });

    this.dataService.getAnalysedBy().subscribe(res=> {
      this.analysedArr = res
      this.criteria.push({ key: 'analyzedby', display_name: 'Analyzed By', searchText: '', value: res })
    });

    this.dataService.getScientistList().subscribe(res=> {
      this.scientistList = res
      this.criteria.push({ key: 'scientist_name', display_name: 'Scientist', searchText: '', value: res})
      this.criteria.push({ key: 'submission', type: 'date', display_name: 'Import Date'})
    });


  }

  loadHitlist() {
    this.dataService.getHitLists().subscribe(res=> {
      this.hitList = res.body.response
      this.count = res.body.count
    })
  }

  onShowScreen(screen_name: string) {
    this.dataService.getScreen(screen_name).subscribe(res=> {
      localStorage.setItem('screenDetails', JSON.stringify(res[0]));
      this.router.navigate(['screens/screen-details', res[0].screen_name]);
    })
    
  }

  export(idArray: any[]) {
    const requests =idArray.map(item => this.dataService.getHitlistDetails(
      {"operation_type": "data",
      "hitlist_id": item.id,
      "table_name": "bay-delt-hitlists",
      "context": "hitlist"
    }
    ));
    forkJoin(requests).subscribe(
      results => {
        this.exportdata = results;
        this.generateZip(results,idArray);
        //this.exportCsvFiles(results,idArray)
      },
      error => {
        console.error('Error occurred while fetching data', error);
      });
  }

  generateZip(dataArray: any[], idArray: any[]) {
    const zip = new JSZip();

    dataArray.forEach((data, index) => {
      const dt = data.body;
      const csvData = this.generateCSV(dt);
      zip.file( idArray[index].name + '.csv', csvData);
    });

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'hitlist.zip');
    });
  }

  // generateCSV(data: any): string {
  //   const headers = Object.keys(data);
  //   const csvRows = [];

  //   // Add headers
  //   csvRows.push(headers.join(';'));

  //   // Add data row
  //   const values = headers.map(header => {
  //     const escaped = ('' + data[header]).replace(/"/g, '\\"');
  //     return `"${escaped}"`;
  //   });
  //   csvRows.push(values.join(';'));

  //   return csvRows.join('\n');
  // }

  generateCSV(data: any): string {
    
    if (!data || data.length === 0) {
      return '';
    }
  
    const csvRows = [];
    const headers = Object.keys(data[0]);
  
    // Add headers
    csvRows.push(headers.join(','));
  
    // Add data rows
    data.forEach((item: any) => {
      const values = headers.map(header => {
        let value = item[header];
        if (typeof value === 'string') {
          // Escape double quotes
          value = value.replace(/"/g, '""');
          // Enclose in double quotes if contains commas or newlines
          if (value.includes(',') || value.includes('\n')) {
            value = `"${value}"`;
          }
        }
        return value;
      });
      csvRows.push(values.join(','));
    });
  
    return csvRows.join('\n');

    
  }

  exportCsvFiles(dataArray: any[], idArray: any[]) {
    dataArray.forEach((data, index) => {
      const id = idArray[index]; // Get the corresponding ID
      const dt = data.body;
      const csvData = this.generateCSV(dt);
      const blob = new Blob([csvData], { type: 'text/csv' });
      saveAs(blob, `data_${id}.csv`);
    });
  }

  getScreens(projectId: string) {
    this.dataService.getScreenList(projectId).subscribe(res => {
      this.screensArr = res
    })

    const selectedProject = this.projectNameArr.find(el=> {
      return el.project_id === projectId
    })

    if(selectedProject) {
      this.selectedProjectID = selectedProject?.unique_project_id
      this.selectedProjectName = selectedProject.project_name
      this.hitlistFormGroup?.get('projectID')?.setValue(this.selectedProjectID);
      this.hitlistFormGroup?.get('projectName')?.setValue(this.selectedProjectName);
    }


  }

  delete(row: any) {
    this.dataService.deleteHitlist(row.hitlist_id).subscribe(res=> {
      this.loadHitlist()
    })
  }

  rowDetails(row: any) {
    const payload = {
      "operation_type": "data",
      "hitlist_id": row.hitlist_id,
      "table_name": "bay-delt-hitlists",
      "context": 'hitlist'
    }

    this.dataService.getHitlistDetails(payload).subscribe(res=> {
      const data = {
        hitlist: row,
        detail: res.body
      }
      this.router.navigate(['hitlists/hitlist-details'], { state: { data: data } });    
    })

  }


  ngAfterViewInit() {
  }

  applyFilter(event: Event) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
  
  openModal() {
    this.modalService.openModalScientist();
  }

  addHitlist(){

    this.dataService.getPresignedUrlCsv(this.selectedFiles).subscribe({
      next: (response) => {

        const statusCode = response.statusCode;
        if (statusCode === 200) {
          response.body.map((obj: any) => this.presignedUrlArr.push(obj));
          this.uploadFileToS3();
          this.hitlistPath =  response?.body[0]?.file_path
        } else {
        }

      },
      error: (error) => {
        console.error('Error:', error);
      }
    })
    
  }

  addNewAnalysis() {
    this.showComponentScientist = false;
    this.showComponentAddScientist = true;
  }

  goBack() {
    this.showComponentScientist = true;
    this.showComponentAddScientist = false;
  }

 

  removeOption1(option: ScreenType) {
    const selectedValues = this.selectedOptions4.value as ScreenType[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions4.setValue(selectedValues);
    }
  }



  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 1000,
      panelClass: 'custom-snackbar'
    });
    setTimeout(() => {
      this.goBack();
    }, 1000);

  }

  onDragOver(event: DragEvent): void {
    // event.preventDefault();
    // event.stopPropagation();
    event.preventDefault();
    event.stopPropagation();
    // event.dataTransfer.dropEffect = 'copy';
    // Add a CSS class to highlight the drop zone when a file is dragged over it
    // event.target.classList.add('drag-over');

  }

  // Function to handle drop event
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // event.target.classList.remove('drag-over');
    if(event.dataTransfer !== null){
      const files:any = event.dataTransfer.files;
      // this.onFilesSelectedDragnDrop(files);
    }
    // this.onFilesSelected(fileInput);
  }

  onFilesSelected(fileInput: HTMLInputElement) {
    this.selectedFiles = []
    const files: FileList | null = fileInput.files;
    const file = files![0];
    if (file) {
      this.parseCSV(file);
    }
    this.selectedFiles.push({
      file: file
    })

    this.fileInput.nativeElement.value = '';
  }

  parseCSV(file: File): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const csvData = event.target.result;
      this.rowCount = this.countRows(csvData);
      this.hitlistFormGroup?.get('hitlistLength')?.setValue(this.rowCount);
      this.validateCSV(csvData);
     
    };
    reader.readAsText(file);
  }

  countRows(csvData: string): number {
    // Split the CSV data into rows
    const rows = csvData.split('\n').filter(row => row.trim().length > 0);
    // Return the number of rows, excluding the header row
    return rows.length - 1;
  }




  validateCSV(csvData: string): void {
    const rows = csvData.split('\n').filter(row => row.trim().length > 0);

    if (rows.length > 0) {
      const headers = rows[0].split(';').map(header => header.trim());
           
      const numberCols = ['Copy Number Condition 1','Enrichment Factor Condition 1','Copy Number Condition 2','Enrichment Factor Condition 2','Copy Number Condition 3','Enrichment Factor Condition 3','Copy Number Condition 4','Enrichment Factor Condition 4','Copy Number Condition 5','Enrichment Factor Condition 5','Copy Number Condition 6','Enrichment Factor Condition 6','Copy Number Condition 7','Enrichment Factor Condition 7','Copy Number Condition 8','Enrichment Factor Condition 8','Copy Number Condition 9','Enrichment Factor Condition 9','Copy Number Condition 10','Enrichment Factor Condition 10','MW','cLogP','PSA','HBD','HBA','Fsp3','HeavyAcount']; // Add column names to validate
      const integerCols = ['Cycles','Sub-library ID','BB1','BB2','BB3','BB4','BB5','Prio']
      const requiredColumns =  ['Library','Compound ID','Cycles','Sub-library ID','BB1','BB2','BB3','BB4','BB5','SMILES BB1','SMILES BB2','SMILES BB3','SMILES BB4','SMILES BB5','SMILES Product','Copy Number Condition 1','Enrichment Factor Condition 1','Copy Number Condition 2','Enrichment Factor Condition 2','Copy Number Condition 3','Enrichment Factor Condition 3','Copy Number Condition 4','Enrichment Factor Condition 4','Copy Number Condition 5','Enrichment Factor Condition 5','Copy Number Condition 6','Enrichment Factor Condition 6','Copy Number Condition 7','Enrichment Factor Condition 7','Copy Number Condition 8','Enrichment Factor Condition 8','Copy Number Condition 9','Enrichment Factor Condition 9','Copy Number Condition 10','Enrichment Factor Condition 10','MW','cLogP','PSA','HBD','HBA','Fsp3','HeavyAcount','Prio','Confirmed after resynthesis?','BAY No of resynthesized compound','Comment']
      const columnIndexes = requiredColumns.map(col => headers.indexOf(col));
      const intColIndex = integerCols.map(col=> headers.indexOf(col))
      const numColIndex = numberCols.map(col => headers.indexOf(col))
   
      this.rowCount = rows.length - 1; // Exclude header row
      this.invalidRows = [];
      const missingHeaders = requiredColumns.filter(header => !headers.includes(header));

      if (missingHeaders.length) {
        this.invalidRows.push(`Missing headers: ${missingHeaders}`);
      } 
      // if (columnIndexes.includes(-1)) {
      //   this.invalidRows.push(`One or more required columns are missing in the CSV file ` );
      // }

      for (let i = 1; i < rows.length; i++) {
       
        const columns = rows[i].split(';');

        integerCols.forEach((col, index) => {
          const colIndex = intColIndex[index];
          if (!this.isIntegerOrBlank(columns[colIndex])) {
            this.invalidRows.push(`Data failed validation at ${headers[colIndex]} column at line ${i + 1}, expected type integer`);
             
          }
        });

        numberCols.forEach((col, index) => {
          const colIndex = numColIndex[index]
          if (!this.isNumberOrBlank(columns[colIndex])) {
            this.invalidRows.push(`Data failed validation at ${headers[colIndex]} column at line ${i + 1}, expected type number`);
             
          }
        });
      }
    }
  }

  isNumberOrBlank(value: string): boolean {
    const trimmedValue = value.trim();
    return trimmedValue === '' || !isNaN(Number(trimmedValue));
  }

  isIntegerOrBlank(value: string): boolean {
    const trimmedValue = value.trim();
    return trimmedValue === '' || /^\d+$/.test(trimmedValue);
  }

  

  uploadFileToS3() {
    
    const presignedUrlArrEle: any = this.presignedUrlArr[0];
    this.dataService.uploadFileToS3FASTQ(presignedUrlArrEle['presigned_url'], 
      this.selectedFiles[0]).subscribe(res => {
        this.saveHitlist()
      });
  }

  selectedObjects: any[] = [];

  selectionChanged(event: any) {
    console.log('Selection changed:', this.selectedObjects);
  }

  saveHitlist() {
    const payload =       {
        "operation_type": "add",
        "table_name": "bay-delt-hitlists",
        "data_type": "scientist_list",
        "file_name": this.selectedFiles[0]['file'].name,
        "hit_list_path": this.hitlistPath,
        "hit_list_name": this.hitlistFormGroup.controls['hitlistName'].value,
        "analyzedby": this.hitlistFormGroup.controls['analysedBy'].value,
        "project_id": this.hitlistFormGroup.controls['uniqueProjectID'].value,
        "scientist_name": this.hitlistFormGroup.controls['scientist'].value,
        "hit_list_lenght": this.hitlistFormGroup.controls['hitlistLength'].value,
        "hit_list_description": this.hitlistFormGroup.controls['comment'].value,
        "screen": this.extractValuesByKey(this.selectedObjects, 'screen_name'),
        "experimental_numner": this.extractValuesByKey(this.selectedObjects, 'experiment_number')
      }

    this.dataService.addHitlist(payload).subscribe(res => {
      this.openSnackBar('New HitList successfully created !!');
    
     // Save form logic here
    // Show success message
    this.showSuccessMessage = true;
    

    // Hide success message after 3 seconds
    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 3000);
      this.loadHitlist()
    })
  }

  extractValuesByKey(array: any[], key: string): string[] {
    return array.map(obj => obj[key]);
  }

  onApplyFilter(filterItem: any) {
        this.filterAnalyzed = filterItem[9].value
        this.filterProjectName = filterItem[3].value  
        this.filterScientist = filterItem[5].value
        this.filterImportDate = filterItem[8].value
       }

}




