import { Component, Inject, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil, subscribeOn } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModalServiceService } from 'src/app/modal-service.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../data.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';



@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  title = 'delt';
  showSideNav: boolean = false;
  @ViewChild('sidenav') sidenav!: MatSidenav;
  private subscription?: Subscription;
  isUserLoggedIn:boolean=false;
  userName?:string='';
  private readonly _destroy=new Subject<void>();
  role?:any;
  profile?:any;
  email?:string;
  userNameAvtar?:string='';
  descriptionObject: any;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig:MsalGuardConfiguration,
  private msalBroadCastService:MsalBroadcastService,private authService:MsalService,
  private modalService: ModalServiceService, private formBuilder: FormBuilder,
  private router: Router, private dataService: DataService, private location: Location){}

  
  toggleSlideBar() {
    this.sidenav.toggle();
  }


  ngOnInit() {
    // Subscribe to the boolean observable to get updates
    this.subscription = this.modalService.sideNav$.subscribe((value) => {
      if(value){
        this.descriptionObject = value
        this.toggleSlideBar()
      }
    });

    this.msalBroadCastService.inProgress$.pipe
    (filter((interactionStatus:InteractionStatus)=>
    interactionStatus==InteractionStatus.None),
    takeUntil(this._destroy))
    .subscribe(x=>
      {
        this.isUserLoggedIn=this.authService.instance.getAllAccounts().length>0;
      
        if(this.isUserLoggedIn)
        {
          this.userName = this.authService.instance.getAllAccounts()[0].name;
          this.email = this.authService.instance.getAllAccounts()[0].username;
          this.role = this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
          const accessToken = this.authService.instance.getAllAccounts()[0].idTokenClaims?.['access_token'];

          this.getAvtarUserName(this.email);
          
          // this.router.navigate(['/home']);
        }
        this.dataService.isUserLoggedIn.next(this.isUserLoggedIn);
      })

      const account: any = this.authService.instance.getAllAccounts()[0];
      if (account) {
        this.authService.instance.setActiveAccount(account);
        
        const tokenRequest = {
          scopes: ['user.read'], // Replace with the appropriate scope(s)
        };
  
        this.authService.acquireTokenSilent(tokenRequest).subscribe({
          next: (response) => {
            localStorage.setItem('access_token', response.accessToken);
            this.router.navigate(['/home']);
          },
          error: (error) => {
            console.error('Error acquiring token silently:', error);
          },
          complete: () => {
            console.log('Token acquisition complete.');
          }
        });
      }else {
        console.log("Not able to fetch access_token due to not active account");
      }

      
  }

  ngOnDestroy(): void {
    if (this.modalService.sideNav$) {
      this.subscription?.unsubscribe();
    }
    this._destroy.next(undefined);
    this._destroy.complete();
  }

  login(){
    if(this.msalGuardConfig.authRequest)
    {
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
    }
    else
    {
      this.authService.loginRedirect();
    }
  }
  logout(){
    this.authService.logoutRedirect({postLogoutRedirectUri:environment.postLogoutUrl});
  }

  private disableBackNavigation(): void {
    history.pushState(null, document.title, location.href);
    this.location.onUrlChange((url: string) => {
      history.pushState(null, document.title, location.href);
    });
  }

  private getAvtarUserName(email:string): any {
    const matchResult = email.match(/\.(.)/);
    const letterAfterDot = matchResult ? matchResult[1] : null;
    this.userNameAvtar = email[0].toUpperCase()+letterAfterDot?.toUpperCase();
  }
  
}
