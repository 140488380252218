import { AfterViewInit, Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ModalServiceService } from '../../modal-service.service';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModal } from 'src/app/components/confirmation-modal/confirmation-modal';
import { DataService } from 'src/app/data.service';
import { NotificationModalComponent } from 'src/app/components/notification-modal/notification-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common.service';



export interface UserData {
  name: string;
  role: string;
  activeSince: string;
  project: string;
  cwid: string;
  screening: string;
}

/**
 * @title Data table with sorting, pagination, and filtering.
 */


@Component({
  selector: 'app-scientist',
  templateUrl: './scientist.component.html',
  styleUrls: ['./scientist.component.css']
})
export class ScientistComponent implements OnInit {
  @ViewChild('squareModal') squareModal!: NotificationModalComponent;

  tableColumns: Array<Object> = [
    { key: 'scientist_name', displayName: 'Scientist' },
    { key: 'cwid', displayName: 'CWID' },
    { key: 'role_area', displayName: 'Role' },
    { key: 'active_since', displayName: 'ActiveSince' },
    { key: 'projects', displayName: 'Project' },
    { key: 'last_screening', displayName: 'LastScreening' },
  ];

  screenHistoryColumns: Array<Object> = [
    { key: 'screen_name', displayName: 'Name' },
    { key: 'submission', displayName: 'Submission' },
  ];

  screens = []
  displayedColumns: string[] = [
    'Scientist'
    , 'Role', 'ActiveSince', 'Project', 'LastScreening', 'menu'];
  dataSource!: MatTableDataSource<any>;
  selection = new SelectionModel<UserData>(true, []);

  clickable = true;
  showCheckbox = true;
  showScientistList = true;
  showAddRow = false;
  showEditRow = false;
  name: string = '';
  project: string = '';
  cwid: string = '';
  role!: string;
  projectAssign!: string;
  showSuccessMessage: boolean = false;
  selectedOptions1: any = new FormControl([]);
  selectedOptions2: any = new FormControl([]);
  optionRole = ['Biologist', 'Chemist', 'Modeller', 'Machine Learning Scientist', 'Other'];
  optionProjectAssign = ['Project Name 01', 'Project Name 02', 'Project Name 03', 'Project Name 04'];
  showDeleteConfirmation = false;
  currentScientist: any;
  @ViewChild(MatSort) sort!: MatSort;
  users: any;



  constructor(
    private dialog: MatDialog,
    private modalService: ModalServiceService, private snackBar: MatSnackBar,
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
  ) {
    // Create 100 users
    // Assign the data to the data source for the table to render
  }

  ngOnInit() {

    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 100000);
    this.getScientistData();
  }

  getScientistData() {
    this.dataService.getScientists().subscribe(res => {
      if (res) {
        this.users = [...res]
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.sort = this.sort;
      }
    })
  }

  ngAfterViewInit() {
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openModal(details?: any) {
    this.modalService.openModalScientist();
  }

  addScientistComponent() {
    // alert(`${this.name}, ${this.cwid}, ${this.project}, ${this.role}`);
    const data = {
      "table_name": "bay-delt-scientists",
      "operation_type": "add",
      "cwid": this.cwid,
      "scientist_name": this.name,
      "role_area": this.role,
      "projects": this.project,
      "last_screening": "No screening"
    }
    this.dataService.addScientist(data).subscribe(res => {
      if (res) {
        this.commonService.openNotificationDialog(this.dialog,'New Scientist successfully added!', () => this.afterNotifationPopupClosed())
        this.getScientistData();
      } else {
        this.openSnackBar('Sorry!! could added');
      }
    })


    setTimeout(() => {
      this.showSuccessMessage = false;
    }, 3000);
  }
  
  afterNotifationPopupClosed(){
    this.showAddRow = false;
    this.showScientistList = true;
    this.getScientistData();
  }


  navigateToAddScientistComponent() {
    this.showScientistList = false;
    this.showAddRow = true;
    this.showEditRow = true;

    this.clearScientist();
  }

  clearScientist() {
    this.name = '';
    this.project = '';
    this.cwid = '';
    this.role = '';
  }


  goBack() {
    this.showScientistList = true;
    this.screens = []
    this.showAddRow = false;
  }

  removeOption(option: string) {
    const selectedValues = this.selectedOptions2.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions2.setValue(selectedValues);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 1000,
      panelClass: 'custom-snackbar'
    });
    setTimeout(() => {
      this.goBack();
    }, 1000);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  // TODO: Sherif 
  rowDetails(row: any, edit: boolean = true) {
    this.showScientistList = false;
    this.showAddRow = true;
    this.showEditRow = edit
    this.currentScientist = row;
    this.name = this.currentScientist.scientist_name;

    this.role = this.currentScientist.role_area;
    this.cwid = this.currentScientist.cwid;
    this.project = this.currentScientist.projects;

    this.dataService.getScreenHistory(this.cwid).subscribe(res => {
      this.screens = res;
    })
    // alert(`${this.name}, ${this.cwid}, ${this.project}, ${this.role}`);

  }

  openDialog() {
    const dialogRef = this.dialog.open(ConfirmationModal, {
      data: {
        message: 'Are you sure want to delete this Scientist?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });
    const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        snack.dismiss();
        const a = document.createElement('a');
        a.click();
        a.remove();
        snack.dismiss();
        this.snackBar.open('Closing snack bar in a few seconds', 'Fechar', {
          duration: 2000,
        });
      }
    });
  }


  deleteScientist(rowData?: any) {
    const indexFound = this.users.findIndex((scientist: any) => scientist.scientist_name == rowData.scientist_name)
    this.dataService.deleteScientist(rowData.cwid).subscribe(res => {
      if (res && indexFound != -1) {
        this.users.splice(indexFound, 1);
        this.users = [...this.users]
      }
    })
  }

}
