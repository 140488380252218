<div class="header-container" >
    <div class="title-container" *ngIf="context=='screen'">
        <mat-icon class="mat-icon-home" (click)="navigateToScreenDetails()">arrow_back</mat-icon>
        <span class="breadcrumb" (click)="navigateToScreenList()">Screens </span>
        <span class="breadcrumb"> | </span>
        <span class="breadcrumb" (click)="navigateToScreenDetails()"> {{screenName}} </span>
        <span class="breadcrumb"> | </span>
        <span class="breadcrumb"> {{conditionName}} </span>
    </div>
    <div class="flex">
        <span class="header-first-part" *ngIf="context=='screen'"> Screen Results </span>
        <mat-icon *ngIf="context=='screen'" style="vertical-align: middle; color: #2196F3;">keyboard_arrow_right</mat-icon>
        <span *ngIf="context=='screen'" class="header-first-part header-second-part push"> {{conditionName}} </span>
        <button (click)="showChart(true)" [ngClass]=" chartView ? 'isClicked' : 'notClicked'"
            class="export-button chart-button zero-margin"> Chart View</button>
        <button (click)="showChart(false)" [ngClass]=" !chartView ? 'isClicked' : 'notClicked'" class="export-button">
            Table View</button>
    </div>

    <div>
        <span *ngIf="context=='screen'" class="title-description"> View below the compounds of the selected condition</span>
        <span *ngIf="context=='library'" class="title-description">  Statistical overview on NGS of Naive library</span>
   
    </div>

    <!-- <span class="chart-highlight" *ngIf="chartView">
        <mat-icon class="align-middle check-icon">check</mat-icon>
        <span class="align-middle">
            126 of 126 item has succesfully completed
        </span>

        <div class="cicle-check-icon">
            <span class="align-middle "> 126 </span>
            <mat-icon class="align-middle ">check_circle_outline</mat-icon>
        </div>
    </span> -->
</div>
<!-- <div class="header-container" *ngIf="context=='library'">
    Statistical overview on NGS of Naive library
</div> -->

<div class="outer-container" [ngStyle]="{ display: chartView ? 'flex' : 'none' }">
    <div class="chart-container" >
        <div class="row">
            <span class="chart-header">NGS Processing Results</span>
            <!-- <button class="export-button"> Export Results</button> -->
        </div>
        <span class="chart-name">Name PP03075</span>
        <div>
            <canvas id="myChart"></canvas>
        </div>
    </div>
    <div class="chart-more-details-container">
        <div class="chart">
            <span class="details-header"> More Details 
                <button mat-button (click)="chartExport('moreDetail')">
                    <mat-icon class="more-details-download-btn">save_alt</mat-icon>
                  </button>
            </span>
            <mat-divider style="padding: 0;"></mat-divider>
        </div>

        <!-- // TODO: LOOP OVER THE DETAILS DYNAMICLLY -->

        <!-- <div *ngFor="let item of chartViewDataMapped | keyvalue"  class="chart">
            <span class="details-key"> {{item.key}}</span>
            <span class="details-value"> {{item.value}}</span>
            <mat-divider style="padding: 0;"></mat-divider>
        </div> -->

        <div *ngFor="let item of chartViewDataMappedSorted"  class="chart">
            <span class="details-key"> {{getKey(item)}}</span>
            <span class="details-value"> {{getValue(item)}}</span>
            <mat-divider style="padding: 0;"></mat-divider>
        </div>

    </div>
</div>

<div [ngStyle]="{ display: tableView ? 'block' : 'none' }" class="table-data-container">
    <div class="export-button-row">
        <button mat-button (click)="chartExport('table')" class="export-button">
            Export Result<mat-icon class="more-details-download-btn">publish</mat-icon>
          </button>
    </div>
        <app-table-component 
            [columns]="screenResultColunms" 
            [tableData]="screenResult" 
            listName="Screens ..." 
            [showActionMenu]=false 
            [showCheckbox]=false
        />
        <br>
    <div class="export-button-row">
        <p style="color: #2196F3;">Total number of result: {{total_row_count | number}}</p>
        <!-- <button mat-button disabled="true" class="export-button">
            Total number of result: {{total_row_count | number}}
        </button> -->
    </div>
</div>


  


  