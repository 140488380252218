<div *ngIf="showComponentScientist">
    <div class="row homepage">
      <mat-icon class="mat-icon-home">folder_open</mat-icon><span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Projects</span>
    </div>
    <div class="row message">
      <p><strong>Projects overview ({{projectListCount}})</strong></p>
      <span class="text">Project contains several Screens and Hit Lists</span>
      <button (click)="navigateToAddScientistComponent()" mat-flat-button color="primary" class="btn-add-library"><mat-icon>add</mat-icon>New Project</button>
    </div>
    <br><br>
    <!-- <div class="row homepage">
      <mat-form-field class="homepage">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Bayer" #input>
        </mat-form-field>
     </div> -->

    <div class="row homepage">
      <app-table-component [columns]="projectListColunms" [tableData]="projectList | filterprotein: filterProtein : filterProjectLead : filterStatus : filterProjectName : filterProjectID" listName="Project"  
      [clickable]="true"  (showRowDetails)="rowDetails($event)"  [showActionMenu]=true     [showDuplicateOption]=true   [showEditRow]=true  [removeDelete]="admin"
      (editRow)="editRow($event)" (duplicateRow)="duplicate($event)" (deleteRow)="delete($event)"
      [filters]="criteria" (filterItem)="onApplyFilter($event)"/>
    </div>
   
  </div>

  <div *ngIf="showComponentAddScientist">
      <div class="row homepage">
        <mat-icon mat-icon-button (click) ="goBack()"class="mat-icon-home">west</mat-icon>
        <span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Projects</span>
      </div>
      <div class="row message">
        <p><strong>Creating New Project</strong></p>
        <span class="text">Insert details below</span>
        <button (click)="addProjectComponent()" mat-flat-button color="primary" class="btn-add-library" [disabled]="!projectFormGroup.valid"><mat-icon>save</mat-icon>Save</button>
      </div>
      <br><br>
      <div class="row add-scientist">
          <div class="mat-elevation-z8">
              <br>
              <p class="form-grid-heading">Project Details</p><br>
          <div class="form-grid-asym" [formGroup]="projectFormGroup">
              <div class="row div1" >
                  <mat-label class="mat-label">Project Status*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <mat-select placeholder="Select Project status" formControlName="projectStatus" >
                        <mat-option *ngFor="let option of projectStatusArr" [value]="option">
                            {{ option }}
                        </mat-option> 
                   </mat-select>
                  </mat-form-field>    
              </div>
              <div class="row div2">
                <mat-label class="mat-label">Project Name*</mat-label><br>
                <mat-form-field class="matformfield">
                    <input matInput placeholder="Insert project name" formControlName="projectName">
                </mat-form-field>
             </div>
             <div class="row div3">
                <mat-label class="mat-label">Project ID*</mat-label><br>
                <mat-form-field class="matformfield">
                    <input matInput placeholder="Insert project id" formControlName="projectID">
                </mat-form-field>
             </div> 
             <div class="row div4" >
                <mat-label class="mat-label">Project Lead*</mat-label><br>
                <mat-form-field class="matformfield">
                    <mat-select placeholder="select project lead" formControlName="projectLead">
                      <mat-option *ngFor="let option of projectLeadArr" [value]="option.scientist_name">
                          {{ option.scientist_name}}
                      </mat-option>
                  </mat-select> 
                </mat-form-field>
             </div>
              <div class="row div5">
                  <mat-label class="mat-label">Project Team*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <mat-select placeholder="Select team"  multiple formControlName="projectTeam">
                        <mat-select-trigger>
                            <ng-container  *ngIf="projectFormGroup.get('projectTeam')?.value as selectedProjectTeam">
                              <ng-container *ngFor="let option of selectedProjectTeam">
                                <mat-chip class="selected-option" [removable]="true" (removed)="removeOption1(option)">
                                  {{ option }}
                                  <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                              </ng-container>
                            </ng-container>
                           
                          </mat-select-trigger>
                          <mat-option *ngFor="let option of projectTeamArr" [value]="option.scientist_name">
                              {{ option.scientist_name }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>   
              </div>
              <div class="row div6" >
                  <mat-label class="mat-label">Target protein*</mat-label><br>
                  <mat-form-field class="matformfield">
                      <mat-select placeholder="Select protein" formControlName="targetProtein">                                                        
                            <mat-option *ngFor="let option of targetProteinArr" [value]="option">
                              {{ option }}
                            </mat-option>
                      </mat-select>
                  </mat-form-field>    
              </div>
              <div class="row div7" >
                <mat-label class="mat-label">Libraries*</mat-label><br>
                <mat-form-field class="matformfield">
                    <mat-select class="custom-dropdown-arrow" placeholder="Select Libraries" multiple  formControlName="library">
                        <mat-select-trigger>
                          <ng-container  *ngIf="projectFormGroup.get('library')?.value as selectedLibrary">
                            <ng-container *ngFor="let option of selectedLibrary">
                              <mat-chip class="selected-option" [removable]="true" (removed)="removeOption3(option)">
                                {{ option }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </ng-container>
                          </ng-container>
                          </mat-select-trigger>
                        <mat-option *ngFor="let option of libraryArr" [value]="option.library_name">
                            {{ option.library_name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>                       
            </div>
            <div class="row div8">
                <mat-label class="mat-label">Comments</mat-label><br>
                <mat-form-field class="matformfieldtextarea">
                    <textarea matInput placeholder="Please enter comments" rows="10" formControlName="comment"></textarea>
                </mat-form-field>
            </div>
          </div> 
                <!-- <button mat-raised-button color="primary" (click)="saveForm()">Save</button>   -->
                <div *ngIf="showSuccessMessage" class="success-message">Form saved successfully!</div> 
          </div>   
      </div>   
    </div>

    
      
    
  
  
