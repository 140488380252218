import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ModalServiceService } from '../../modal-service.service';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';


export interface UserData {
  name: string;
  definition: string;
  activeSince: string;
  info: string;
}
/** Constants used to fill up our data base. */
/**
 * @title Data table with sorting, pagination, and filtering.
 */

@Component({
  selector: 'app-about',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.css']
})
export class LibraryComponent implements AfterViewInit {

  tableColumns: any =
    [
      { key: 'library_name', displayName: 'Library' },
      { key: 'compound', displayName: 'Theoretical number of compound' },
      { key: 'creation_date', displayName: 'Active Since' },
      { key: 'info', displayName: 'Description' },
      { key: 'library_status', displayName: 'Status'},
      { key: 'scientist_name', displayName: 'Scientists'},
      { key: 'ngs_files', displayName: 'NGS Files'}
    ]

  libraries: any;
  showCheckbox: boolean = false
  clickable: boolean = true
  // ['Name', 'Definition', 'ActiveSince', 'Info'];
  dataSource!: MatTableDataSource<UserData>;
  @Input() tableData?: any[]; // Assume an array of objects as the table data

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  loading: boolean = false;
  statisticsList: any;
  screenResultList: any;
  chartData: any;
  libraryDetails: any;

  constructor(private modalService: ModalServiceService,
    private dataService: DataService,
    private router: Router
  ) {
    // Create 100 users
    // this.libraries = Array.from({ length: 100 }, (_, k) => createNewUser(k + 1));
      this.getLibraryData();
    // Assign the data to the data source for the table to render
   
  }
  getLibraryData() {
    this.dataService.getLibrariesData().subscribe(res => {
      if (res) {
        this.libraries = [...res];
        this.dataSource = new MatTableDataSource(this.libraries);
      }
    })
  }

  ngAfterViewInit() {
    if(this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openModal(rowDetails?: any, edit?:boolean ) {
    if(rowDetails) {
      rowDetails.editMode = edit;
    }
    this.modalService.openModal('Library', rowDetails).afterClosed().subscribe(result => {
        if(result || edit) {
          this.getLibraryData();
        }
      });
  }


  addLibrary() {
    this.router.navigate(['libraries/process-new-library']);
  }

  toggleBarchartView(row: any) {
    localStorage.setItem('libraryDetails', JSON.stringify(row));
    this.getScreenStatistics(row);
    const inputString = row.ngs_files;
       // Split the string by commas
    const valuesArray = inputString.split(',');
    // Get the first value (at index 0)
    const firstValue = valuesArray[0];
    if(row.ngs_files) {
      this.getHitmilesAndCSVImportURL(row.library_name, 'Naive', firstValue, row);
    } else {
      this.router.navigate(['libraries/library-details'], { state: { data: row } })
    }
    

    
    // this.router.navigate(['libraries/library-details'], { state: { data: row.ldf_files } })
    //this.router.navigate(['libraries/libray-details'])
  }

  async getHitmilesAndCSVImportURL(screenName: string, condition: string, ngs_files: string, row: any) {
    this.loading = true;
    
    
    const api1Observable = this.dataService.getHitmiles(screenName, condition, ngs_files, 'library');
    const api2Observable = this.dataService.getHitmilesCSVPresignedURL(screenName, condition, ngs_files, 'library');
    try {
      const [api1Response, api2Response] = await Promise.all([
        firstValueFrom(api1Observable),
        firstValueFrom(api2Observable)
      ]);
      // Handle the responses of both API calls
      if (api1Response.statusCode === 200 &&  api2Response.statusCode === 200 && 
          api1Response.body.length !== 0 && api2Response.body !== null ) {
        localStorage.setItem('hitmiles_data', JSON.stringify(api1Response.body));
        localStorage.setItem('hitmiles_data_total_count', JSON.stringify(api1Response.count));
        localStorage.setItem('getHitmilesParams', JSON.stringify({screenName:screenName, condition:condition,ngs_files:ngs_files}));
        localStorage.setItem('hitmiles_CSV_PresignedURL', JSON.stringify(api2Response.body));
        const index = this.statisticsList.findIndex((data: any)=> data.screen_name == row.library_name)
        localStorage.setItem('chartData', JSON.stringify(this.chartData[index]));
        this.loading = false;
        //this.router.navigate(['libraries/chart-analysis', 'Naive']);
        //this.router.navigate(['libraries/libray-details'])
        this.router.navigate(['libraries/library-details'], { state: { data: row } })
      } else if (api1Response.statusCode !== 200 || api1Response.body.length === 0 ||
         api2Response.statusCode !== 200 || api2Response.body === null) {
          this.loading = false;
          alert("Could not fetched complete data there might be some server error!!");
      }
    } catch (error) {
      // Handle errors in the Promise.all or individual promises
      this.loading = false;
      //this.router.navigate(['libraries/libray-details'])
      alert("There is a server error!!");
      console.error('An error occurred:', error);
      
    }
  }


  getScreenStatistics(libraryDetails: any) {
    //console.log("this.libraryDetails",this.libraryDetails)
    this.dataService.getLibraryStatistics(libraryDetails.library_name).subscribe(res => {
      this.statisticsList = [...res];
      this.screenResultList = [...this.statisticsList].map(item => {
        return {
          library_name: item.library_name,
          totalMolecules: item.total_molecules, 
          ldfFiles: libraryDetails.ldf_files,
          description: libraryDetails.description,
          statusIcon: libraryDetails.screen_status
        }
      })
      this.chartData = [...this.statisticsList];
    })
    
  }

  deleteLibrary(rowData?: any) {
    const indexFound = this.libraries.findIndex((library: any) => library.library_name == rowData.library_name)
    this.dataService.deleteLibrary(rowData.library_name).subscribe(res => {
      if (res && indexFound != -1) {
        this.libraries.splice(indexFound, 1);
        this.libraries = [...this.libraries]
      }
    })
  }
}
