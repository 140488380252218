import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ModalServiceService } from '../../modal-service.service';
import { FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalScreenComponent } from '../modal-screen/modal-screen.component';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';

import * as XLSX from 'xlsx';
import { CommonService } from 'src/app/common.service';
import { MatButtonToggle } from '@angular/material/button-toggle';
// import * as ExcelJS from 'exceljs';
import {
  GridApi, GridOptions, GridReadyEvent, RowClassParams, RowStyle,
} from 'ag-grid-community'
import 'ag-grid-enterprise';
import { FILE_TYPE } from 'src/app/enums/File-type.enum';
import { DataService } from 'src/app/data.service';
import { concatMap, from, forkJoin } from 'rxjs';


export interface multiPartFileObject {
  
        upload_id: string,
        parts: any[],
        key: string
      }


const NGS_READ_ONE_COL = 'NGS_read_1'
const NGS_READ_TWO_COL = 'NGS_read_2'
 
@Component({
  selector: 'app-process-new-library',
  templateUrl: './process-new-library.component.html',
  styleUrls: ['./process-new-library.component.css']
})
export class ProcessNewLibraryComponent {
  private gridApi!: GridApi<any>;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  @ViewChild('myModal') myModal!: ElementRef;

  @ViewChild('toggleButton1') toggleButton1!: MatButtonToggle;
  @ViewChild('toggleButton2') toggleButton2!: MatButtonToggle;
  @ViewChild('toggleButton3') toggleButton3!: MatButtonToggle;
  @ViewChild('toggleButton4') toggleButton4!: MatButtonToggle;

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  showCheckbox = true;
  clickable = true;
  screenList: any;

  screenTab = true;
  isTabComplete = true;

  personalFormGroup!: FormGroup;
  ngsFormGroup!: FormGroup;
  experimentalFormGroup!: FormGroup;
  processingFormGroup!: FormGroup;
  readFileGroup!: FormGroup;

  addressFormGroup?: any;
  tab1Selected = true;
  tab2Selected = false;
  tab3Selected = false;
  tab4Selected = false;

  uploadedFileSecondTab: boolean = false;
  uploadedFileThirdTab: boolean = false;
  selectedTab = 'tab1'; // Initial selected tab

  modalFormData?: any;
  data?: any;

  name!: string;
  role!: string;
  projectAssign!: string;
  screener!: string;
  experimentNumber!: string;
  alignmentAlgorithm!: string;
  efc!: string;
  errorMargin!: string;
  projectTeam!: string[];
  targetProtein!: string[];
  showSuccessMessage: boolean = false;
  selectedOptions2: any = new FormControl([]);
  selectedOptions4: any = new FormControl([]);
  libraries: any = new FormControl([]);

  // TODO: SHERIF make library called from endpoint
  libraryArr = [];
  alignAlgo1: string[] = [];
  alignAlgo2 = ['fac1', 'fact2', 'fact3', 'fact4', 'fact5'];
  matchMethod: string[] = ['Whole Sequence Exact Match', 'Barcode Match'];
  uploaders = [];
  readFile!: string;
  readFiles = ['Merged', 'Paired'];
  readFileType = 'Paired';
  errorMarginsValues = ['0', '1', '2', '3'];

  fileUploadedTabs: Array<any> = [];
  selectedFiles: any = [];
  //newSelectedFiles: any =[];
  selectedNgsFiles: any = [];
  ngsFiles: any = []

  libraryUpload!: string;
  selectedFile!: any;
  isFileUploaded = false;
  uploadedFile: File | null = null;
  fileControl = new FormControl();
  uploadedFiles: File[] = [];


  xlData: any = [];

  xlDisplayedColumns: string[] = ['Condition Name', 'Description', 'Field A', 'Field B', 'Field C', 'Field D', 'Field E', 'Field F', 'Field G', 'NGS Read-1', 'NGS Read-2'];

  isXLSXTableManual = false;
  isXLSXTableExport = false;

  xlsxHeaders: any[] = [];
  xlsxData: any;
  rowData: any;

  showInputError: boolean = false;
  showNaiveNGSFilesErr: boolean = false;
  gridOptions: GridOptions;

  ngsFilesUploaded: boolean = false;
  experementalConditionUploaded: boolean = false;

  presignedUrlArr: Object[] = [];
  ldfPresignedUrlArr: Object[] = [];
  structPresignedUrlArr: Object[] = []
  ngsPresignedUrlArr: Object[] = []

  uploadLdf = false;
  uploadStruct = false;
  uploadNgs = false


  screenSubmitted: boolean = false;
  showEXPvalidationError = false;
  indexCellSelected: any;

  errorMessageDuplicateFileUpload: string = '';

  ngsReadOneFiles: any = []
  ngsReadTwoFiles: any = []

  expReadOneFileNames: any = []
  expReadTwoFileNames: any = []


  expRows: any;
  expHeaders: any;

  showErrorOddNGSFiles: boolean = false;
  loading: boolean = false;
  loadingExpFile: boolean = true;
  loadingNGSFile: boolean = true;


  libFileSelected: boolean = false;
  showErrorExcel = false;
  showErrorCSV = false; 
  libraryDefinitionFile = FILE_TYPE.LIBRARY_DEFINITION
  libraryStructureFile = FILE_TYPE.LIBRARY_STRUCTURE

  commonTimeStamp = 0
  timestamp = 0
  duplicatedName = false
  
  ngOnInit(): void {
    this.dataService.getScientists().subscribe(res => {
      if (res) {
        this.uploaders = res.map((scientist: any) => `${scientist.scientist_name} (${scientist.cwid})`)
      }
    })
    this.dataService.getLibrariesData().subscribe(res => {
      let libraryData = res;
      this.libraryArr = res.map((library: any) => library.library_file)
    })
    this.personalFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      uploader: [null, Validators.required],
      libraryFileInput: [null, Validators.required],
      chemicalFileInput: [null, Validators.required],
      compounds: ['', Validators.required],
      description: [''],
    });

    this.ngsFormGroup = this.formBuilder.group({
      ngsFileInput: [null, Validators.required],
    });

    this.experimentalFormGroup = this.formBuilder.group({
      experimentalFileInput: [null, Validators.required],
    })

    this.processingFormGroup = this.formBuilder.group({
      method: ['', Validators.required],
      algorithm: ['', Validators.required],
      errorMargins: ['', Validators.required],
    })

    this.readFileGroup = this.formBuilder.group({
      readFile: ['', Validators.required]
    })
    this.readFileGroup.controls['readFile'].setValue(this.readFileType)
  }

  
  cellRules = {
    'rag-red': (param: any) =>  (!param.value || (param.colDef.field == NGS_READ_ONE_COL && this.ngsReadOneFiles && 
      this.ngsReadOneFiles.findIndex((file:any) => file.replace('.gz','') == param.value.replace('.gz','')) == -1  && this.indexCellSelected == param.rowIndex) ||
      (param.colDef.field == NGS_READ_TWO_COL && this.ngsReadTwoFiles && 
        this.ngsReadTwoFiles.findIndex((file:any) => file.replace('.gz','') == param.value.replace('.gz','')) == -1  && this.indexCellSelected == param.rowIndex))
  };
  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService,
    private dataService: DataService
  ) {

    this.gridOptions = {
      rowData: this.rowData,
      rowClassRules: {
        'even-row': (params: any) => params.node.rowIndex % 2 === 0,
      },

      onCellValueChanged: this.onCellValueChanged,
      // Other AG-Grid configuration options
    };

  }

  onLibFilesSelected(fileInput: HTMLInputElement, fileType: FILE_TYPE) {
    const files: any | null = fileInput.files;
    if (files && files.length) {
      if (this.commonTimeStamp == 0) {
        this.timestamp = new Date().getTime()
        this.commonTimeStamp = 1
      }
    // timestamp = this.commonTimeStamp == 0 ? new Date().getTime() : timestamp
      //let timestamp = new Date().getTime()

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        file.fileType = fileType
       
        
        if (this.commonService.validateFileName(file, fileType)) {
          // find file type and replace it 
          const indexFound = this.selectedFiles?.findIndex((file: any) => file.fileType == fileType)
          //replace File
          if (indexFound != -1) {
            this.selectedFiles.splice(indexFound, 1);
          }

          // appending timestamp to file name to get uniqueness while uploading to s3 and making entry in dynamodb

          
          // let fileExt = file['name'].split('.').pop();  /// last index split
          // let filenameWithoutExt = file['name'].substring(0, file['name'].lastIndexOf('.')) || file['name']
          //file['name'] = filenameWithoutExt + '_' + timestamp + '.' + fileExt
          let fileExt = ''
          let filenameWithoutExt = ''
          if (fileType == this.libraryDefinitionFile){
            if (file['name'].endsWith('.gz')) {
              filenameWithoutExt = file['name'].replace('.xlsx.gz', '');
              fileExt = 'xlsx.gz'
          } else {
              filenameWithoutExt = file['name'].replace('.xlsx', '');
              fileExt = 'xlsx'
          }
          } else if (fileType == this.libraryStructureFile) {
            if (file['name'].endsWith('.gz')) {
              filenameWithoutExt = file['name'].replace('.csv.gz', '');
              fileExt = 'csv.gz'
          } else {
              filenameWithoutExt = file['name'].replace('.csv', '');
              fileExt = 'csv'
          }
          }

          const newFile = new File([file], filenameWithoutExt + '_' + this.timestamp  + '.' + fileExt , { type: file.type });
        
          const fileWrapper = {
            file: newFile,
            fileType: fileType
        };
          
        
          this.selectedFiles.push(fileWrapper);
          if (this.selectedFiles.length == 2) {
            this.libFileSelected = true
            this.showErrorExcel = false;
            this.showErrorCSV = false;
          }
          
        } else {
          if (fileType == this.libraryDefinitionFile) {
            this.showErrorExcel = true;
            this.showErrorCSV = false;
          }
          if (fileType == this.libraryStructureFile) {
            this.showErrorExcel = false;
            this.showErrorCSV = true;
          }
        }
      }
    }
  }

  ngAfterViewInit() {
    if (this.data && this.personalFormGroup) {
      this.personalFormGroup?.get('name')?.setValue(this.data.name);
      if (this.data.duplicateRow) {
        this.personalFormGroup?.get('name')?.setValue(this.data.screen_name + '_copy');
        let screenerValue = `${this.data.scientist_name} (${this.data.cwid})`
        this.personalFormGroup?.get('screener')?.patchValue(screenerValue)
        this.personalFormGroup?.get('libraries')?.patchValue([this.data.ldf_files])
        this.personalFormGroup?.get('experiment')?.setValue(this.data.experiment_number)
        this.personalFormGroup?.get('protein')?.setValue(this.data.protein)
        this.personalFormGroup?.get('description')?.setValue(this.data.description)
        this.personalFormGroup?.get('algorithm')?.setValue(this.data.alignment_algorithms)
        this.personalFormGroup?.get('errorMargins')?.setValue(this.data.error_margin)
      }
    }

    // this.screenListDataSource.paginator = this.paginator;
    // this.screenListDataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.screenTab = true;
  }

  selectFileType(event: any) {
    this.readFileType = event.value
    this.ngsFiles = []
    this.ngsReadOneFiles = []
    this.ngsReadTwoFiles = []
    this.showErrorOddNGSFiles = this.showNaiveNGSFilesErr = this.showInputError = false;
    this.errorMessageDuplicateFileUpload = ''
    this.commonService.readFileType$.next(event.value)
  }

  onCellValueChanged(params: any) {
    const field = params.colDef.field;
    const value = params.value;
    const rowIndex = params.rowIndex;
    this.indexCellSelected = rowIndex

    if (field == NGS_READ_ONE_COL) {
      let valueTemp = value
      if(valueTemp && valueTemp.includes('.gz')){
        valueTemp= valueTemp.replace('.gz','');
      }
      this.expReadOneFileNames.splice(rowIndex,1, valueTemp);
      let valid = this.validateExpByFileName(valueTemp);
      if(valid) {
        let readTwoValue = value.replace('R1','R2');
        params.data[NGS_READ_TWO_COL] = readTwoValue;
        this.expReadTwoFileNames.splice(rowIndex,1, readTwoValue);
        params.api.refreshCells({columns : [NGS_READ_ONE_COL,NGS_READ_TWO_COL]}) ;
      }   
      this.vaidateAllFileNamesInExp();  
      this.gridApi.redrawRows();
    } 
    if (field == NGS_READ_TWO_COL) {
      // const indexFound = this.ngsFiles && this.ngsReadTwoFiles.findIndex((file: any) => file == value);
      let valueTemp = value
      if(valueTemp && valueTemp.includes('.gz')){
        valueTemp= valueTemp.replace('.gz','');
      }
      this.expReadTwoFileNames.splice(rowIndex,1, valueTemp);
      let valid = this.validateExpByFileName(valueTemp);
      if(valid) {
        let readOneValue = value.replace('R2','R1');
        params.data[NGS_READ_ONE_COL] = readOneValue;
        this.expReadOneFileNames.splice(rowIndex,1, readOneValue);
        params.api.refreshCells({columns : [NGS_READ_ONE_COL,NGS_READ_TWO_COL]}) ;
      }    
      this.vaidateAllFileNamesInExp(); 
      this.gridApi.redrawRows();
    }
 

  }


  screenChange(event: any) {

  }

  applyFilter(event: Event) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // this.screenListDataSource.filter = filterValue.trim().toLowerCase();

    // if (this.screenListDataSource.paginator) {
    //   this.screenListDataSource.paginator.firstPage();
    // }
  }

  submitScreen() {
    const libFile = this.selectedFiles.filter((item: any) => { return item.fileType === "Library Definition"})
    const libraryFile = libFile[0]['file'].name

    const structFile = this.selectedFiles.filter((item: any) => { return item.fileType === "Library Structure"})
    const structureFile = structFile[0]['file'].name


    const libraryFileWithoutExt =  libraryFile.substring(0, libraryFile.lastIndexOf('.')) || libraryFile
    const libraryName = this.personalFormGroup.get('name')?.value;
    const description = this.personalFormGroup.get('description')?.value;
    const compound = this.personalFormGroup.get('compounds')?.value;
    const uploader = this.personalFormGroup.get('uploader')?.value;
    const scientistName = uploader.split(' ')[0]
    let secondSplit = uploader.split('(')[1]
    const cwid = secondSplit.substring(0, secondSplit.length - 1);
    const ngsFiles = this.ngsFiles.join(',')
    const errorMargin = this.processingFormGroup.get('errorMargins')?.value;
    const alignmentAlgorithm = this.processingFormGroup.get('algorithm')?.value;
   
    this.screenSubmitted = true
    const data = {
      table_name: "bay-delt-libraries",
      operation_type: "add",
      single_read: (this.readFileType == 'Merged')? "True" : "False",
      library_name: libraryName,
      compound: compound,
      scientist_name: scientistName,
      cwid: cwid,
      description: description,
      ngs_files: ngsFiles,
      alignment_algorithms: alignmentAlgorithm,
      error_margin: errorMargin,
      ldf_files: libraryFileWithoutExt,
      struct_file: structureFile,
      library_status: ngsFiles? "Initiated" : "No NGS provided",
      is_whole_sequence_exact_match: (this.processingFormGroup.get('method')?.value == 'Whole Sequence Exact Match')? "True" : "False"
    }
    this.dataService.addLibrary(data).subscribe(res => {
      if (res) {
        this.commonService.openNotificationDialog(this.dialog,'New Library successfully created!',() => this.navigateToscreensPage());
      }
    })
  }

  selectSequenceMatchMethod(event: any) {
    if(event.value == 'Whole Sequence Exact Match') {
      this.alignAlgo1 = []
      this.alignAlgo1.push("None")
      this.alignAlgo1.push('Hamming Distance Calculation')
      this.processingFormGroup?.get('errorMargins')?.setValue('0');
      this.processingFormGroup?.get('errorMargins')?.disable()
      this.processingFormGroup?.get('algorithm')?.setValue('None')
      this.processingFormGroup?.get('algorithm')?.disable()
    }
    else {
      this.alignAlgo1 = [];
      this.alignAlgo1.push('Hamming Distance Calculation')
      this.processingFormGroup?.get('errorMargins')?.setValue('1');
      this.processingFormGroup?.get('errorMargins')?.enable()
      this.processingFormGroup?.get('algorithm')?.setValue('Hamming Distance Calculation')
      this.processingFormGroup?.get('algorithm')?.enable()
    }

  }


  openSubmitModal() {
    // Access the modal using the ViewChild reference
    this.myModal.nativeElement.style.display = 'block';
  }

  checkDuplicateName() {
    let name = this.personalFormGroup.get('name')?.value;
    this.loading = true

    forkJoin([this.dataService.scanDynamoDB(name, 'bay-delt-screens'), this.dataService.scanDynamoDB(name, 'bay-delt-libraries')]).subscribe(
      ([response1, response2]) => {
        if(response1 || response2) this.duplicatedName = true
        else this.duplicatedName = false
        this.loading = false
      },
      error=> {
        alert("Error")
        this.loading = false
        this.duplicatedName = true
      }
    )

  }

  afterCloseSubmitModal() {
    // Access the modal using the ViewChild reference
    this.myModal.nativeElement.style.display = 'none';
    this.reloadComponent();
  }


  openModal() {
    //this.modalService.openModalScreen();
    this.openModalScreen();
  }

  submitForm() {
    // Perform form submission logic here
  }

  navigateToscreensPage() {
    this.router.navigate(['libraries']);
  }

  reloadComponent() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  changeSelectedTab(tabNumber: number, button: MatButtonToggle) {
    const isDisabled = button?.disabled;
    if (isDisabled) {
      return;
    }
    this.selectedTab = `tab${tabNumber}`
  }

  nextClick(tab: any) {
    if (tab === 'reset') {
      this.selectedTab = 'tab1';
    }
    if (tab === 'tab1') {
      this.tab2Selected = true;
      this.tab1Selected = false;
      this.selectedTab = 'tab2';
      this.toggleButton2.checked = true;
    } else if (tab === 'tab2') {
      this.tab4Selected = true;
      this.tab2Selected = false;
      this.toggleButton4.checked = true;
      this.selectedTab = 'tab4';
    } else if (tab === 'tab3') {
      this.tab4Selected = true;
      this.tab3Selected = false;
      this.toggleButton4.checked = true;
      this.selectedTab = 'tab4';
    } else if (tab === 'tab4') {
      this.tab1Selected = true;
      this.tab4Selected = false;
      this.toggleButton1.checked = true;
      this.selectedTab = 'tab1';
    }

  }

  removeOption(option: string) {
    const selectedValues = this.selectedOptions2.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions2.setValue(selectedValues);
    }
  }


  openModalScreen() {
    const dialogRef = this.dialog.open(ModalScreenComponent, {
      // Add any additional configuration options for the modal
      width: '400px',
      // ... other options
    });

    // Subscribe to the afterClosed observable to get the result when the modal is closed
    dialogRef.afterClosed().subscribe(formValues => {
      if (formValues) {
        // Form values are available here
        alert(JSON.stringify(formValues));
        this.data = formValues;
        // this.updateData(JSON.stringify(formValues));
        this.screenTab = false;
      } else {
        // Modal closed without submitting the form
      }
    });
  }



  readExcelFile(file: any) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const contents = e.target.result;
      this.processXlsxData(contents);
    };
    reader.readAsArrayBuffer(file);
    this.isXLSXTableExport = true
  }



  onDropOld(event: DragEvent): void {
    event.preventDefault();
    const files: FileList | null = event.dataTransfer?.files || null;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.selectedFiles.push(file);
      }
    }
  }

  onDragOver(event: DragEvent): void {
    // event.preventDefault();
    // event.stopPropagation();
    event.preventDefault();
    event.stopPropagation();
    // event.dataTransfer.dropEffect = 'copy';
    // Add a CSS class to highlight the drop zone when a file is dragged over it
    // event.target.classList.add('drag-over');

  }

  // Function to handle drop event
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // event.target.classList.remove('drag-over');
    if(event.dataTransfer !== null){
      const files:any = event.dataTransfer.files;
      // this.onFilesSelectedDragnDrop(files);
    }
    // this.onFilesSelected(fileInput);
  }

  // Function to handle drag leave event
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    // Remove the CSS class when the file is dragged out of the drop zone
    // event.target.classList.remove('drag-over');
  }


  onDragEnter(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileUploaded = true;
  }

  onDragLeaveOld(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileUploaded = false;
  }

  removeFile() {
    this.uploadedFile = null;
    this.selectedFile = null;
    this.isFileUploaded = false;
    this.fileControl.reset();
  }

  onFilesSelected1(event: any): void {
    const files = event.target.files;
    if (files) {
      this.uploadedFiles = Array.from(files);
    }
  }

  deleteFile(file: File): void {
    const index = this.uploadedFiles.indexOf(file);
    //TODO: SHERIF CHECK NGS TO REMOVE
    // const indexNGS = this.uploadedFiles.indexOf(file);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  showXLSXTable() {
    this.isXLSXTableManual = !this.isXLSXTableManual;
  }


  screenResults(row: any, edit: boolean = true) {
    this.router.navigate(['screens/screen-details', 1]);
  }

  duplicateRow(row: any) {
    const screenIndex = this.screenList.findIndex((screen: any) => screen.id == row.id);
    row.name = row.name + '2'
    this.screenList.splice(screenIndex, 0, row)
    this.screenList = [...this.screenList]
  }

  onGridReady(params: GridReadyEvent<any>) {
    this.gridApi = params.api;
  }

  exportToExcel() {
    this.gridApi.exportDataAsExcel();
  }


  uploadNGSEndPoint() {
    this.ngsFilesUploaded = true;
  }

  getPresignedUrl(fileList: any) {
    this.dataService.getPresignedUrl(fileList).subscribe({
      next: (response) => {
       const statusCode = response.statusCode;
        if (statusCode === 200) {
          response.body.map((obj: any) => this.presignedUrlArr.push(obj));
          this.uploadFileToS3_copy(fileList);
        } else {
          console.log(`There is ${statusCode} error from the server side!!`);
        }
      },
      error: (error) => {
        console.error('Error:', error);
      }
    })
  }

  

  getPresignedUrlNGS(ldfSelectedFileNew: any, structureSelectedFileNew: any, selectedNgsFilesNew: any) {
    
    const req1 = this.dataService.getPresignedUrlNGS(ldfSelectedFileNew, 'ldf')
    const req2 = this.dataService.getPresignedUrlNGS(structureSelectedFileNew, 'structure')
    const req3 = this.selectedNgsFiles.length !== 0 ? this.dataService.getPresignedUrlNGS(selectedNgsFilesNew, 'library') : 'NA'

    if (this.selectedNgsFiles.length !== 0) {
      forkJoin([req1, req2, req3]).subscribe({
        next: ([response1, response2, response3]) => {
          // Handle responses here
          console.log('Data from request 1:', response1);
          console.log('Data from request 2:', response2);
          console.log('Data from request 3:', response3);
          response1.body.map((obj: any) => this.ldfPresignedUrlArr.push(obj));
          response2.body.map((obj: any) => this.structPresignedUrlArr.push(obj));
          response3.body.map((obj: any) => this.ngsPresignedUrlArr.push(obj));
          this.uploadNGSFileToS3(ldfSelectedFileNew, 'LDF')
          this.uploadNGSFileToS3(structureSelectedFileNew, 'STRUCT')
          this.uploadNGSFileToS3(selectedNgsFilesNew, 'NGS')
    },
    error: (error) => {
      console.error('Error:', error);
    }
   })
   } else {
      forkJoin([req1, req2]).subscribe({
        next: ([response1, response2]) => {
        // Handle responses here
        console.log('Data from request 1:', response1);
        console.log('Data from request 2:', response2);
      
        response1.body.map((obj: any) => this.ldfPresignedUrlArr.push(obj));
        response2.body.map((obj: any) => this.structPresignedUrlArr.push(obj));

        this.uploadNGSFileToS3(ldfSelectedFileNew, 'LDF')
        this.uploadNGSFileToS3(structureSelectedFileNew, 'STRUCT')
        this.uploadNgs = true
        
      },
      error: (error) => {
      console.error('Error:', error);
      }
      })
    }
   
  }

  uploadFileToS3(fileList: any) {
   
    if (fileList.length === this.presignedUrlArr.length) {

      from(fileList).pipe(
        concatMap((file: any, i) => {
          let presignedUrlArrEle: any = this.presignedUrlArr[i];
  
          return this.dataService.uploadFileToS3(presignedUrlArrEle['presigned_url'], fileList[i]);
        })
      ).subscribe(res=> {
       if(this.selectedNgsFiles.length == 0) {
        this.submitScreen()
       }
      })
    }
    else {
      console.log('please select at least one file OR there is mis-match in  no. of presigned urls and no. of files we are trying to upload');
    }
  }

  uploadFileToS3_copy(fileList: any) {
    

    if (fileList.length === this.presignedUrlArr.length) {
      // Create an array to store the observables for each file upload
      const uploadObservables = fileList.map((file: any, i:any) => {
        const presignedUrlArrEle: any = this.presignedUrlArr[i];
        return this.dataService.uploadFileToS3(presignedUrlArrEle['presigned_url'], fileList[i]);
      });

      // Use forkJoin to wait for all file uploads to complete
      forkJoin(uploadObservables).subscribe((uploadResults:any) => {
        // UploadResults is an array containing the results of all file uploads
        // Check if all uploads were successful, then submit the screen
        if (
          // uploadResults.every((result:any) => result.success) || 
          uploadResults.length === fileList.length) {
            if(this.selectedNgsFiles.length == 0) {
              this.submitScreen()
             }
          // if (!this.screenSubmitted) {
          //   this.loadingExpFile = false;
          //   // this.loading = false;
          //   // this.submitScreen();
          // }
        } else {
          console.log('Some file uploads failed');
        }
      });
    }else{

    }
  }

  
  

  uploadNGSFileToS3(ngsFile: any, type: string) {
    let p: multiPartFileObject[] = []
    let callMultiPartFunction = false

    let filePresignedUrlArr: Object[] = []
    if (type === 'LDF') {
      filePresignedUrlArr = []
      filePresignedUrlArr = this.ldfPresignedUrlArr
    } else if ( type === 'STRUCT') {
      filePresignedUrlArr = []
      filePresignedUrlArr = this.structPresignedUrlArr
    } else {
      filePresignedUrlArr = []
      filePresignedUrlArr = this.ngsPresignedUrlArr
    }
  
    filePresignedUrlArr.forEach((url: any, indexOfUrl: number)=> {

      // creating chunks for each large NGS file.
      let chunks: any = [];
      let part:any = []
      let start = 0;
      let end = 1000*1000*1000*1
      if (type === 'NGS') {
        while (start < ngsFile[indexOfUrl]['file'].size) {
          chunks.push(ngsFile[indexOfUrl]['file'].slice(start, end, ngsFile[indexOfUrl]['file'].type));
          start = end;
          end = start + 1000*1000*1000*1;
        }
      } else {
        while (start < ngsFile[indexOfUrl].size) {
          chunks.push(ngsFile[indexOfUrl].slice(start, end, ngsFile[indexOfUrl].type));
          start = end;
          end = start + 1000*1000*1000*1;
        }
      }

     

      let temp1 = {
        key: url.key,
        upload_id: url.upload_id,
        parts: []
      }
      p.push(temp1)
      // iterating on each object 
      url.presigned_urls.forEach((item: any, index: number)=> {

        if (type === 'NGS') {
          var file = new File([chunks[index]], `${ngsFile[indexOfUrl]['file'].name}/chunk_${index}`, { type: 'application/x-gzip'});
        } else {
          var file = new File([chunks[index]], `${ngsFile[indexOfUrl].name}/chunk_${index}`, { type: 'application/x-gzip'});
        
        }
        let uploadFile = []
        uploadFile.push({
          file: file
        });
        
        this.dataService.uploadFileToS3FASTQ(item[1], uploadFile[0]).subscribe(res=> {
          const etag = res.headers.get('Etag');
          const temp = {
                        "ETag": etag,
                        "PartNumber": item[0]
          }
          p[indexOfUrl]['parts'].push(temp)
          
          callMultiPartFunction = this.callMultipartUpload(p, type)
          if (callMultiPartFunction) {
            p.forEach((item)=> {
              item.parts.sort((a: any, b: any)=> {
                return a.PartNumber - b.PartNumber
              })
            })
      
            this.dataService.uploadMultiPartFile(p).subscribe(res=> {
            
              if (type === 'LDF') {
                this.uploadLdf = true
              } else if (type === 'STRUCT') {
                this.uploadStruct = true
              } else {
                this.uploadNgs = true
              }
              if(this.uploadLdf && this.uploadStruct && this.uploadNgs) {
                this.loadingNGSFile = false
                this.loading = false
                this.submitScreen()
              }
              
              console.log('Lets see final upload ------------------------------------------------->>>>>>>>>>>>>>>>>>', res)
            })
          }
          

        })

      })



    })

   

  }

  callMultipartUpload(p: any, type: string): boolean {
    let filePresignedUrlArr = []
    if (type === 'LDF') {
      filePresignedUrlArr = []
      filePresignedUrlArr = this.ldfPresignedUrlArr
    } else if ( type === 'STRUCT') {
      filePresignedUrlArr = []
      filePresignedUrlArr = this.structPresignedUrlArr
    } else {
      filePresignedUrlArr = []
      filePresignedUrlArr = this.ngsPresignedUrlArr
    }
    let count = 0 
    if(p.length == filePresignedUrlArr.length) {
      
      filePresignedUrlArr.forEach((url: any, indexOfUrl: number)=> {
        if (url.presigned_urls.length == p[indexOfUrl].parts.length) {
          count = count +1
        } 
        else  {
          return false
        }
  
      })
     return  (count == filePresignedUrlArr.length ? true : false)
  
    } else {
      return false
    }

  }


  screenFilesUpload() {
    this.loading = true;
    this.ngsFilesUploaded = true;

    let ldfSelectedFile = this.selectedFiles.filter( (item: any) =>  { if (item['fileType'] === 'Library Definition')
      return item['file']  
    }  )
    let ldfSelectedFileNew = ldfSelectedFile.map( (item: any) =>  item['file']   )
    let structureSelectedFile = this.selectedFiles.filter( (item: any) =>   { if (item['fileType'] === 'Library Structure')
      return item['file']
    })
    let structureSelectedFileNew = structureSelectedFile.map( (item: any) =>  item['file']    )
    this.getPresignedUrlNGS(ldfSelectedFileNew, structureSelectedFileNew, this.selectedNgsFiles)
    
  }


  removeOption3(option: string) {
    const selectedLibraries = this.personalFormGroup?.get('libraries')?.value;
    const index = selectedLibraries.indexOf(option);

    if (index >= 0) {
      selectedLibraries.splice(index, 1);
      this.personalFormGroup?.get('libraries')?.setValue(selectedLibraries);
    }
  }

   // Function to reset the file input
   resetFileInput() {
    this.fileInput.nativeElement.value = ''; // Clear the input
    this.errorMessageDuplicateFileUpload = '';
  }

  // NGS + EXP 

  processXlsxData(contents: string) {
    const workbook = XLSX.read(contents, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    if (!this.rowData) this.rowData = []
    const rows: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = rows[0];

    this.expRows = rows
    this.expHeaders = headers
    this.xlsxHeaders = headers.map((header: string) => {
      if (header == NGS_READ_ONE_COL || header == NGS_READ_TWO_COL) return { headerName: header, field: header, editable: true, cellClassRules: this.cellRules};
      else return { headerName: header, field: header, editable: true };
    });
    this.rowData = [];
    this.fillExperimentalIntialData();

    this.experementalConditionUploaded = true;
    this.vaidateAllFileNamesInExp();
  }

  fillExperimentalIntialData(emptyRows ?: number) {
    let startIndex = emptyRows ? this.expRows.length: 1
    let endIndex = emptyRows ? this.expRows.length + emptyRows : this.expRows.length
    for (let i = startIndex; i < endIndex; i++) {
      if(emptyRows){
        this.expRows.push([])
      }
      const rowDataTemp: any = {};
      for (let j = 0; j < this.expHeaders.length; j++) {
        const value = this.expRows[i][j]
        if( this.expHeaders[j] == 'Condition') {
          if(!value){
            return;
          }
        }

        let valueTemp = value;
        if(valueTemp && valueTemp.toString().includes('.gz')) {
          valueTemp = valueTemp.toString().replace('.gz','')
        } 
        if(this.expHeaders[j] == NGS_READ_ONE_COL) {
            this.expReadOneFileNames.push(valueTemp);
        }
      
        if(this.expHeaders[j] == NGS_READ_TWO_COL) {
             this.expReadTwoFileNames.push(valueTemp);
        }
        rowDataTemp[this.expHeaders[j]] = valueTemp;
      }
      this.rowData.push(rowDataTemp);
    }
  }
  validateExpByFileName(fileName: string){
    if(!fileName){
      return false;
    }
    if(fileName.includes('.gz')){
      fileName = fileName.replace('.gz','');
    }

    if(fileName.includes('R1')){
      if (!this.ngsReadOneFiles.includes(fileName)) {
        return false;
      }
    }

    if(fileName.includes('R2')){
      if (!this.ngsReadTwoFiles.includes(fileName)) {
        return false;
      }
    }
    return true
  }
  vaidateAllFileNamesInExp() {
    this.showEXPvalidationError = false;   
    for (let i = 0; i < this.expReadOneFileNames.length; ++i) {
      let valueTemp = this.expReadOneFileNames[i];
      if(!valueTemp){
        this.showEXPvalidationError = true;
        return this.showEXPvalidationError;
      }
      valueTemp = valueTemp.replace('.gz','');
      if (!this.ngsReadOneFiles.includes(valueTemp)) {
        this.showEXPvalidationError = true;
        return this.showEXPvalidationError;
      }
    }
    if(this.readFileType == 'Paired') {
      for (let i = 0; i < this.expReadTwoFileNames.length; ++i) {
        let valueTemp = this.expReadTwoFileNames[i];
        if(!valueTemp){
          this.showEXPvalidationError = true;
          return this.showEXPvalidationError;
        }
        valueTemp = valueTemp.replace('.gz','');
        if (!this.ngsReadTwoFiles.includes(valueTemp)) {
          this.showEXPvalidationError = true;
          return this.showEXPvalidationError;
        }
      }

    }
  

    return this.showEXPvalidationError


  }

  addMoreRows(){
    if(this.experementalConditionUploaded && (this.ngsReadOneFiles.length >  this.expReadOneFileNames.length)){
      let differenceRows = this.ngsReadOneFiles.length - this.expReadOneFileNames.length 
      // while( differenceRows > 0){
      //   this.expRows.push({});
      //   differenceRows --;
      // }
      // this.fillExperimentalIntialData(differenceRows)

    }
  }

  clearFiles(index: any): void {
    let file = this.ngsFiles[index];
    if(file.includes('R1')){
      this.ngsReadOneFiles = this.ngsReadOneFiles.filter((item:any) => item != file.replace('.gz',''))
    }

    if(file.includes('R2')){
      this.ngsReadTwoFiles = this.ngsReadTwoFiles.filter((item:any) => item != file.replace('.gz',''))
    }
    this.selectedFiles = this.selectedFiles.filter((element:any, i:any) => i !== index);
    this.ngsFiles = this.ngsFiles.filter((element:any, i:any) => i !== index);
    
    this.validateNumberOfNGSFilesUploaded();
    this.vaidateAllFileNamesInExp();
    this.resetFileInput();
  }
  
  onFilesSelected(fileInput: HTMLInputElement) {
    const files: FileList | null = fileInput.files;
    // if (!this.selectedFiles) this.selectedFiles = [];
    if (files && files.length) {
      // Check for duplicate files
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (this.ngsFiles.some((fileName:string) => fileName === file.name)) {
          this.errorMessageDuplicateFileUpload = 'Duplicate file: ' + file.name;
          return;
        }
      }

      // No duplicates, clear error message
      this.errorMessageDuplicateFileUpload = '';

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // if (this.selectedTab == 'tab3' && file && file.name.endsWith('.xlsx')) {
        if (this.selectedTab == 'tab3' && this.commonService.validateFileName(file, FILE_TYPE.NGS_EXPERIMENTAL)) {
          this.clearExpData();
          this.readExcelFile(file);
          this.showInputError = false;
          this.showNaiveNGSFilesErr = false;
          this.selectedFiles.push({
            file: file,
            selectedFile: this.selectedFile,
          });
          
        }
        else if (this.selectedTab == 'tab2') {
          if (this.ngsFiles.length > 0) {
            if (file.name.startsWith('NAIVE') && this.ngsFiles[0].startsWith('NGS')) {
              this.showNaiveNGSFilesErr = true;
              this.showInputError = false;
              return;
            }
            if (file.name.startsWith('NGS') && this.ngsFiles[0].startsWith('NAIVE')) {
              this.showNaiveNGSFilesErr = true;
              this.showInputError = false;
              return;
            }
          }
          if (this.commonService.validateFileName(file, FILE_TYPE.NGS_FILE)) {
            this.showInputError = false;
            this.showNaiveNGSFilesErr = false;
            this.selectedNgsFiles.push({
              file: file,
              selectedFile: this.selectedFile,
            });

              let fileName = file.name
              let splitted = fileName.split('_');
              let lastSplitFileName = splitted.length >=1 ? splitted[splitted.length-1] : ''
              if(lastSplitFileName.includes('R1') || this.readFileType == 'Merged'){
                if(fileName.includes('.gz')){
                  fileName = fileName.replace('.gz','')
                }
                this.ngsReadOneFiles.push(fileName)
              }

              if(lastSplitFileName.includes('R2')){
                if(fileName.includes('.gz')){
                  fileName = fileName.replace('.gz','')
                }
                this.ngsReadTwoFiles.push(fileName)
              }
              this.ngsFiles.push(file.name)
          }
          else {
            this.showInputError = true;
            this.showNaiveNGSFilesErr = false;
            return;

          }
          this.validateNumberOfNGSFilesUploaded();
        }
        else {
          this.showInputError = true;
          return;
        }
      }
      if(this.experementalConditionUploaded) {
        this.vaidateAllFileNamesInExp();
      }
      this.resetFileInput();
    }
  }

  clearExpData(){
    this.expReadOneFileNames = [];
    this.expReadTwoFileNames = [];
  }

  validateNumberOfNGSFilesUploaded(){
    if(this.readFileType == 'Merged') return
    if(this.ngsReadOneFiles.length !== this.ngsReadTwoFiles.length){
      this.showErrorOddNGSFiles = true;
    } else {
      this.showErrorOddNGSFiles = false;
    }
  }

}



