import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( private dataService: DataService,
               private router: Router ) {
   }
  selected = 1;
  title = 'delt';
  tableTitle = 'New Processed Screen'
  path = 'screens'
  showCardDetails: boolean = true;
  dataSource: any[] = [];
  cardColumns: any[] = [];

  // related to project

  projectListColunms: Array<Object> = [
    { key: 'project_name', displayName: 'Project Name' },
    { key: 'unique_project_id', displayName: 'Project ID' },
    { key: 'project_lead', displayName: 'Project Lead' },
    { key: 'main_target_protein', displayName: 'Target' },
    { key: 'project_status', displayName: 'Status' },
    { key: 'updated_date', displayName: 'Last Screening' },
  ];

  projectList: any;

  // related to screen

  screenListColunms: Array<Object> = [
    { key: 'experiment_number', displayName: 'Exp ID' },
    { key: 'screen_name', displayName: 'Name' },
    { key: 'screen_status', displayName: 'Status' },
    { key: 'submission', displayName: 'Submission' },
    { key: 'protein', displayName: 'Protein' },
    { key: 'scientist_name', displayName: 'Scientist' },
    { key: 'cwid', displayName: 'CWID' },
    { key: 'project_name', displayName: 'Project' },
    { key: 'alignment_algorithms', displayName: 'Aligment Algorithms' },
    { key: 'ngs_files', displayName: 'NGS Files'},
    { key: 'error_margin', displayName: 'Error Margin' },
    { key: 'description', displayName: 'Description' },
  ];

  screenList: any;

  tableColumns: any[] = [];
  tableList: any;

  // related to library
  libraryListColumns: any =
    [
      { key: 'library_name', displayName: 'Library' },
      { key: 'compound', displayName: 'Theoretical number of compound' },
      { key: 'creation_date', displayName: 'Active Since' },
      { key: 'info', displayName: 'Description' },
      { key: 'library_status', displayName: 'Status'},
      { key: 'scientist_name', displayName: 'Scientists'},
      { key: 'ngs_files', displayName: 'NGS Files'}
    ]
  libraryList: any;
  // related to hitlist

  hitListColumns: any = [
  { key: 'hit_list_name', displayName: 'Hit List' },
  { key: 'project_name', displayName: 'Project Name' },
  { key: 'import_date', displayName: 'Import Date' },
  { key: 'scientist_name', displayName: 'Scientist' },
  { key: 'analyzedby', displayName: 'Analyzed By' }];

  hitList: any;

  statisticsList: any;
  screenResultList: any;
  chartData: any;
  loading: boolean = false;

  ngOnInit(): void {
    this.dataService.getLatestProjectList().subscribe(res=> {
      this.projectList = res.body.response;
      //this.projectListCount = res.body.count;
  });
    this.dataService.getLatestProcessedScreens().subscribe(res => {
    if (res) {
      this.screenList = [...res];
      this.tableColumns = this.screenListColunms
      this.tableList = this.screenList
    }
  });
    this.dataService.getLatestLibrariesData().subscribe(res => {
    if (res) {
      this.libraryList = [...res];
    } 
  });
  this.dataService.getLatestHitlists().subscribe(res => {
    if (res) {
      this.hitList = res.body.response
    } 
  });

}



 
  

  toggleCardDetails(cardNumber: number) {
    this.selected = cardNumber;
    if(cardNumber == 1) {
      this.tableColumns = this.screenListColunms
      this.tableList = this.screenList
      this.tableTitle = 'New Processed Screen'
      this.path = 'screens'

    } else if (cardNumber == 2) {
      this.tableColumns = this.projectListColunms
      this.tableList = this.projectList
      this.tableTitle = 'New Project List'
      this.path = 'projects'

    } else if (cardNumber == 3) {

      this.tableColumns = this.libraryListColumns
      this.tableList = this.libraryList
      this.tableTitle = 'New Library'
      this.path = 'libraries'
    } else if (cardNumber == 4) {

      this.tableColumns = this.hitListColumns
      this.tableList = this.hitList
      this.tableTitle = 'New Hit List'
      this.path = 'hitlists'
    }
    this.showCardDetails = true;
    this.loadCardData(cardNumber);
    this.loadCardColumns(cardNumber);
  }

  listDetail() {
    this.router.navigate([this.path])
  }

  loadCardData(cardNumber: number) {
    // Simulating data loading based on the cardNumber
    if (cardNumber === 1) {
      this.dataSource = [
        { screen: 'Screen Z', status: 'Successful', timeanddate: '15 Jan 2023, 18:22', targetprotein: 'Protein Y', scientist: 'Rose Rae', project: 'Project Name Y' },
        { screen: 'Screen B', status: 'Successful', timeanddate: '21 Dec 2022, 1:20', targetprotein: 'Protein D', scientist: 'Rose Rae', project: 'Project Name Y' }
      ];
    } else if (cardNumber === 2) {
      this.dataSource = [
        { listname: 'Hit List A', column1: 'Column1', column2: 'Column2', column3: 'Column3', column4: 'Column4', column5: 'Column5' }   
      ];
    } else if (cardNumber === 3) {
      this.dataSource = [
        { project: 'Project Name 01', projectlead: 'Rose Rae', target: 'Protein X', status: 'On Going', lastscreening: '21 Dec 2022, 1:20'},
        { project: 'Project Name 02', projectlead: 'Rose Rae', target: 'Protein X', status: 'On Going', lastscreening: '21 Dec 2022, 1:20'},
        { project: 'Project Name 03', projectlead: 'Rose Rae', target: 'Protein X', status: 'On Going', lastscreening: '21 Dec 2022, 1:20'}   
      ];
    } else if (cardNumber === 4) {
      this.dataSource = [
        { name: 'DEL Library', definition: 'Library Defnition', date: '21 Dec 2022, 1:20', info: 'info about the Library' },
        { name: 'X Library', definition: 'Library Defnition', date: '21 Dec 2022, 1:20', info: 'info about the Library' },    
      ];
    }
  }

  loadCardColumns(cardNumber: number) {
    // Define column structure based on the cardNumber
    if (cardNumber === 1) {
      this.cardColumns = [
        { columnDef: 'screen', header: 'Screen' },
        { columnDef: 'status', header: 'Status' },
        { columnDef: 'timeanddate', header: 'Time and date' },
        { columnDef: 'targetprotein', header: 'Target Protein' },
        { columnDef: 'scientist', header: 'Scientist' },
        { columnDef: 'project', header: 'Project' }
      ];
    } else if (cardNumber === 2) {
      this.cardColumns = [
        { columnDef: 'listname', header: 'List name' },
        { columnDef: 'column1', header: 'Column1' },
        { columnDef: 'column2', header: 'Column2' },
        { columnDef: 'column3', header: 'Column3' },
        { columnDef: 'column4', header: 'Column4' },
        { columnDef: 'column5', header: 'Column5' }
      ];
    } else if (cardNumber === 3) {
      this.cardColumns = [
        { columnDef: 'project', header: 'Project' },
        { columnDef: 'projectlead', header: 'Project Lead' },
        { columnDef: 'target', header: 'Target' },
        { columnDef: 'status', header: 'Status' },
        { columnDef: 'lastscreening', header: 'Last Screening' }
      ];
    } else if (cardNumber === 4) {
      this.cardColumns = [
        { columnDef: 'name', header: 'Name' },
        { columnDef: 'definition', header: 'Definition' },
        { columnDef: 'date', header: 'Date' },
        { columnDef: 'info', header: 'Info' } 
      ];
    }
  }

  getColumnDefNames(): string[] {
    return this.cardColumns.map(column => column.columnDef);
  }
  
  getScreenStatistics(libraryDetails: any) {
    //console.log("this.libraryDetails",this.libraryDetails)
    this.dataService.getLibraryStatistics(libraryDetails.library_name).subscribe(res => {
      this.statisticsList = [...res];
      this.screenResultList = [...this.statisticsList].map(item => {
        return {
          library_name: item.library_name,
          totalMolecules: item.total_molecules, 
          ldfFiles: libraryDetails.ldf_files,
          description: libraryDetails.description,
          statusIcon: libraryDetails.screen_status
        }
      })
      this.chartData = [...this.statisticsList];
    })
    
  }

  async getHitmilesAndCSVImportURL(screenName: string, condition: string, ngs_files: string, row: any) {
    this.loading = true;
    
    
    const api1Observable = this.dataService.getHitmiles(screenName, condition, ngs_files, 'library');
    const api2Observable = this.dataService.getHitmilesCSVPresignedURL(screenName, condition, ngs_files, 'library');
    try {
      const [api1Response, api2Response] = await Promise.all([
        firstValueFrom(api1Observable),
        firstValueFrom(api2Observable)
      ]);
      // Handle the responses of both API calls
      if (api1Response.statusCode === 200 &&  api2Response.statusCode === 200 && 
          api1Response.body.length !== 0 && api2Response.body !== null ) {
        localStorage.setItem('hitmiles_data', JSON.stringify(api1Response.body));
        localStorage.setItem('hitmiles_data_total_count', JSON.stringify(api1Response.count));
        localStorage.setItem('getHitmilesParams', JSON.stringify({screenName:screenName, condition:condition,ngs_files:ngs_files}));
        localStorage.setItem('hitmiles_CSV_PresignedURL', JSON.stringify(api2Response.body));
        const index = this.statisticsList.findIndex((data: any)=> data.screen_name == row.library_name)
        localStorage.setItem('chartData', JSON.stringify(this.chartData[index]));
        this.loading = false;
        //this.router.navigate(['libraries/chart-analysis', 'Naive']);
        //this.router.navigate(['libraries/libray-details'])
        this.router.navigate(['libraries/library-details'], { state: { data: row } })
      } else if (api1Response.statusCode !== 200 || api1Response.body.length === 0 ||
         api2Response.statusCode !== 200 || api2Response.body === null) {
          this.loading = false;
          alert("Could not fetched complete data there might be some server error!!");
      }
    } catch (error) {
      // Handle errors in the Promise.all or individual promises
      this.loading = false;
      //this.router.navigate(['libraries/libray-details'])
      alert("There is a server error!!");
      console.error('An error occurred:', error);
      
    }
  }

  rowDetails(row: any, edit: boolean = false) {

    if(this.path == 'projects') {  
       this.router.navigate(['project/project-details'], { state: { data: row } }); 
    } else if (this.path == 'screens') {
      this.router.navigate(['screens/screen-details', row.screen_name]);
    } else if (this.path == 'hitlists') {
      const payload = {
        "operation_type": "data",
        "hitlist_id": row.hitlist_id,
        "table_name": "bay-delt-hitlists",
        "context": "hitlist"
      }
      this.dataService.getHitlistDetails(payload).subscribe(res=> {
        const data = {
          hitlist: row,
          detail: res.body
        }
        this.router.navigate(['hitlists/hitlist-details'], { state: { data: data } });    
      })
  
    } else if (this.path == 'libraries') {
      localStorage.setItem('libraryDetails', JSON.stringify(row));
      this.getScreenStatistics(row);
      const inputString = row.ngs_files;
         // Split the string by commas
      const valuesArray = inputString.split(',');
      // Get the first value (at index 0)
      const firstValue = valuesArray[0];
      if(row.ngs_files) {
        this.getHitmilesAndCSVImportURL(row.library_name, 'Naive', firstValue, row);
      } else {
        this.router.navigate(['libraries/library-details'], { state: { data: row } })
      }
    }

  }

}
