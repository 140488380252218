<div class="row homepage">
  
  <div class="title-home">
    <mat-icon class="mat-icon-home">person_outline</mat-icon><span class="title-breadcrump">Libraries</span>
  </div>


  <div class="select-description-container">
    <span class="description-title">
      Libraries
      <span *ngIf="this.libraries?.length > 0"> ({{this.libraries?.length}}) </span>
      <br />
      <span class="description-text">Select on the library name to view more details</span>
    </span>
    <button (click)="addLibrary()" mat-flat-button class="btn-add" 
    >
      <mat-icon>add</mat-icon>         
        Add New Library
    </button>
  </div>
    

  </div>
  <br />
  <mat-divider></mat-divider>
  <br />

  <div class="row homepage">
    <!-- TABLE_HERE -->
    <div *ngIf="!loading" class="row homepage">
      <app-table-component [columns]="tableColumns" [tableData]="libraries" [showCheckbox]="showCheckbox"
        [clickable]="clickable" (showRowDetails)="toggleBarchartView($event)" 
         listName="Library_list"  />
    </div>
    <div *ngIf="loading" class="loading-container">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>