import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { ModalServiceService } from '../../modal-service.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common.service';
import { DataService } from 'src/app/data.service';


export interface library {
  info: string;
  library_name: string;
  active_since: string;
  library_file: string
}

export interface projectInfo {
  projects: string;
  last_screening: string;
  scientist_name: string;
  active_since: string;
  cwid: string;
  role_area: string
}


@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {

  showComponentScientist = true;
  showComponentAddScientist = false;
  projectStatus!: string;
  role!: string;
  showSuccessMessage: boolean = false;
  selectedOptions4:any = new FormControl([]);
  selectedOptions5:any = new FormControl([]);
  selectedOptions6:any = new FormControl([]);
  projectStatusArr = [];
  projectLeadArr: projectInfo[] = [];
  projectTeamArr: projectInfo[] = [];
  targetProteinArr = [];
  libraryArr: library[] = [];
  clickable = true;

  screenColumns: Array<Object> = [
    { key: 'screen_name', displayName: 'Screen' },
    { key: 'protein', displayName: 'Protein' },
    { key: 'scientist_name', displayName: 'Scientist' },
    { key: 'submission', displayName: 'Submission' },
    { key: 'experiment_number', displayName: 'Exp ID' },
    { key: 'screen_status', displayName: 'Status' },
  ]

  hitListColumns: Array<Object> = [
    { key: 'hit_list_name', displayName: 'Hit List' },
    { key: 'project_name', displayName: 'Project Name' },
    { key: 'import_date', displayName: 'Import Date' },
    { key: 'scientist_name', displayName: 'Scientist' },
    { key: 'analyzedby', displayName: 'Analyzed By' }];
 
  
  screenList = [];
  hitList = []
  projectDetail: any
  edit = false
  data?: any
  editFormGroup!: FormGroup


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;


  constructor(private modalService: ModalServiceService,  private snackBar: MatSnackBar, private http: HttpClient, private router: Router, private formBuilder: FormBuilder,
              private commService: CommonService, private dataService: DataService) {
    
    
    const navigation: any = this.router.getCurrentNavigation();
    this.data = navigation.extras.state.data

   
  }

  ngOnInit(): void {

    this.editFormGroup = this.formBuilder.group({
      projectStatus: ['', Validators.required],
      projectName: ['', Validators.required],
      projectID: ['', Validators.required],
      projectLead: ['', Validators.required],
      projectTeam: ['', Validators.required],
      targetProtein: ['', Validators.required],
      library: ['', Validators.required],
      comment: ['']
      
    })

    this.editFormGroup?.get('projectStatus')?.setValue(this.data.project_status);
    this.editFormGroup?.get('projectName')?.setValue(this.data.project_name);
    this.editFormGroup?.get('projectID')?.setValue(this.data.unique_project_id);
    this.editFormGroup?.get('projectLead')?.setValue(this.data.project_lead);
    this.editFormGroup?.get('projectTeam')?.setValue(this.data.project_team);
    this.editFormGroup?.get('targetProtein')?.setValue(this.data.main_target_protein);
    this.editFormGroup?.get('library')?.setValue(this.data.libraries);
    this.editFormGroup?.get('comment')?.setValue(this.data.comments);

    this.commService.editProject$.subscribe(res=>{
      this.edit = res
      if(this.edit) {
        this.editFormGroup.enable()
      } else {
        this.editFormGroup.disable()
      }
    })

    this.getProjectEssentials()
    
  }


  getProjectEssentials() {
    this.dataService.getProjectStatus().subscribe(res=> {
      let temp = (res.version)
      this.projectStatusArr = (temp)
    })

    this.dataService.getProjectLead().subscribe(res=> {
      this.projectLeadArr = res
      this.projectTeamArr =res
    })
   

    this.dataService.getLibrary().subscribe(res=>{
      this.libraryArr = res
    })


    this.dataService.getProtein().subscribe(res=>{
      this.targetProteinArr = res
    })

    this.dataService.getScreenList(this.data.project_id).subscribe(res=> {
      this.screenList = res
    })

    this.dataService.getHitList(this.data.project_id).subscribe(res=> {
      this.hitList = res.body.response
    })
  }


  ngAfterViewInit() {
  }

  

  editProjectComponent(){

    const payload = {
      
      "table_name": "bay-delt-projects",
      "operation_type": "update",
      "unique_project_id": this.editFormGroup.controls['projectID'].value,
      "project_name": this.editFormGroup.controls['projectName'].value,
      "project_status": this.editFormGroup.controls['projectStatus'].value,
      "project_lead": this.editFormGroup.controls['projectLead'].value,
      "project_team": this.editFormGroup.controls['projectTeam'].value,
      "libraries": this.editFormGroup.controls['library'].value,
      "main_target_protein": this.editFormGroup.controls['targetProtein'].value,
      "comments": this.editFormGroup.controls['comment'].value,
      "user_id": '123',
      "project_id": this.data.project_id,
      "creation_date": this.data.creation_date
    }

    this.dataService.updateProjectItem(payload).subscribe(res=> {
      this.openSnackBar('New project successfully created !!');
      this.showSuccessMessage = true;
      this.router.navigate(['projects']);
    })  


    
  // Hide success message after 3 seconds
  setTimeout(() => {
    this.showSuccessMessage = false;
  }, 3000);
  }

  editProjectForm() {
    this.editFormGroup.enable()
    this.edit = true
  }

  screenDetail(row: any) {
    localStorage.setItem('screenDetails', JSON.stringify(row));
    this.router.navigate(['screens/screen-details', row.screen_name]);
  }

  hitlistDetail(row: any) {
    const payload = {
      "operation_type": "data",
      "hitlist_id": row.hitlist_id,
      "table_name": "bay-delt-hitlists",
      "context": "hitlist"
    }

    this.dataService.getHitlistDetails(payload).subscribe(res=> {
      const data = {
        hitlist: row,
        detail: res.body
      }
      this.router.navigate(['hitlists/hitlist-details'], { state: { data: data } });    
    })
  }

  addNewProject() {
    this.showComponentScientist = false;
    this.showComponentAddScientist = true;
    this.commService.showComponentScientist$.next(false)
    this.commService.showComponentAddScientist$.next(true)  
    const showProjectpage = { showComponentScientist: false, showComponentAddScientist: true}
    this.router.navigate(['projects'])
  }

  goBack() {
    this.router.navigate(['projects'])
  }

  processScreen() {
    const projectName = {projectName: this.data.project_name}
    this.router.navigate(['screens/process-new-screen'], { state: { data: projectName } });
  }
 
 
  removeOption1(option: string) {
    const selectedValues = this.selectedOptions4.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions4.setValue(selectedValues);
    }
  }

  removeOption2(option: string) {
    const selectedValues = this.selectedOptions5.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions5.setValue(selectedValues);
    }
  }

  removeOption3(option: string) {
    const selectedValues = this.selectedOptions6.value as string[];
    const index = selectedValues.indexOf(option);
    if (index >= 0) {
      selectedValues.splice(index, 1);
      this.selectedOptions6.setValue(selectedValues);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 1000,
      panelClass: 'custom-snackbar'
    });
    setTimeout(() => {
      this.goBack();
    }, 1000);

  }

}


