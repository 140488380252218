import { Component, Inject, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil, subscribeOn } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModalServiceService } from 'src/app/modal-service.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from './common.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'delt';
  showSideNav: boolean = false;
  @ViewChild('sidenav') sidenav!: MatSidenav;
  private subscription?: Subscription;
  isUserLoggedIn:boolean=false;
  userName?:string='';
  private readonly _destroy=new Subject<void>();
  role?:any;
  profile?:any;
  email?:string;
  userNameAvtar?:string='';
  descriptionObject: any;
  isIframe = false;
  loginDisplay = false;
  apiVersion?:string;
  uiVersion?:string;
  roleAdmin = false;
  private readonly _destroying$ = new Subject<void>();
  selectedItem: string = 'home'; // Default selected item

  
  
  constructor(
        // SSO-sherif
  @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig:MsalGuardConfiguration,
  private msalBroadCastService:MsalBroadcastService,private authService:MsalService,
  private msalBroadcastService: MsalBroadcastService,
  private modalService: ModalServiceService, private formBuilder: FormBuilder,
  private router: Router, private dataService: DataService, private location: Location, private commService: CommonService){}

  
  toggleSlideBar() {
    this.sidenav.toggle();
  }

  selectItem(item: string): void {
    this.selectedItem = item;
  }

  ngOnInit() {

    this.isIframe = window !== window.parent && !window.opener;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
    });

    // Subscribe to the boolean observable to get updates
    this.subscription = this.modalService.sideNav$.subscribe((value) => {
      if(value){
        this.descriptionObject = value
        this.toggleSlideBar()
      }
    });

    this.msalBroadCastService.inProgress$.pipe
    (filter((interactionStatus:InteractionStatus)=>
    interactionStatus==InteractionStatus.None),
    takeUntil(this._destroy))
    .subscribe(x=>
      {
        this.isUserLoggedIn=this.authService.instance.getAllAccounts().length>0;
      
        if(this.isUserLoggedIn)
        {
          this.userName = this.authService.instance.getAllAccounts()[0].name;
          this.email = this.authService.instance.getAllAccounts()[0].username;
          this.role = this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
          const accessToken = this.authService.instance.getAllAccounts()[0].idTokenClaims?.['access_token'];

          this.getAvtarUserName(this.email);
          this.roleAdmin = this.role.some((role: string)=> {
            return role == 'ApplicationAdmin'
          })

          this.commService.adminRole$.next(this.roleAdmin)
          // this.router.navigate(['/home']);
        }
        this.dataService.isUserLoggedIn.next(this.isUserLoggedIn);
      })

      const account: any = this.authService.instance.getAllAccounts()[0];
      if (account) {
        this.authService.instance.setActiveAccount(account);
        
        const tokenRequest = {
          scopes: ['user.read'], // Replace with the appropriate scope(s)
        };
  
        this.authService.acquireTokenSilent(tokenRequest).subscribe({
          next: (response) => {
            localStorage.setItem('access_token', response.accessToken);
            this.router.navigate(['/home']);
          },
          error: (error) => {
            console.error('Error acquiring token silently:', error);
          },
          complete: () => {
            console.log('Token acquisition complete.');
          }
        });
      }else {
        console.log("Not able to fetch access_token due to not active account");
      }
  }

  setLoginDisplay() {
        // SSO-sherif
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    if(this.isUserLoggedIn) {
      this.userName = this.authService.instance.getAllAccounts()[0].name;
      this.email = this.authService.instance.getAllAccounts()[0].username;
      this.role = this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
      const accessToken = this.authService.instance.getAllAccounts()[0].idTokenClaims?.['access_token'];

      this.getAvtarUserName(this.email);
      
      
    } 
    this.dataService.isUserLoggedIn.next(this.isUserLoggedIn);

    const account: any = this.authService.instance.getAllAccounts()[0];
      if (account) {
        this.authService.instance.setActiveAccount(account);
        
        const tokenRequest = {
          scopes: ['user.read'], // Replace with the appropriate scope(s)
        };
  
        this.authService.acquireTokenSilent(tokenRequest).subscribe({
          next:  (response) => {
             localStorage.setItem('access_token', response.accessToken);
           
            this.router.navigate(['/home']);
            this.getAPIVersion();
          },
          error: (error) => {
            console.error('Error acquiring token silently:', error);
          },
          complete: () => {
            console.log('Token acquisition complete.');
          }
        });
      }else {
        console.log("Not able to fetch access_token due to not active account");
      }
  }

  // ngOnDestroy(): void {
  //   if (this.modalService.sideNav$) {
  //     this.subscription?.unsubscribe();
  //   }
  //   this._destroy.next(undefined);
  //   this._destroy.complete();
  // }

  ngOnDestroy(): void {
    if (this.modalService.sideNav$) {
      this.subscription?.unsubscribe();
    }
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  

  // login(){
  //   if(this.msalGuardConfig.authRequest)
  //   {
  //     this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest)
  //   }
  //   else
  //   {
  //     this.authService.loginRedirect();
  //   }
  // }

      // SSO-sherif
  login() {
    // this.router.navigate(['/home']);
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  // logout(){
  //   this.authService.logoutRedirect({postLogoutRedirectUri:environment.postLogoutUrl});
  // }

    // SSO-sherif
  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        // LOCAL
         postLogoutRedirectUri: environment.postLogoutUrl,
         mainWindowRedirectUri: environment.postLogoutUrl
        // DEV
        // postLogoutRedirectUri: environment.postLogoutUrl_DEV,
        // mainWindowRedirectUri: environment.postLogoutUrl_DEV
        //QA
        //postLogoutRedirectUri: environment.postLogoutUrl_QA,
        //mainWindowRedirectUri: environment.postLogoutUrl_QA

        //PROD
        // postLogoutRedirectUri: environment.postLogoutUrl_PROD,
        // mainWindowRedirectUri: environment.postLogoutUrl_PROD
      });
    } else {
      this.authService.logoutRedirect({
          //LOCAL
          postLogoutRedirectUri: environment.postLogoutUrl,
         // DEV
          //postLogoutRedirectUri: environment.postLogoutUrl_DEV,
         //QA
        //  postLogoutRedirectUri: environment.postLogoutUrl_QA,
        // PROD
        // postLogoutRedirectUri: environment.postLogoutUrl_PROD,
      });
    }
  }

  private disableBackNavigation(): void {
    history.pushState(null, document.title, location.href);
    this.location.onUrlChange((url: string) => {
      history.pushState(null, document.title, location.href);
    });
  }

  private getAvtarUserName(email:string): any {
    const matchResult = email.match(/\.(.)/);
    const letterAfterDot = matchResult ? matchResult[1] : null;
    this.userNameAvtar = email[0].toUpperCase()+letterAfterDot?.toUpperCase();
  }

  getAPIVersion(){
    this.dataService.getAPIVersion().subscribe(res =>{
      if(res.statusCode === 200){
        this.apiVersion = res.version;
        this.uiVersion = environment.ui_version;
        localStorage.setItem('API_VERSION', res.version);
      }else{
        this.apiVersion = 'NA';
        this.uiVersion = environment.ui_version;
      }
    })
  }


  
}
