<div *ngIf="showComponentScientist">
  <div class="row homepage">
    <mat-icon class="mat-icon-home">add_chart</mat-icon><span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Frequency Hit Lists</span>
  </div>
  <div class="row message">
    <p><strong>Frequency Hit Overview </strong></p>
    <span class="text">Some comment which can be added here !</span>
    <button (click)="addFrequencyHit()" mat-flat-button color="primary" class="btn-add-library"><mat-icon>file_download</mat-icon>Add Frequency Hit</button>
  </div>
  <br><br>

   <div class="row homepage">
    <!-- <app-table-component [columns]="hitListColunms" [tableData]="hitList | hitlistFilter : filterAnalyzed : filterScientist : filterProjectName : filterImportDate" listName="Hitlist"  
    (showRowDetails)="rowDetails($event)"  (showScreen)="onShowScreen($event)" [clickable]="true"  
    [showActionMenu]=true (deleteRow)="delete($event)" (checkedRow)="export($event)" [showCheckbox]="true" [export]="true"
    [filters]="criteria" (filterItem)="onApplyFilter($event)" /> -->

    
   </div>     
</div>
<div *ngIf="showComponentAddScientist">
    <!-- <div class="row homepage">
      <mat-icon mat-icon-button (click) ="goBack()"class="mat-icon-home">west</mat-icon>
      <span style="margin-left:-25px; vertical-align: 5px; size: 5px;">Hit List | create a new hit list</span>
    </div>
    <div class="row message">
      <p><strong>Import New Analysis</strong></p>
      <span class="text">Insert related information about the new Hit list.</span>
      <button (click)="addHitlist()" [disabled]="!hitlistFormGroup.valid || invalidRows.length > 0" mat-flat-button color="primary" class="btn-add-library"><mat-icon>save</mat-icon>Save</button>
    </div> -->
    <br><br>

    
    <div class="row add-scientist">
        <div class="mat-elevation-z8">
            <br>
            <p class="form-grid-heading">Frequency Hit Details</p><br>
        <div class="form-grid-asym" [formGroup]="frequencyhitlistFormGroup">  
            <div class="row div1a">
              <div class="drop-zone large-upload-container"  (dragover)="onDragOver($event)" (drop)="onDrop($event)"
              multiple>
          <input type="file" #fileInput accept=".xlsx" (change)="onFilesSelected(fileInput);"
              style="display: none"  formControlName="hitlistFile" >
          <div class="upload-icon">
              <mat-icon (click)="fileInput.click()">upload</mat-icon>
          </div>
          <div class="row upload-text">
              <span (click)="fileInput.click()" style="color: #2196F3;">Click to Upload 
              </span><span>or drag and drop</span>
                 
    
                  
          </div>
                   
          </div>
          <!-- <div class="error">
            <ul *ngIf="invalidRows.length > 0">
              <li *ngFor="let row of invalidRows">{{ row }}</li>
            </ul>
          </div> -->
    
            </div>
            <div class="row div1">
                <mat-label class="mat-label">Name*</mat-label><br>
                <mat-form-field class="matformfield">
                    <input matInput placeholder="Insert name" formControlName="name" required>               
                </mat-form-field>
            </div>
            <!-- <div class="row div2">
              <mat-label class="mat-label">Project Name*</mat-label><br>
              <mat-form-field class="matformfield">
              <mat-select placeholder="select project name" #projectName formControlName="uniqueProjectID" 
              (selectionChange)="getScreens(projectName.value)"  required>
                <mat-option *ngFor="let option of projectNameArr" [value]="option.project_id">
                      {{ option.project_name }}
                </mat-option>
              </mat-select>
              </mat-form-field> 
           </div> -->
          
         
        </div> 
               
              <!-- <div *ngIf="showSuccessMessage" class="success-message">Form saved successfully!</div>  -->
        </div>   
    </div>     
  </div>

  
    
  



