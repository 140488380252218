import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hitlist-details',
  templateUrl: './hitlist-details.component.html',
  styleUrls: ['./hitlist-details.component.css']
})
export class HitlistDetailsComponent {

  data: any;
  hitlist: any
  columns: Array<Object> = [
    { key: 'Library', displayName: 'Library' },
    { key: 'Compound ID', displayName: 'Compound ID' },
    { key: 'Cycles', displayName: 'Cycles' },
    { key: 'Sub-library ID', displayName: 'Sub-library ID' },
    { key: 'BB1', displayName: 'BB1' },
    { key: 'BB2', displayName: 'BB2' },
    { key: 'BB3', displayName: 'BB3' },
    { key: 'BB4', displayName: 'BB4' },
    { key: 'BB5', displayName: 'BB5' },
    { key: 'SMILES BB1', displayName: 'SMILES BB1' },
    { key: 'SMILES BB2', displayName: 'SMILES BB2' },
    { key: 'SMILES BB3', displayName: 'SMILES BB3' },
    { key: 'SMILES BB4', displayName: 'SMILES BB4' },
    { key: 'SMILES BB5', displayName: 'SMILES BB5' },
    { key: 'SMILES Product', displayName: 'SMILES Product' },
    { key: 'Copy Number Condition 1', displayName: 'Copy Number Condition 1' },
    { key: 'Enrichment Factor Condition 1', displayName: 'Enrichment Factor Condition 1' },
    { key: 'Copy Number Condition 2', displayName: 'Copy Number Condition 2' },
    { key: 'Enrichment Factor Condition 2', displayName: 'Enrichment Factor Condition 2' },
    { key: 'Copy Number Condition 3', displayName: 'Copy Number Condition 3' },
    { key: 'Enrichment Factor Condition 3', displayName: 'Enrichment Factor Condition 3' },
    { key: 'Copy Number Condition 4', displayName: 'Copy Number Condition 4' },
    { key: 'Enrichment Factor Condition 4', displayName: 'Enrichment Factor Condition 4' },
    { key: 'Copy Number Condition 5', displayName: 'Copy Number Condition 5' },
    { key: 'Enrichment Factor Condition 5', displayName: 'Enrichment Factor Condition 5' },
    { key: 'Copy Number Condition 6', displayName: 'Copy Number Condition 6' },
    { key: 'Enrichment Factor Condition 6', displayName: 'Enrichment Factor Condition 6' },
    { key: 'Copy Number Condition 7', displayName: 'Copy Number Condition 7' },
    { key: 'Enrichment Factor Condition 7', displayName: 'Enrichment Factor Condition 7' },
    { key: 'Copy Number Condition 8', displayName: 'Copy Number Condition 8' },
    { key: 'Enrichment Factor Condition 8', displayName: 'Enrichment Factor Condition 8' },
    { key: 'Copy Number Condition 9', displayName: 'Copy Number Condition 9' },
    { key: 'Enrichment Factor Condition 9', displayName: 'Enrichment Factor Condition 9' },
    { key: 'Copy Number Condition 10', displayName: 'Copy Number Condition 10' },
    { key: 'Enrichment Factor Condition 10', displayName: 'Enrichment Factor Condition 10' },
    { key: 'MW', displayName: 'MW' },
    { key: 'cLogP', displayName: 'cLogP' },
    { key: 'PSA', displayName: 'PSA' },
    { key: 'HBD', displayName: 'HBD' },
    { key: 'HBA', displayName: 'HBA'},
    { key: 'Fsp3', displayName: 'Fsp3' },
    { key: 'HeavyAcount', displayName: 'HeavyAcount' },
    { key: 'Prio', displayName: 'Prio' },
    { key: 'Confirmed after resynthesis?', displayName: 'Confirmed after resynthesis?' },
    { key: 'BAY No of resynthesized compound', displayName: 'BAY No of resynthesized compound' },
    { key: 'Comment', displayName: 'Comment' },
  ];
  hitlistDetails: any;
  constructor(private router: Router) {
    
    const navigation: any = this.router.getCurrentNavigation();
    this.data = navigation.extras.state.data
      }

      goBack() {
        this.router.navigate(['hitlists'])
      } 
}


